import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { DepartmentResponse } from "../redux/departments/types"

import DepartmentForm from "../components/Form/DepartmentForm"

type Props = {
	open: boolean
	department: DepartmentResponse | null
}

export default function DepartmentModal({ open, department }: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<DepartmentForm department={department} />
		</ReactModal>
	)
}
