import { api } from ".."
import { departmentsURL } from "../../../api"
import { DepartmentsResponse, FetchOptions } from "../../departments/types"

const FETCH_DEFAULTS = {
	stats: false,
}

export const departments = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchDepartments: builder.query<DepartmentsResponse, FetchOptions | void>({
			query: (options) => departmentsURL({ ...FETCH_DEFAULTS, ...options }),
			providesTags: ["Departments"],
		}),
	}),
})

export const { useFetchDepartmentsQuery } = departments
