import { api } from "../.."
import {
	subscriptionActivationURL,
	subscriptionURL,
	subscriptionsGetURL,
	subscriptionsURL,
} from "../../../../api"
import { FetchOptions } from "../../../users/types"
import { TrialType } from "../types"
import {
	Subscription,
	SubscriptionCreateRequest,
	SubscriptionResponse,
	SubscriptionUpdateRequest,
} from "./types"

const FETCH_DEFAULTS = {
	limit: 20,
	offset: 0,
}

export const subscriptions = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchSubscriptions: builder.query<
			SubscriptionResponse,
			FetchOptions | void
		>({
			query: (options) => ({
				url: subscriptionsGetURL({ ...FETCH_DEFAULTS, ...options }),
			}),
			providesTags: (result) =>
				result
					? [
							...result.results.map(({ subscription_id }) => ({
								type: "Subscriptions" as const,
								id: subscription_id,
							})),
							{ type: "Subscriptions", id: "LIST" },
					  ]
					: [{ type: "Subscriptions", id: "LIST" }],
		}),

		fetchSubscription: builder.query<Subscription, number>({
			query: (id) => ({
				url: subscriptionURL(id),
			}),
			providesTags: (_r, _e, id) => [{ type: "Subscriptions", id }],
		}),

		createTrialSubscription: builder.mutation<void, TrialType>({
			query: (type) => ({
				url: subscriptionActivationURL({ type }),
				method: "POST",
			}),
			invalidatesTags: [{ type: "Subscriptions", id: "LIST" }],
		}),

		createSubscription: builder.mutation<
			Subscription,
			SubscriptionCreateRequest
		>({
			query: (body) => ({
				url: subscriptionsURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Subscriptions", id: "LIST" }],
		}),

		updateSubscription: builder.mutation<
			Subscription,
			SubscriptionUpdateRequest
		>({
			query: ({ id, ...body }) => ({
				url: subscriptionsURL(),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_r, _e, { id }) => [{ type: "Subscriptions", id }],
		}),
	}),
})

export const {
	useFetchSubscriptionsQuery,
	useFetchSubscriptionQuery,
	useCreateTrialSubscriptionMutation,
	useCreateSubscriptionMutation,
	useUpdateSubscriptionMutation,
} = subscriptions
