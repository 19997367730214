import React from "react"

import {
	Redirect,
	Switch as RouteSwitch,
	useRouteMatch,
} from "react-router-dom"

import Devices from "./Devices"
import DeviceDetail from "./Devices/DeviceDetail"
import PairRoomDevice from "./Devices/PairDevice"
import RoomsOverview from "./Overview"
import RoomList from "./Rooms"
import EditRoom from "./Rooms/EditRoom"
import { ROOMS_PATHS } from "./constants"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Rooms = () => {
	let { path } = useRouteMatch()

	return (
		<RouteSwitch>
			<ProtectedRoute
				exact
				path={ROOMS_PATHS.overview}
				accessPermission="desk.change_deskresource"
				component={RoomsOverview}
			/>
			<ProtectedRoute
				exact
				path={ROOMS_PATHS.rooms}
				// accessPermission="" TODO: Add proper access permissions
				component={RoomList}
			/>
			<ProtectedRoute
				exact
				path={ROOMS_PATHS.addRoom}
				// accessPermission=""
				component={EditRoom}
			/>
			<ProtectedRoute
				exact
				path={`${ROOMS_PATHS.rooms}/:key`}
				// accessPermission="" TODO: Add proper access permissions
				component={EditRoom}
			/>
			<ProtectedRoute
				exact
				path={ROOMS_PATHS.devices}
				// accessPermission="" TODO: Add proper access permissions
				component={Devices}
			/>
			<ProtectedRoute
				exact
				path={ROOMS_PATHS.pair}
				// accessPermission="" TODO: Add proper access permissions
				component={PairRoomDevice}
			/>
			<ProtectedRoute
				exact
				path={`${ROOMS_PATHS.devices}/:device_id`}
				// accessPermission="" TODO: Add proper access permissions
				component={DeviceDetail}
			/>
			<Redirect to={`${path}/overview`} />
		</RouteSwitch>
	)
}

export default Rooms
