/* eslint-disable jsx-a11y/accessible-emoji */
import React, { SyntheticEvent, useCallback } from "react"

import { useTranslation } from "react-i18next"

import { meURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import CompanyModal from "../../modals/CompanyModal"
import PersonModal from "../../modals/PersonModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchCompanyQuery } from "../../redux/api/company"
import { useFetchMeQuery } from "../../redux/api/me"
import { DepartmentResponse } from "../../redux/departments/types"
import {
	formatUser,
	isOfficeManager,
	isPortalAdmin,
} from "../../redux/user/utils"

import Card from "../../components/Card"
import Heading from "../../components/Heading"
import Intro from "../../components/Intro"
import Space from "../../components/Space"
import { SsoProviders } from "../../components/SsoProviders"
import View from "../../components/View"
import { AsyncCheckbox } from "../../components/advanced/AsyncCheckbox"
import Avatar from "../../components/advanced/Avatar"
import Button from "../../components/basic/Button"

import { ReactComponent as PencilSVG } from "../../assets/images/icons/Pencil.svg"

import "./Profile.sass"

function gotoLogout(e: SyntheticEvent) {
	e.preventDefault()

	window.location.href = "/auth/logout"
}

function Profile() {
	const { openModal } = useModals()
	const { infoToast } = useToast()
	const { t } = useTranslation()
	const { data: user, isLoading, refetch } = useFetchMeQuery()

	const { data: company } = useFetchCompanyQuery()
	const personType =
		user?.groups && user?.groups?.length > 0 ? user?.groups[0] : ""

	const handleEditClick = useCallback(() => {
		openModal(PersonModal, {
			person: { ...user, type: personType },
			refetch,
		})
	}, [openModal, user, personType, refetch])

	const refresh = useCallback(
		(value: boolean) => {
			if (value) {
				infoToast(t("desktop.settings.account.privacy_settings_enabled_toast"))
			} else {
				infoToast(t("desktop.settings.account.privacy_settings_disabled_toast"))
			}
			refetch()
		},
		[infoToast, refetch, t],
	)

	const handleCompanyEdit = () => {
		openModal(CompanyModal)
	}

	const formattedUser = formatUser(user)

	if (!user) return null

	return (
		<View className="Profile">
			<Heading>{t("desktop.settings.account.title")}</Heading>

			<Space size={0.5} />

			<Card className="ProfileCard">
				<Avatar user={user} size="large" variant="square" />

				<div className="personal-info row">
					{formattedUser}
					{formattedUser !== user.email && (
						<>
							<br />
							<a href={`mailto:${user.email}`}>{user.email}</a>
						</>
					)}
					{isOfficeManager(user) && (
						<>
							<br />
							<span className="manager-title">
								{t("general.office_manager")}
							</span>
						</>
					)}
					{isPortalAdmin(user) && (
						<>
							<br />
							<span className="manager-title">
								{t("general.administrator")}
							</span>
						</>
					)}
				</div>
				{user.departments && user.departments.length > 0 && (
					<div className="user-departments">
						{user.departments.map((dep: DepartmentResponse, i: number) => (
							<span key={dep.id}>
								{dep.name}
								{user.departments.length !== i + 1 && ", "}
							</span>
						))}
					</div>
				)}
				{company?.name && (
					<div className="company-name row">
						{company.name}
						{isPortalAdmin(user) && (
							<span className="company-edit" onClick={handleCompanyEdit}>
								<PencilSVG />
							</span>
						)}
					</div>
				)}
				<div className="privacy-setting row">
					<AsyncCheckbox
						label={t("desktop.settings.account.book_anonymously")}
						urlGenerator={() => meURL()}
						bodyGenerator={(value: boolean) => ({
							privacy_mode_enabled: value,
						})}
						type="put"
						value={user.privacy_mode_enabled}
						refresh={refresh}
					/>
				</div>
			</Card>

			<Space size={0.5} />

			<Heading>{t("desktop.settings.account.connected_accounts")}</Heading>

			<Intro isConstrained>
				{t("desktop.settings.account.connected_accounts_info")}
			</Intro>

			<Space size={0.75} />

			<Card className="SsoProvidersCard">
				<SsoProviders />
			</Card>

			<Space size={0.75} />

			<div className="actions">
				<Button onClick={handleEditClick} isDisabled={isLoading}>
					{t("desktop.settings.account.edit_button")}
				</Button>
				<Button variant="secondary" onClick={gotoLogout}>
					{t("desktop.settings.account.log_out_button")}
				</Button>
			</div>
		</View>
	)
}

export default Profile
