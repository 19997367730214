import React, { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { usersURL } from "../../../api"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { formatUser } from "../../../redux/user/utils"
import { UserResponse } from "../../../redux/users/types"

import BuildingFilter from "../../../components/Filter/BuildingFilter"
import DepartmentFilter from "../../../components/Filter/DepartmentFilter"
import Filters from "../../../components/Filter/Filters"
import FloorFilter from "../../../components/Filter/FloorFilter"
import MultiAmenitiesFilter from "../../../components/Filter/MultiAmenitiesFilter"
import { MultipleAsyncSelector } from "../../../components/Filter/MultipleAsyncSelector"

export type Filter = {
	building: string
	floor: string
	department_id: string
	amenity_id: string[]
}
type FilterKeys = keyof Filter

type Props = {
	onChange: (filter: Filter) => Promise<void>
	onUsersChange: (users: string[]) => void
	defaultValues: Filter
}

const FloorPlanFilters = ({
	onChange,
	defaultValues,
	onUsersChange,
}: Props) => {
	const floorRef = useRef<string>(defaultValues.floor)
	const filtersRef = useRef<Filter>(defaultValues)
	const [filters, setFilters] = useState<Filter>(defaultValues)
	const [users, setUsers] = useState<UserResponse[]>([])

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

	const { t } = useTranslation()

	const handleOnChange = useCallback(
		(filterName: FilterKeys) => (value: string | string[] | UserResponse[]) => {
			// the FloorFilter triggers unwanted onChanges this is temporary fix
			if (filterName === "floor" && floorRef.current === value) {
				return
			}

			filtersRef.current = {
				...filtersRef.current,
				[filterName]: value,
			}
			floorRef.current = filtersRef.current.floor

			if (filterName === "building") {
				if (!!buildings.find((b) => b.id === value)) {
					setFilters(filtersRef.current)
					return
				} else {
					filtersRef.current = {
						...filtersRef.current,
						floor: "",
					}
				}
			}

			onChange(filtersRef.current as Filter)
			setFilters(filtersRef.current)
		},
		[buildings, onChange],
	)

	const handleOnUserChange = (users: UserResponse[]) => {
		onUsersChange(users.map((u) => u.email))
		setUsers(users)
	}

	const amenities =
		typeof filters.amenity_id === "string"
			? [filters.amenity_id]
			: filters.amenity_id

	return (
		<Filters>
			<BuildingFilter
				value={filters.building ?? ""}
				onChange={handleOnChange("building")}
			/>
			<FloorFilter
				value={filters.floor ?? ""}
				onChange={handleOnChange("floor")}
				buildingId={filters.building}
				showOnlyWithPlan
			/>
			<DepartmentFilter
				value={filters.department_id ?? ""}
				onChange={handleOnChange("department_id")}
				showAll={true}
				showNotAssigned={true}
			/>
			<MultiAmenitiesFilter
				preSelectedValues={amenities}
				onChange={handleOnChange("amenity_id")}
			/>
			<MultipleAsyncSelector
				options={users}
				onChange={handleOnUserChange}
				urlGenerator={(fetchOptions) => {
					return usersURL(fetchOptions)
				}}
				placeholder={t("general.select.user")}
				nothingFoundMessage={t("general.not_found.no_user_found")}
				getOptionLabel={(dataPoint) => {
					return formatUser(dataPoint)
				}}
				getOptionValue={(dataPoint) => {
					return dataPoint.email
				}}
				extraClassName="UserSelector"
			/>
		</Filters>
	)
}

export default FloorPlanFilters
