import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { usersURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import { calculatePercentAmount } from "../../utils"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import { LimitOccupancyDecider } from "../advanced/LimitOccupancyDecider"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	createDepartment,
	destroyDepartment,
	fetchDepartments,
	updateDepartment,
} from "../../redux/departments/departmentsSlice"
import {
	DepartmentRequest,
	DepartmentResponse,
	FetchOptions,
} from "../../redux/departments/types"
import { getNumberOfDesksInDepartment } from "../../redux/departments/utils"
import { formatUser } from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"
import { useActions } from "../../redux/utils"

import "./DepartmentForm.sass"

type Props = {
	department: DepartmentResponse | null
}

type FormValues = {
	name: string
	users: UserResponse[]
	capacity_limit: number | null
	active: boolean
}

const DepartmentForm = ({ department }: Props) => {
	const { closeModal } = useModals()
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const { id, name, users, capacity_limit, active } = department ?? {}

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? "",
			capacity_limit: capacity_limit ?? null,
			active: department ? active : true,
			users: users ?? [],
		},
	})

	const {
		setError,
		control,
		getValues,
		formState: { isSubmitting },
	} = methods

	const actions = useActions({
		fetchDepartments: (options: FetchOptions) => fetchDepartments(options),
		createDepartment: (department: DepartmentRequest) =>
			createDepartment(department),
		updateDepartment: (id: string, department: DepartmentRequest) =>
			updateDepartment({ id, ...department }),
		destroyDepartment: (id: string) => destroyDepartment(id),
	})

	const onCreateClick = useCallback(
		async ({ name, capacity_limit, users, active }: FormValues) => {
			const response = await actions.createDepartment({
				name,
				capacity_limit: capacity_limit ?? undefined,
				users: users.map((u) => u.email),
				active,
			})

			if (createDepartment.fulfilled.match(response)) {
				const departmentsResponse = await actions.fetchDepartments({
					stats: true,
				})
				infoToast(
					t(
						"desktop.settings.departments.department_form.department_created_toast",
					),
				)
				if (fetchDepartments.fulfilled.match(departmentsResponse)) {
					analyticsEvent(SupportedEvents.DEPARTMENT_ADD, {
						id: response.payload.id,
						name: response.payload.name,
						total: departmentsResponse.payload.count,
					})
				}

				closeModal()
			} else if (
				createDepartment.rejected.match(response) &&
				response.payload
			) {
				setErrors(response.payload, setError, errorToast)
			}
		},
		[actions, infoToast, t, closeModal, setError, errorToast],
	)

	const onUpdateClick = useCallback(
		async ({ name, capacity_limit, users, active }: FormValues) => {
			if (id) {
				const response = await actions.updateDepartment(id, {
					name,
					capacity_limit: capacity_limit,
					users: users.map((u) => u.email),
					active,
				})

				if (updateDepartment.fulfilled.match(response)) {
					await actions.fetchDepartments({ stats: true })
					infoToast(
						t(
							"desktop.settings.departments.department_form.department_updated_toast",
						),
					)

					analyticsEvent(SupportedEvents.DEPARTMENT_UPDATE, {
						id: response.payload.id,
						name: response.payload.name,
					})

					closeModal()
				} else if (
					updateDepartment.rejected.match(response) &&
					response.payload
				) {
					setErrors(response.payload, setError, errorToast)
				}
			}
		},
		[id, actions, infoToast, t, closeModal, setError, errorToast],
	)

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()
			if (id) {
				const response = await actions.destroyDepartment(id)

				if (destroyDepartment.fulfilled.match(response)) {
					const departmentsResponse = await actions.fetchDepartments({
						stats: true,
					})
					infoToast(
						t(
							"desktop.settings.departments.department_form.department_deleted_toast",
						),
					)
					if (fetchDepartments.fulfilled.match(departmentsResponse)) {
						analyticsEvent(SupportedEvents.DEPARTMENT_DELETE, {
							id: id,
							total: departmentsResponse.payload.count,
						})

						closeModal()
					}
				} else {
					errorToast(response.error.message)
				}
			}
		},
		[id, actions, infoToast, t, closeModal, errorToast],
	)

	const totalDeskNo = getNumberOfDesksInDepartment(department)
	const activeDeskAmount = calculatePercentAmount(
		totalDeskNo,
		getValues().capacity_limit || 100,
	)
	const isUpdateMode = !!id

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="DepartmentForm"
				updateMode={isUpdateMode}
				title={
					isUpdateMode
						? t("desktop.settings.departments.department_form.edit_department")
						: t("desktop.settings.departments.department_form.new_department")
				}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
			>
				<Field
					control={control}
					name="name"
					label={t(
						"desktop.settings.departments.department_form.department_name",
					)}
				>
					{(props) => (
						<Input
							autoFocus
							maxLength={60}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="users"
					label={t(
						"desktop.settings.departments.department_form.assigned_employees",
					)}
					subText={t("general.optional")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							isMulti
							urlGenerator={(fetchOptions) => usersURL(fetchOptions)}
							nothingFoundMessage={t(
								"desktop.settings.departments.department_form.no_users_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field control={control} name="capacity_limit">
					{(props) => (
						<LimitOccupancyDecider
							{...props}
							label={t(
								"desktop.settings.departments.department_form.department_occupancy_limit",
							)}
							infoText={t(
								"desktop.settings.departments.department_form.department_bookable_desks",

								{ activeDeskAmount, totalDeskNo },
							)}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field control={control} name="active" className="asset-enabled-field">
					{(props) => (
						<Switch
							label={t(
								"desktop.settings.departments.department_form.enable_department",
							)}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default DepartmentForm
