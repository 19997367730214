import React, { useMemo, useState } from "react"

import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../hooks/useToast"
import { forceMode } from "../../../utils"
import LogoVariant from "../../LogoVariant"

import { requestLogin } from "../../../redux/auth/authSlice"
import { useActions } from "../../../redux/utils"

import Field from "../../../components/Field"
import { setErrors } from "../../../components/Form/formUtils"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import Button from "../../../components/basic/Button"
import { Checkbox } from "../../../components/basic/Checkbox"
import { Input } from "../../../components/basic/Input"
import Loader from "../../../components/basic/Loader"

import "./Start.sass"

type FormValues = {
	email: string
	terms: boolean
}

const Start: React.FC = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { errorToast } = useToast()
	const {
		setError,
		control,
		formState: { isSubmitting, isValid },
		handleSubmit,
	} = useForm<FormValues>({
		defaultValues: {
			email: "",
			terms: false,
		},
	})

	const [hasError, setHasError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { reqLogin } = useActions({
		reqLogin: (email: string) => requestLogin(email),
	})

	const params = useMemo(
		() => new URLSearchParams(history.location.search),
		[history.location.search],
	)

	const next = params.get("next")

	const handleStart = async (values: FormValues) => {
		setIsLoading(true)
		const response = await reqLogin(values.email)
		if (requestLogin.rejected.match(response)) {
			setHasError(true)
			if (response.payload) {
				setErrors(response.payload, setError, errorToast)
			}
			setIsLoading(false)
			return
		} else {
			setHasError(false)
			if (next) {
				history.push(`/auth/login?next=${next}`)
			} else {
				history.push("/auth/login")
			}
		}
	}

	const handleForceTablet = () => {
		forceMode("tablet")
		window.location.href = window.location.origin
	}
	return (
		<form onSubmit={handleSubmit(handleStart)}>
			<SafeViewArea className="Start">
				<div className="body">
					<LogoVariant variant="mobile" />
					{!isLoading ? (
						<div>
							{!hasError ? (
								<>
									<h2>{t("mobile.auth.welcome")}</h2>
									<p>{t("mobile.auth.email_info")}</p>
								</>
							) : (
								<>
									<h2>{t("mobile.auth.login_failed")}</h2>
									<p>{t("mobile.auth.try_again")}</p>
								</>
							)}
							<div>
								<Field control={control} name="email">
									{(props) => <Input variant="mobile" {...props} />}
								</Field>
							</div>
							<div className="confirmation">
								<Field
									control={control}
									name="terms"
									rules={{ required: true }}
								>
									{(props) => (
										<Checkbox
											isSecondary
											{...props}
											label={
												<div>
													<Trans i18nKey="mobile.auth.terms_agreement">
														<a href="https://getjoan.com/terms-of-use/">
															terms
														</a>
														<a href="https://getjoan.com/privacy-policy/">
															policy
														</a>
													</Trans>
												</div>
											}
										/>
									)}
								</Field>
							</div>
						</div>
					) : (
						<div>
							<Loader variant="fullScreen" />
						</div>
					)}
				</div>
				{!isSubmitting && (
					<div className="action">
						<Button
							isSubmit
							variant="mobile-action"
							isDisabled={isSubmitting || !isValid}
						>
							{t("mobile.auth.log_in")}
						</Button>
						<Button
							variant="secondary-white"
							className="cant-login-button"
							onClick={() => {
								history.push("/auth/help")
							}}
						>
							{t("mobile.auth.cant_login")}
						</Button>
						<span className="app-switch">
							{t("mobile.auth.visitor_app")}{" "}
							<span className="switch" onClick={handleForceTablet}>
								{t("mobile.auth.click_here")}
							</span>
						</span>
					</div>
				)}
			</SafeViewArea>
		</form>
	)
}

export default Start
