import { MouseEvent, useState } from "react"

import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import { DeleteReservationType } from "../../modals/DeleteReservationModal"
import { DeleteTypeField } from "../Field/DeleteTypeField"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import { useDeleteDeskReservationMutation } from "../../redux/api/deskReservations"
import { isRejected } from "../../redux/api/types"
import { deleteAssetReservation } from "../../redux/asset_reservation/assetReservationSlice"
import { DeleteType } from "../../redux/reservations/types"
import { useActions } from "../../redux/utils"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./DeleteReservationForm.sass"

interface Props {
	reservationId: string
	reservationType: DeleteReservationType
	dataUpdate?: () => void
}

export const DeleteReservationForm = ({
	reservationId,
	dataUpdate,
	reservationType,
}: Props) => {
	const { closeModal, closeAllModals, openModal } = useModals()
	const { t } = useTranslation()
	const [deleteOption, setDeleteOption] = useState<DeleteType>(
		DeleteType.CURRENT,
	)
	const [deleteDeskReservation] = useDeleteDeskReservationMutation()

	const actions = useActions({
		deleteDeskReservation: (id: string, type: DeleteType) =>
			deleteDeskReservation({ id, type }),
		deleteAssetReservation: (id: string, type: DeleteType) =>
			deleteAssetReservation({ reservationId: id, type: type }),
	})

	const onDeleteClick = async (e: MouseEvent<HTMLElement>) => {
		e.preventDefault()

		let response
		if (reservationType === "desk") {
			response = await deleteDeskReservation({
				id: reservationId,
				type: deleteOption,
			})
		} else if (reservationType === "asset") {
			response = await actions.deleteAssetReservation(
				reservationId,
				deleteOption,
			)
		}
		if (response) {
			if (!isRejected(response)) {
				if (deleteOption === DeleteType.CURRENT) {
					toast.info("Reservation deleted", { hideProgressBar: true })
				} else {
					toast.info("Reservations deleted", { hideProgressBar: true })
				}
				dataUpdate?.()
			} else {
				toast.error(response.error.message, { hideProgressBar: true })
			}
		}
	}

	const onDeleteConfirmation = (e: MouseEvent<HTMLElement>) => {
		openModal(ConfirmationModal, {
			onConfirm: () => {
				onDeleteClick(e)
				closeAllModals()
			},
		})
	}

	return (
		<div className="DeleteReservationForm ModalForm">
			<div className="title">
				<h1>{t("desktop.manage.desk_booking.form.delete_recurring")}</h1>
			</div>
			<div className="close" onClick={() => closeModal()}>
				<CrossSVG />
			</div>

			<div>
				<DeleteTypeField value={deleteOption} onChange={setDeleteOption} />
			</div>

			<div className="actions actions-delete-only">
				<Button
					variant="danger-pop"
					noConfirm
					className="delete"
					onClick={onDeleteConfirmation}
				>
					Delete
				</Button>
			</div>
		</div>
	)
}

export default DeleteReservationForm
