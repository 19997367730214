import { api } from ".."
import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { assetsGetURL, assetsURL } from "../../../api"
import { PaginatedOptions } from "../../reduxUtils"
import {
	AssetCreateRequest,
	AssetFetchRequest,
	AssetRequestWithId,
	AssetResponse,
	AssetsResponse,
} from "./types"
import { Draft, current } from "@reduxjs/toolkit"

import { Asset } from "../../assets/types"

export const assets = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchAssets: builder.query<
			AssetsResponse & { offset: number },
			(PaginatedOptions & AssetFetchRequest) | void
		>({
			query: (options) => ({
				url: assetsGetURL({ ...options }),
			}),
			providesTags: (result, _error, arg) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "Assets" as const,
								id,
							})),
							{ type: "Assets", id: "LIST" },
					  ]
					: [{ type: "Assets", id: "LIST" }],
			transformResponse: (response: AssetsResponse, _, arg) => {
				return {
					...response,
					offset: arg?.offset ?? 0,
				}
			},
		}),
		fetchAsset: builder.query<AssetResponse, string>({
			query: (id) => ({
				url: assetsURL(id),
			}),
			providesTags: (_result, _error, id) => [{ type: "Assets", id }],
		}),
		createAsset: builder.mutation<AssetResponse, AssetCreateRequest>({
			query: (body) => ({
				url: assetsURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Assets", id: "LIST" }],
			onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
				try {
					const { data: asset, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(
							assets.util.updateQueryData(
								"fetchAssets",
								undefined,
								(draft: Draft<AssetsResponse>) => {
									const assets = current(draft)

									analyticsEvent(SupportedEvents.ASSET_ADD, {
										id: asset.id,
										name: asset.name,
										assigned_to_departments: asset.departments.length > 0,
										assigned_to_users: asset.users.length > 0,
										total: assets.count + 1,
									})
								},
							),
						)
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		updateAsset: builder.mutation<AssetResponse, AssetRequestWithId>({
			query: ({ id, ...body }) => ({
				url: assetsURL(id),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "Assets", id: "LIST" },
				{ type: "Assets", id },
			],
			onQueryStarted: async (_arg, { queryFulfilled }) => {
				try {
					const { data: asset, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						analyticsEvent(SupportedEvents.ASSET_UPDATE, {
							id: asset.id,
							name: asset.name,
						})
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		destroyAsset: builder.mutation<void, string>({
			query: (id) => ({
				url: assetsURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "Assets", id: "LIST" },
				{ type: "Assets", id },
			],
			onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
				try {
					const { meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(
							assets.util.updateQueryData(
								"fetchAssets",
								undefined,
								(draft: Draft<AssetsResponse>) => {
									const assets = current(draft)

									analyticsEvent(SupportedEvents.ASSET_DELETE, {
										id,
										name: assets.results.find((a: Asset) => a.id === id)?.name,
										total: assets.count - 1,
									})
								},
							),
						)
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
	}),
})

export const {
	useFetchAssetsQuery,
	useLazyFetchAssetsQuery,
	useFetchAssetQuery,
	useCreateAssetMutation,
	useUpdateAssetMutation,
	useDestroyAssetMutation,
} = assets
