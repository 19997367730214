import { PropsWithChildren, useCallback, useEffect, useState } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { useToast } from "../hooks/useToast"
import CheckInsModal from "../modals/CheckInsModal"

import { useCheckinDeskReservationMutation } from "../redux/api/deskReservations"
import {
	isEmployeeScreeningNotApplicable,
	useLazyFetchEmployeeScreeningQuery,
} from "../redux/api/screenings"
import { isRejected } from "../redux/api/types"
import { checkinAssetReservation } from "../redux/asset_reservation/assetReservationSlice"
import { fetchCheckIns } from "../redux/check_ins/checkInsSlice"
import { selectCheckIns } from "../redux/check_ins/selectors"
import {
	CHECK_INS_TYPES,
	CheckInResponse,
	FetchOptions,
} from "../redux/check_ins/types"
import { useAppSelector } from "../redux/reducers"
import { selectIsAuth } from "../redux/selectors"
import { selectSettingsEffective } from "../redux/settings/selectors"
import { useActions } from "../redux/utils"

export const CheckInProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [opened, setOpened] = useState(false)

	const isAuth = useAppSelector(selectIsAuth)
	const { entry: settings } = useAppSelector(selectSettingsEffective)
	const { entries: checkIns, count, isLoaded } = useAppSelector(selectCheckIns)

	const { errorToast } = useToast()
	const [checkinDeskReservation] = useCheckinDeskReservationMutation()
	const [fetchScreening] = useLazyFetchEmployeeScreeningQuery()
	const history = useHistory()

	const actions = useActions({
		fetchCheckIns: (options?: FetchOptions) => fetchCheckIns(options),
		checkInAsset: (id: string) => checkinAssetReservation(id),
	})

	const handleCheckIn = useCallback(
		async (checkIn: CheckInResponse) => {
			const { type, id, building } = checkIn

			switch (type) {
				case CHECK_INS_TYPES.ASSET: {
					const response = await actions.checkInAsset(id)

					if (checkinAssetReservation.rejected.match(response)) {
						errorToast(response.error.message)
						return
					}
					break
				}
				default: {
					const screening = await fetchScreening(building.id)

					if (
						isEmployeeScreeningNotApplicable(screening) ||
						screening?.data?.passed === true
					) {
						const response = await checkinDeskReservation(id)

						if (isRejected(response)) {
							errorToast(response.error.message)
							return
						}
					}

					if (!isRejected(screening)) {
						history.push(
							`/screening/employee/${building.id}?${queryString.stringify({
								id,
							})}`,
						)
					}
					break
				}
			}

			await actions.fetchCheckIns()
		},
		[actions, checkinDeskReservation, errorToast, fetchScreening, history],
	)

	useEffect(() => {
		if (isAuth) {
			actions.fetchCheckIns()
		}
	}, [actions, isAuth])

	useEffect(() => {
		if (!settings?.desk_check_in_enabled || !isLoaded) return

		if (count < 1) {
			setOpened(false)
			return
		}

		setOpened(true)
	}, [settings?.desk_check_in_enabled, checkIns, count, isLoaded])

	if (history.location.pathname.startsWith("/screening/employee")) {
		return <>{children}</>
	}

	return (
		<>
			<CheckInsModal
				onClose={() => setOpened(false)}
				open={opened}
				handleCheckIn={handleCheckIn}
			/>
			{children}
		</>
	)
}
