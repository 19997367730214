import { FormEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { departmentsURL, usersURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import { IdAndName } from "../../types/sharedTypes"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	useCreateAssetMutation,
	useDestroyAssetMutation,
	useUpdateAssetMutation,
} from "../../redux/api/assets"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { AssetType } from "../../redux/asset_types/types"
import { Asset } from "../../redux/assets/types"
import { DepartmentResponse } from "../../redux/departments/types"
import { formatUser } from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"

import "./AssetForm.sass"

type AssetTypeLike = IdAndName & Partial<AssetType>

type Props = {
	assetType: AssetTypeLike
	asset?: Asset
}
type FormValues = {
	name: string
	description: string
	departments: DepartmentResponse[]
	users: UserResponse[]
	active: boolean
}

const AssetForm = ({ assetType, asset }: Props) => {
	const { closeModal } = useModals()
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()
	const methods = useForm<FormValues>({
		defaultValues: {
			name: asset?.name ?? "",
			description: asset?.description ?? "",
			departments: asset?.departments ?? [],
			users: asset?.users ?? [],
			active: asset ? asset.active : true,
		},
	})
	const {
		setError,
		control,
		formState: { isSubmitting },
	} = methods

	const [createAsset] = useCreateAssetMutation()
	const [updateAsset] = useUpdateAssetMutation()
	const [destroyAsset] = useDestroyAssetMutation()

	const isUpdateMode: boolean = asset !== undefined

	const title = isUpdateMode ? (
		<Trans
			i18nKey="desktop.settings.assets.asset_form.edit_asset_title"
			values={{ assetTypeName: assetType.name }}
		/>
	) : (
		<Trans
			i18nKey="desktop.settings.assets.asset_form.new_asset_title"
			values={{ assetTypeName: assetType.name }}
		/>
	)

	const getAsset = useCallback(
		({ name, description, departments, users, active }: FormValues) => ({
			name,
			description: description,
			departments: departments.map((d) => d.id),
			users: users.map((u) => u.email),
			asset_type_id: assetType.id,
			active,
		}),
		[assetType.id],
	)

	const onCreateClick = useCallback(
		async (values: FormValues) => {
			const response = await createAsset(getAsset(values))

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
				}
			} else {
				infoToast(t("desktop.settings.assets.asset_form.asset_created_toast"))
				closeModal()
			}
		},
		[closeModal, createAsset, errorToast, getAsset, infoToast, setError, t],
	)

	const onUpdateClick = useCallback(
		async (values: FormValues) => {
			if (asset) {
				const response = await updateAsset({
					...getAsset(values),
					id: asset.id,
				})

				if (isRejected(response)) {
					const { error } = response

					if (isApiResponseError(error)) {
						setErrors(error.formError, setError, errorToast)
					}
				} else {
					infoToast(t("desktop.settings.assets.asset_form.asset_updated_toast"))
					closeModal()
				}
			}
		},
		[
			asset,
			closeModal,
			errorToast,
			getAsset,
			infoToast,
			setError,
			t,
			updateAsset,
		],
	)

	const onDeleteClick = useCallback(
		async (e: FormEvent) => {
			if (asset) {
				const response = await destroyAsset(asset.id)

				if (isRejected(response)) {
					const { error } = response

					if (isApiResponseError(error)) {
						errorToast(error.message)
					}
				} else {
					infoToast(t("desktop.settings.assets.asset_form.asset_deleted_toast"))

					closeModal()
				}
			}
		},
		[asset, closeModal, destroyAsset, errorToast, infoToast, t],
	)

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="asset-form"
				updateMode={isUpdateMode}
				title={title}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
			>
				<Field
					control={control}
					name="name"
					label={t("desktop.settings.assets.asset_form.name")}
				>
					{(props) => (
						<Input
							autoFocus
							maxLength={100}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="description"
					label={t("desktop.settings.assets.asset_form.description")}
					subText={t("general.optional")}
				>
					{(props) => (
						<Input maxLength={200} disabled={isSubmitting} {...props} />
					)}
				</Field>
				<Field
					control={control}
					name="departments"
					label={t("desktop.settings.assets.asset_form.departments")}
					subText={t("general.optional")}
				>
					{(props) => (
						<AsyncSelect
							isMulti
							urlGenerator={(fetchOptions) => {
								return departmentsURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.assets.asset_form.no_departments_found",
							)}
							getOptionLabel={(department) => department?.name ?? ""}
							getOptionValue={(department) => department?.id ?? ""}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="users"
					label={t("desktop.settings.assets.asset_form.assign_to")}
					subText={t("general.optional")}
				>
					{(props) => (
						<AsyncSelect
							isMulti
							urlGenerator={(fetchOptions) => {
								return usersURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.assets.asset_form.no_user_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>

				<Field control={control} name="active" className="asset-enabled-field">
					{(props) => (
						<Switch
							label={t("desktop.settings.assets.asset_form.enable_asset")}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default AssetForm
