import { api } from ".."
import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { assetTypesGetURL, assetTypesURL } from "../../../api"
import { nameComparator } from "../../../utils"
import { AssetType } from "../../asset_types/types"
import { PaginatedOptions } from "../../reduxUtils"
import {
	AssetTypeResponse,
	AssetTypeWithIdRequest,
	AssetTypesResponse,
	CreateAssetTypeRequest,
	FetchAssetTypesRequest,
} from "./types"
import { Draft, current } from "@reduxjs/toolkit"

export const assetTypes = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchAssetTypes: builder.query<
			AssetTypesResponse,
			(PaginatedOptions & FetchAssetTypesRequest) | void
		>({
			query: (options) => ({
				url: assetTypesGetURL({ ...options }),
			}),
			providesTags: (result, _error, arg) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "AssetTypes" as const,
								id,
							})),
							{ type: "AssetTypes", id: "LIST" },
					  ]
					: [{ type: "AssetTypes", id: "LIST" }],
			transformResponse: (response: AssetTypesResponse, _, arg) => {
				return {
					...response,
					results: response.results.sort(nameComparator),
					offset: arg?.offset ?? 0,
				}
			},
		}),
		fetchAssetType: builder.query<AssetTypeResponse, string>({
			query: (id) => ({
				url: assetTypesURL(id),
			}),
			providesTags: (_result, _error, id) => [{ type: "AssetTypes", id }],
		}),
		createAssetType: builder.mutation<
			AssetTypeResponse,
			CreateAssetTypeRequest
		>({
			query: (body) => ({
				url: assetTypesURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "AssetTypes", id: "LIST" }],
			onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
				try {
					const { data, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(
							assetTypes.util.updateQueryData(
								"fetchAssetTypes",
								undefined,
								(draft: Draft<AssetTypesResponse>) => {
									const assetTypes = current(draft)

									analyticsEvent(SupportedEvents.ASSET_TYPE_ADD, {
										id: data.id,
										name: data.name,
										icon: data.icon,
										total: assetTypes.count + 1,
									})
								},
							),
						)
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		updateAssetType: builder.mutation<
			AssetTypeResponse,
			AssetTypeWithIdRequest
		>({
			query: ({ id, ...body }) => ({
				url: assetTypesURL(id),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "AssetTypes", id: "LIST" },
				{ type: "AssetTypes", id },
			],
			onQueryStarted: async (_arg, { queryFulfilled }) => {
				try {
					const { data, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						analyticsEvent(SupportedEvents.ASSET_TYPE_UPDATE, {
							id: data.id,
							name: data.name,
							icon: data.icon,
						})
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		destroyAssetType: builder.mutation<void, string>({
			query: (id) => ({
				url: assetTypesURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "AssetTypes", id: "LIST" },
				{ type: "AssetTypes", id },
			],
			onQueryStarted: async (id, { dispatch, queryFulfilled, getState }) => {
				try {
					const { meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(
							assetTypes.util.updateQueryData(
								"fetchAssetTypes",
								undefined,
								(draft: Draft<AssetTypesResponse>) => {
									const assetTypes = current(draft)

									analyticsEvent(SupportedEvents.ASSET_TYPE_DELETE, {
										id,
										name: assetTypes.results.find(
											(assetType: AssetType) => assetType.id === id,
										)?.name,
										total: assetTypes.count - 1,
									})
								},
							),
						)
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
	}),
})

export const {
	useFetchAssetTypesQuery,
	useLazyFetchAssetTypesQuery,
	useFetchAssetTypeQuery,
	useCreateAssetTypeMutation,
	useUpdateAssetTypeMutation,
	useDestroyAssetTypeMutation,
} = assetTypes
