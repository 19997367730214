import {
	PLAN_GROUPS,
	PLAN_TYPES,
	SUBSCRIPTION_STATUSES,
	TRIAL_TYPES,
} from "../../../../redux/api/billing/constants"
import { Subscription } from "../../../../redux/api/billing/subscriptions/types"
import {
	PlanType,
	SubscriptionStatus,
	TrialType,
} from "../../../../redux/api/billing/types"

import { getPlanGroup } from "../../../../components/advanced/SubscriptionCard/utils"

export const activeStatuses: SubscriptionStatus[] = [
	SUBSCRIPTION_STATUSES.ACTIVE,
	SUBSCRIPTION_STATUSES.IN_TRIAL,
	SUBSCRIPTION_STATUSES.NON_RENEWING,
]

export const getCardSubscriptions = (
	subscriptions: Subscription[],
): (Subscription | { plan_type: PlanType })[] => {
	const activeSubscriptions = subscriptions.filter((s) =>
		activeStatuses.includes(s.status),
	)
	const displaySubscription = AVAILABLE_PLAN_GROUPS.reduce(
		(subs, ap) => {
			if (
				!activeSubscriptions.find(
					(as) => getPlanGroup(as.plan_type) === ap.group,
				)
			) {
				subs.push({ plan_type: ap.planType })
			}
			return subs
		},
		[...activeSubscriptions] as (Subscription | { plan_type: PlanType })[],
	)

	return displaySubscription
}

export const planTypeToTrialType = (planType: PlanType): TrialType => {
	switch (planType) {
		case PLAN_TYPES.DESK_BOOKING:
		case PLAN_TYPES.DESK_BOOKING_EAP:
		case PLAN_TYPES.DESK_ESSENTIALS:
			return TRIAL_TYPES.DESK
		case PLAN_TYPES.HOME:
		case PLAN_TYPES.HOME_ESSENTIALS:
			return TRIAL_TYPES.HOME
		case PLAN_TYPES.VISITOR_PROFESSIONAL:
		case PLAN_TYPES.VISITOR_PROFESSIONAL_EAP:
			return TRIAL_TYPES.VISITOR
		case PLAN_TYPES.SIGN:
		case PLAN_TYPES.SIGN_ESSENTIALS:
		case PLAN_TYPES.SIGN_PROFESSIONAL:
			return TRIAL_TYPES.SIGN
		default:
			return TRIAL_TYPES.ROOM
	}
}

export const AVAILABLE_PLAN_GROUPS = [
	{ group: PLAN_GROUPS.DESK_BOOKING, planType: PLAN_TYPES.DESK_ESSENTIALS },
	{ group: PLAN_GROUPS.ROOM, planType: PLAN_TYPES.ROOM_ENTERPRISE },
	{ group: PLAN_GROUPS.SIGN, planType: PLAN_TYPES.SIGN_PROFESSIONAL },
	{
		group: PLAN_GROUPS.VISITOR,
		planType: PLAN_TYPES.VISITOR_PROFESSIONAL,
	},
]
