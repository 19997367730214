import React, {
	MouseEvent as ReactMouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"

import { DraggableEvent } from "react-draggable"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import { queryBuilder } from "../../../../api/queryBuilder"
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside"
import { useToast } from "../../../../hooks/useToast"
import DeskModal from "../../../../modals/DeskModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { AmenityResponse } from "../../../../redux/amenities/types"
import {
	useFetchDesksQuery,
	useUpdateDeskMutation,
} from "../../../../redux/api/desks"
import {
	DeskRequest,
	DeskRequestWithId,
	DeskResponse,
} from "../../../../redux/api/desks/types"
import { useFetchFloorsQuery } from "../../../../redux/api/floors"
import { isRejected } from "../../../../redux/api/types"
import { setRepositionDesk } from "../../../../redux/app/appSlice"
import { DepartmentResponse } from "../../../../redux/departments/types"
import { FloorResponse } from "../../../../redux/floors/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectUserPermissions } from "../../../../redux/user/selectors"
import { UserResponse } from "../../../../redux/users/types"
import { useActions } from "../../../../redux/utils"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Card from "../../../../components/Card"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import DepartmentFilter from "../../../../components/Filter/DepartmentFilter"
import Filters from "../../../../components/Filter/Filters"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import Map from "../../../../components/Map"
import Place from "../../../../components/Place"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import Button from "../../../../components/basic/Button"
import Divider from "../../../../components/basic/Divider"
import Loader from "../../../../components/basic/Loader"

import { ReactComponent as ChairSvg } from "../../../../assets/images/icons/Chair.svg"
import { ReactComponent as DeselectSVG } from "../../../../assets/images/icons/Deselect.svg"
import { ReactComponent as PencilSVG } from "../../../../assets/images/icons/Pencil.svg"

/* import { ReactComponent as QrCodeSvg } from "../../assets/images/icons/QrCode.svg" */
import { ReactComponent as SelectSVG } from "../../../../assets/images/icons/Select.svg"

import "./style.sass"

const getMouseXY = (
	event: Pick<MouseEvent, "x" | "y">,
	img: HTMLImageElement,
	map?: FloorResponse,
) => {
	const { x: mouseX, y: mouseY } = event
	const { width: sourceWidth, height: sourceHeight } = map ?? {}
	const {
		x: clientX,
		y: clientY,
		width: clientWidth,
		height: clientHeight,
	} = img.getBoundingClientRect()

	const localX = mouseX - clientX
	const localY = mouseY - clientY

	const widthRatio = sourceWidth! / clientWidth
	const heightRatio = sourceHeight! / clientHeight

	const x = localX * widthRatio
	const y = localY * heightRatio

	return { x, y }
}

const getCoordsFormEvent = (event: DraggableEvent) => {
	if (!(event instanceof MouseEvent)) {
		if (event.target && "getBoundingClientRect" in event.target) {
			const { top, bottom, left, right } = (
				event.target.getBoundingClientRect as Function
			)()

			return { x: (left + right) / 2, y: (top + bottom) / 2 }
		}

		return { x: 0, y: 0 }
	}
	return { x: event.x, y: event.y }
}

/**
 * Why we use Y_OFFSET_DESK?
 *
 * Current positioning of desks on the map in production does not
 * work correctly - when creating or repositioning a desk - it is shifted
 * 4 pixels down the Y axis. To fix the incorrect display, a decision was made
 * use a little hack where we need to apply -4 pixels every time the desk is moved
 * or set.
 *
 * Note: The source of the issue is the height of the container,
 * which is ~4 pixels different from the plan image. When trying to fix
 * this difference, all current desks of existing users in the system will be
 * shifted 4 pixels up on the y-axis, which would be unacceptable.
 */

const Y_OFFSET_DESK: number = 4

function DeskLayout() {
	const { t } = useTranslation()

	const { infoToast } = useToast()

	const { openModal } = useModals()

	const { search } = useLocation()

	const query = useMemo(() => new URLSearchParams(search), [search])

	const [selectedDesks, setSelectedDesks] = useState<string[]>([])

	const actions = useActions({
		updateDesk: (id: string, desk: DeskRequest) => updateDesk({ id, ...desk }),
		repositionDesk: (desk: DeskRequestWithId | null) => setRepositionDesk(desk),
	})

	let { reposition_desk } = useAppSelector((state) => state.app)

	const permissions = useAppSelector(selectUserPermissions)
	const canEditDesks =
		permissions.includes("desk.add_deskresource") &&
		permissions.includes("desk.delete_deskresource")

	const [isRepositionInitialized, setRepositionInitialized] = useState(false)

	const [isStartDragReposition, setStartDragReposition] = useState(false)

	const [dragDesk, setDragDesk] = useState<string>("")
	const [dragDeskScale, setDragDeskScale] = useState<number>(1)

	const [hoveredDesk, setHoveredDesk] = useState<string>("")

	const handleUpdateScale = (value: number) => setDragDeskScale(value)

	const editingDeskId = reposition_desk && reposition_desk.id

	// Filters
	const [departmentFilter, setDepartmentFilter] = useState<string>(
		FilterSpecialValues.ALL,
	)

	const [buildingFilter, setBuildingFilter] = useState<string>(
		query.get("building") || FilterSpecialValues.EMPTY,
	)

	const [floorFilter, setFloorFilter] = useState<string>(
		query.get("floor") || FilterSpecialValues.EMPTY,
	)

	const [updateDesk] = useUpdateDeskMutation()

	const { data: { results: floors = [] } = {} } = useFetchFloorsQuery(
		{
			building: buildingFilter,
		},
		{
			skip: !buildingFilter,
		},
	)

	const { data: { results: desks = [] } = {}, isFetching: isLoading } =
		useFetchDesksQuery({ floor: floorFilter, department_id: departmentFilter })

	useEffect(() => {
		setSelectedDesks([])
	}, [buildingFilter, desks.length])

	const mapBoxRef = useRef<HTMLDivElement>(null)
	const imageRef = useRef<HTMLImageElement>(null)

	const floor: FloorResponse | undefined = floors.find(
		(f: FloorResponse) => f.id === floorFilter,
	)

	const newDesk = useCallback(
		(desk: DeskResponse) => {
			if (floor) {
				openModal(DeskModal, {
					floor,
					desks: [desk],
				})
			}
		},
		[floor, openModal],
	)

	const selectDesk = useCallback(
		(desk: DeskResponse) => {
			if (selectedDesks.find((id) => id === desk.id) === undefined) {
				setSelectedDesks([...selectedDesks, desk.id])
			} else {
				setSelectedDesks(selectedDesks.filter((id) => id !== desk.id))
			}
		},
		[selectedDesks, setSelectedDesks],
	)

	const handleMouseOver = (id: string) => setHoveredDesk(id)

	const handleMouseOut = () => setHoveredDesk("")

	const handleEditDesk = () => {
		if (floor) {
			openModal(DeskModal, {
				floor,
				desks: desks.filter((d: DeskResponse) => selectedDesks.includes(d.id)),
			})
		}
	}

	const handleDeselect = useCallback(() => {
		setSelectedDesks([])
		actions.repositionDesk(null)
	}, [actions])

	const handleSelectAll = useCallback(() => {
		if (floor) {
			setSelectedDesks(desks.map((d: DeskResponse) => d.id))
		}
	}, [desks, floor])

	/* 	const handlePrintQrCode = () => {
	} */

	useOnClickOutside([mapBoxRef], (e) => {
		if (!(e.target as HTMLElement).closest(`.Button.link`)) {
			if (selectedDesks.length) {
				handleDeselect()
			}
		}
	})

	const handleToggleReposition = () =>
		setRepositionInitialized((prevValue) => !prevValue)

	const handleStartReposition = useCallback(() => {
		const draggableDesk = desks.find((d: DeskResponse) => d.id === dragDesk)

		if (draggableDesk && draggableDesk.id) {
			setStartDragReposition(true)

			infoToast(
				t("desktop.settings.desks.desk_form.desk_reposition_toast_drag", {
					deskName: draggableDesk.name,
				}),
			)

			actions.repositionDesk({
				...draggableDesk,
				departments: draggableDesk.departments?.map(
					(d: DepartmentResponse) => d.id,
				),
				users: draggableDesk.users?.map((u: UserResponse) => u.email),
				amenities: draggableDesk.amenities?.map((a: AmenityResponse) => a.id),
			})
		}
	}, [desks, dragDesk, infoToast, t, actions])

	const handleRepositionDesk = useCallback(
		async (desk: DeskRequestWithId) => {
			const response = await updateDesk(desk)

			if (!isRejected(response)) {
				handleDeselect()
				toast.info(
					t("desktop.settings.desks.desk_form.repositioned_desk_toast"),
					{ hideProgressBar: true },
				)
			} else {
				toast.error(response.error.message, { hideProgressBar: true })
			}
		},
		[updateDesk, handleDeselect, t],
	)

	const handleMapClick = useCallback(
		({ nativeEvent }: ReactMouseEvent<HTMLImageElement>) => {
			if (floor) {
				const { x: imgX, y: imgY } = getMouseXY(
					nativeEvent,
					imageRef.current!,
					floor,
				)

				if (!reposition_desk) {
					// Creating a new desk
					newDesk({
						coord_x: Math.floor(imgX),
						coord_y: Math.floor(imgY) - Y_OFFSET_DESK,
					} as DeskResponse)
				} else {
					// or repositioning an existing desk by clicking
					handleRepositionDesk({
						...reposition_desk,
						coord_x: Math.floor(imgX),
						coord_y: Math.floor(imgY) - Y_OFFSET_DESK,
					})
				}
			}
		},
		[floor, reposition_desk, newDesk, handleRepositionDesk],
	)

	const handleDownDragPlace = useCallback(
		(id?: string) => id && setDragDesk(id),
		[],
	)

	const handleStopDragPlace = useCallback(
		(event: DraggableEvent) => {
			if (floor) {
				const coords = getCoordsFormEvent(event)
				// Repositioning an existing desk by dragging
				const { x: imgX, y: imgY } = getMouseXY(
					coords,
					imageRef.current!,
					floor,
				)

				const { width: sourceWidth = 0, height: sourceHeight = 0 } = floor

				const isDeskWithinMapBounds =
					Math.floor(imgX) > sourceWidth ||
					Math.floor(imgX) <= 0 ||
					Math.floor(imgY) > sourceHeight ||
					Math.floor(imgY) <= 0

				if (reposition_desk) {
					if (isDeskWithinMapBounds) {
						toast.error(
							t(
								"desktop.settings.desks.desk_form.incorrect_desk_position_toast",
							),
							{
								hideProgressBar: true,
							},
						)
					} else {
						handleRepositionDesk({
							...reposition_desk,
							coord_x: Math.floor(imgX),
							coord_y: Math.floor(imgY) - Y_OFFSET_DESK,
						}).then(() => {
							setStartDragReposition(false)
							setRepositionInitialized(false)
						})
					}
				}
			}
		},
		[t, floor, reposition_desk, handleRepositionDesk],
	)

	useEffect(() => {
		if (isRepositionInitialized) {
			handleStartReposition()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRepositionInitialized])

	return (
		<View className="DeskLayout SettingsPage">
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.settings.desks.title"),
					t("desktop.settings.layout.title"),
				]}
			/>

			<Intro isConstrained>{t("desktop.settings.layout.subtitle")}</Intro>

			<Space size={0.75} />

			<Filters>
				<BuildingFilter value={buildingFilter} onChange={setBuildingFilter} />
				<FloorFilter
					value={floorFilter}
					onChange={setFloorFilter}
					buildingId={buildingFilter}
				/>
				<DepartmentFilter
					value={departmentFilter}
					onChange={setDepartmentFilter}
					showAll={true}
					showNotAssigned={true}
				/>
				{isLoading && <Loader size="small" />}
			</Filters>

			<Space size={0.75} />

			{floor && floor.image && (
				<div className="desks">
					<Card className="floor-plan">
						<Map
							map={floor}
							isDisabled={isLoading || !canEditDesks || isStartDragReposition}
							onClick={handleMapClick}
							onUpdateScale={handleUpdateScale}
							ref={imageRef}
							mapBoxRef={mapBoxRef}
							showPanControls
							showZoomControls
						>
							{!isLoading &&
								desks.map((desk: DeskResponse, i: number) => {
									let isMarket = true

									if (departmentFilter !== FilterSpecialValues.ALL) {
										if (departmentFilter === FilterSpecialValues.NOT_ASSIGNED) {
											isMarket =
												desk.departments === undefined ||
												desk.departments.length === 0
										} else {
											isMarket =
												desk.departments?.find(
													(d) => d.id === departmentFilter,
												) !== undefined
										}
									}

									return (
										<Place
											key={`seat-${i}`}
											id={desk.id}
											title={desk.name}
											x={desk.coord_x}
											y={desk.coord_y}
											mapWidth={floor.width ?? 1}
											mapHeight={floor.height ?? 1}
											onClick={() => selectDesk(desk)}
											onMouseOver={() => handleMouseOver(desk.id)}
											onMouseOut={handleMouseOut}
											isBlinking={desk.id === editingDeskId}
											isInactive={!desk.active}
											isUnmarked={!isMarket}
											isHovered={desk.id === hoveredDesk}
											isSelected={
												selectedDesks.find((id) => id === desk.id) !== undefined
											}
											scale={dragDeskScale}
											onDownDragPlace={handleDownDragPlace}
											onStopDragPlace={handleStopDragPlace}
											onToggleReposition={handleToggleReposition}
										/>
									)
								})}
						</Map>
					</Card>
					<div className="options">
						<div className="selected-desks">
							<ChairSvg />
							{t("desktop.settings.desks.desk_form.selectedDeskCount", {
								count: selectedDesks.length,
							})}
						</div>
						<Button
							onClick={handleSelectAll}
							icon={<SelectSVG />}
							variant="link"
							isDisabled={selectedDesks.length === desks.length}
						>
							{t("desktop.settings.desks.desk_form.select_all_desks")}
						</Button>

						<Button
							onClick={handleDeselect}
							icon={<DeselectSVG />}
							variant="link"
							isDisabled={selectedDesks.length === 0}
						>
							{selectedDesks.length === desks.length
								? t("desktop.settings.desks.desk_form.deselect_all_desks")
								: t("desktop.settings.desks.desk_form.deselect_desk")}
						</Button>

						<Divider color="gray-3" />

						<Button
							onClick={handleEditDesk}
							icon={<PencilSVG />}
							variant="link"
							isDisabled={selectedDesks.length === 0}
						>
							{selectedDesks.length > 1
								? t("desktop.settings.desks.desk_form.edit_desks")
								: t("desktop.settings.desks.desk_form.edit_desk")}
						</Button>

						{/* 						<Button
							onClick={handlePrintQrCode}
							icon={<QrCodeSvg />}
							variant="link"
							isDisabled={true}
						>
							{selectedDesks.length > 1
								? t("desktop.settings.desks.desk_form.print_qr_codes")
								: t("desktop.settings.desks.desk_form.print_qr_code")}
						</Button> */}
					</div>
				</div>
			)}
			{(!floor || !floor.image) && (
				<div className="desks">
					<Card className="floor-plan missing">
						<p>
							This floor doesn't have a floor plan.
							<br />
							Add one under{" "}
							<Link
								to={{
									pathname: "/settings/floor-plans",
									search: queryBuilder({
										building: buildingFilter,
										floor: floorFilter,
									}),
								}}
							>
								floor plan settings
							</Link>
						</p>
					</Card>
					<div className="options hidden"></div>
				</div>
			)}
		</View>
	)
}
export default DeskLayout
