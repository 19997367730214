import { Subscription } from "./subscriptions/types"
import { SubscriptionUnitType } from "./types"

const ACTIVE_LICENSE_STATUSES = ["active", "in_trial", "non_renewing"]

export const getLicenses = (
	subscriptions: Subscription[],
	type: SubscriptionUnitType,
) =>
	subscriptions
		.filter(
			(s) =>
				ACTIVE_LICENSE_STATUSES.includes(s.status) && s.unit[0].type === type,
		)
		.map((s) => ({
			quantity: s.unit[0].quantity ?? 0,
			quantityUsed: s.unit[0].quantity_used ?? 0,
			renewalDate: s.next_billing_date,
			status: s.status,
		}))
