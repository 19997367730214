import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import LogoVariant from "../../LogoVariant"

import {
	fetchEmailSuccess,
	postLogin,
	requestLogin,
} from "../../../redux/auth/authSlice"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import Button from "../../../components/basic/Button"
import { Input } from "../../../components/basic/Input"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as ArrowBackSVG } from "../../../assets/images/icons/Arrow back.svg"

import "./Login.sass"

const ACCESS_CODE_DURATION_MIN = 10

const Login: React.FC = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const inputRef = useRef<HTMLInputElement>(null)

	const params = useMemo(
		() => new URLSearchParams(history.location.search),
		[history.location.search],
	)
	const code = params.get("code")
	const email = params.get("email")
	const next = params.get("next")

	const [loginCode, setLoginCode] = useState(code || "")
	const [loginCodeError, setLoginCodeError] = useState(false)
	const [resendCodeError, setResendCodeError] = useState(false)
	const [loading, setLoading] = useState(false)

	const { login, fetchEmail, reqLogin } = useActions({
		login: (password: string) => postLogin(password),
		fetchEmail: (email: string) => fetchEmailSuccess(email),
		reqLogin: (email: string) => requestLogin(email),
	})

	const storedEmail = useAppSelector((state) => state.auth.email) ?? ""

	const [counter, setCounter] = useState(ACCESS_CODE_DURATION_MIN)
	const timeout: any = useRef(null)

	const handleStart = useCallback(async () => {
		inputRef.current?.blur()

		if (loginCode !== "") {
			setLoading(true)

			const response = await login(loginCode)

			setLoading(false)

			if (postLogin.rejected.match(response)) {
				setLoginCodeError(true)
			} else {
				setLoginCodeError(false)

				if (next) {
					history.push(next)
				} else {
					history.push("/")
				}
			}
		} else {
			setLoginCodeError(true)
		}
	}, [login, history, loginCode, next])

	const handleResend = useCallback(async () => {
		const response = await reqLogin(storedEmail)
		if (requestLogin.rejected.match(response)) {
			setResendCodeError(true)
			setLoginCodeError(false)
			return
		}
		setCounter(ACCESS_CODE_DURATION_MIN)
		setLoginCode("")
		setLoginCodeError(false)
		setResendCodeError(false)
		toast.info(t("mobile.auth.resend_code_success"))
	}, [reqLogin, t, storedEmail])

	useEffect(() => {
		timeout.current = setInterval(() => {
			setCounter((counter) => counter - 1)
		}, 60000)

		return () => {
			clearInterval(timeout.current)
		}
	}, [])

	useEffect(() => {
		if (counter === 0) {
			clearInterval(timeout.current)
		}
	}, [counter])

	useEffect(() => {
		if (email !== null) {
			fetchEmail(email)
		}
	}, [fetchEmail, email])

	useEffect(() => {
		if (email !== null && loginCode !== "") {
			handleStart()
		}
	}, [handleStart, email, loginCode])

	return (
		<SafeViewArea className="Login">
			<div className="body">
				<div
					className="back-button"
					onClick={() => {
						history.push("/login")
					}}
				>
					<ArrowBackSVG />
				</div>

				<LogoVariant variant="mobile" />

				{!loading ? (
					<div>
						<h2>{t("mobile.auth.done")}</h2>
						<h2>{t("mobile.auth.check_mail")}</h2>
						<p>{t("mobile.auth.done_info")}</p>
						<p>{t("mobile.auth.enter_code")}</p>
						<Input
							variant="mobile"
							ref={inputRef}
							value={loginCode}
							onChange={(value: string) => {
								setLoginCode(value.toUpperCase())
							}}
							autoCapitalize="characters"
							placeholder={t("mobile.auth.logincode")}
							disabled={loading}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleStart()
								}
							}}
						/>
						{loginCodeError ? (
							<div className="input-error">
								{t("mobile.auth.invalid_login_code")}
							</div>
						) : resendCodeError ? (
							<div className="input-error">
								{t("mobile.auth.resend_code_failed")}
							</div>
						) : counter > 0 ? (
							<div className="input-info">
								{t("mobile.auth.access_code_validity", {
									validFor: `${counter}`,
								})}
							</div>
						) : (
							<div className="input-error">
								{t("mobile.auth.access_code_expired")}
							</div>
						)}
					</div>
				) : (
					<div>
						<Loader />
					</div>
				)}
			</div>
			<div className="action">
				<Button
					type="submit"
					variant="mobile-action"
					isDisabled={loading || counter === 0 || loginCode === ""}
					onClick={handleStart}
				>
					{t("mobile.auth.log_in")}
				</Button>
				<Button
					variant="secondary-white"
					className="resend-code-button"
					isDisabled={loading}
					onClick={handleResend}
				>
					{t("mobile.auth.resend_code")}
				</Button>
			</div>
		</SafeViewArea>
	)
}

export default Login
