import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

import Loader from "../basic/Loader"
import SafeViewArea from "./SafeViewArea"
import { TopNav } from "./TopNav"

import { fetchDepartments } from "../../redux/departments/departmentsSlice"
import { selectDepartments } from "../../redux/departments/selectors"
import { DepartmentResponse } from "../../redux/departments/types"
import { useAppSelector } from "../../redux/reducers"
import { useActions } from "../../redux/utils"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as PeopleSVG } from "../../assets/images/icons/People.svg"

import "./DepartmentPicker.sass"

type DepartmentPickerProps = {
	asPage?: boolean
	showAll?: boolean
	onPick: (department: DepartmentResponse | null) => void
}

export const DepartmentPicker: React.FC<DepartmentPickerProps> = ({
	asPage = false,
	showAll = false,
	onPick,
}) => {
	const { t } = useTranslation()

	const actions = useActions({
		fetchDepartments: () => fetchDepartments({}),
	})

	const {
		entries: departments,
		isLoading: areDepartmentsLoading,
		isLoaded: areDepartmentsLoaded,
	} = useAppSelector(selectDepartments)

	useEffect(() => {
		if (!areDepartmentsLoaded && !areDepartmentsLoading) {
			actions.fetchDepartments()
		}
	}, [actions, areDepartmentsLoaded, areDepartmentsLoading])

	return (
		<SafeViewArea className="DepartmentPicker">
			<TopNav
				backArrow={asPage}
				title={!asPage ? t("mobile.general.choose_department") : ""}
				rightIcon={
					<CrossSVG
						onClick={() => {
							onPick(null)
						}}
					/>
				}
			/>
			<div className="picker-content">
				{asPage && <h2>{t("mobile.general.choose_department")}</h2>}
				{areDepartmentsLoaded && (
					<div>
						{showAll && (
							<div
								className="department-item"
								onClick={() => {
									onPick(null)
								}}
							>
								<div className="department-info">
									<div className="name">
										{t("mobile.general.all_departments")}
									</div>
								</div>
							</div>
						)}
						{departments.map((department: DepartmentResponse) => {
							return (
								<div
									className="department-item"
									key={department.id}
									onClick={() => {
										onPick(department)
									}}
								>
									<div className="department-info">
										<div className="name">{department.name}</div>
									</div>

									<span className="users-count">
										{department.users?.length} <PeopleSVG />
									</span>
								</div>
							)
						})}
					</div>
				)}

				{areDepartmentsLoading && !areDepartmentsLoaded && <Loader />}
			</div>
		</SafeViewArea>
	)
}
