import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { Link, useHistory, useRouteMatch } from "react-router-dom"

import { queryBuilder } from "../../../api/queryBuilder"
import { timeZone } from "../../../dayjs"
import { useCalculateBookable } from "../../../hooks/useCalculateBookable"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import DeskList from "./DeskList"
import FloorPlanFilters, { Filter } from "./Filters"
import {
	FLOOR_PLAN_SCHEDULE_PATHNAME,
	useFloorPlanContext,
} from "./FloorPlanProvider"
import Header from "./Header"
import { DeskWithReservations } from "./types"
import { useModals } from "@mattjennings/react-modal-stack"

import { useLazyFetchDesksQuery } from "../../../redux/api/desks"
import { useFetchFloorQuery } from "../../../redux/api/floors"
import { selectAppDates } from "../../../redux/app/selectors"
import { fetchDesksSchedule } from "../../../redux/desk_schedule/deskScheduleSlice"
import { selectDeskSchedule } from "../../../redux/desk_schedule/selectors"
import { useAppSelector } from "../../../redux/reducers"
import {
	selectUser,
	selectUserPermissions,
} from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/Card"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import Map from "../../../components/Map"
import OccupancyInfo from "../../../components/OccupancyInfo"
// import { OccupancyWarning } from "../../../components/OccupancyWarning"
import Place from "../../../components/Place"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

export const ENTRIES_PER_PAGE = 1000
export const FILTER_LOCAL_STORE_NAME = "manage-floor-plan_filters"

type Params = {
	id?: string
}

type Props = {
	add?: boolean
}

/**
 * Component: ManageFloorPlan
 */
const ManageFloorPlan = ({ add = false }: Props) => {
	const { currentDate } = useAppSelector(selectAppDates)
	const currentDateRef = useRef(currentDate.toISOString())
	const { schedule, isLoading: isDeskScheduleLoading } =
		useAppSelector(selectDeskSchedule)
	const permissions = useAppSelector(selectUserPermissions)
	const { entry: currentUser } = useAppSelector(selectUser)
	const {
		entry: { building },
	} = useAppSelector(selectUser)
	const [
		fetchDesks,
		{
			data: { results: desks = [] } = {},
			isFetching: isDeskLoading = false,
		} = {},
	] = useLazyFetchDesksQuery()

	const desk_reservation_window_length = useCheckReservationWindowLength({
		globalSettings: true,
	})
	const today = dayjs().format("YYYY-MM-DD")
	const lastDay = dayjs(today)
		.add(useCheckReservationWindowLength() ?? 7, "day")
		.endOf("day")

	const isAdminManager =
		isOfficeManager(currentUser) || isPortalAdmin(currentUser)
	const canBookDesks = isAdminManager || currentDate < lastDay

	const isLoading = isDeskLoading && isDeskScheduleLoading

	const defaultFilter: Filter = {
		department_id: FilterSpecialValues.ALL,
		building: building ? building.id : FilterSpecialValues.ALL,
		floor: FilterSpecialValues.ALL,
		amenity_id: [FilterSpecialValues.ALL],
	}

	const [storedFilterValues, saveFilter] = useStoredFilter({
		filterName: FILTER_LOCAL_STORE_NAME,
		defaultFilterValues: defaultFilter,
	})

	if (typeof storedFilterValues.amenity_id === "string") {
		storedFilterValues.amenity_id = [storedFilterValues.amenity_id]
	}

	const reqParams = useRef<Filter>(storedFilterValues)
	const [isPanningDisabled, setPanningDisabled] = useState(false)
	const [filters, setFilters] = useState<Filter>(storedFilterValues)
	const [users, setUsers] = useState<string[]>([])

	const { data: floor } = useFetchFloorQuery({ id: filters.floor })

	const canAddFloorPlans = permissions.includes("desk.add_floorplan")
	const hasCapacityLimit = floor?.capacity_limit || floor?.capacity_limit === 0
	const mainFloorPlanContentClasses = classNames("main-floorplan-content", {
		"capacity-limit": hasCapacityLimit,
	})

	const {
		clearHoveredDesk,
		setHoveredDesk,
		hoveredDesk,
		setOpenedDesk,
		createNewReservation,
		clearOpenedDesk,
		openedDesk,
	} = useFloorPlanContext()
	/**
	 * Hooks
	 */
	const {
		params: { id },
	} = useRouteMatch<Params>()
	const { openModal, closeAllModals } = useModals()
	const history = useHistory()
	const { search, pathname } = history.location

	const desksWithReservations: DeskWithReservations[] = useMemo(() => {
		return desks
			.map((d) => {
				const reservations =
					schedule.find((s) => s.id === d.id)?.schedule[0]?.reservations ?? []
				return { ...d, reservations }
			})
			.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
	}, [desks, schedule])

	const desksBookable = useCalculateBookable({
		date: currentDate.toISOString(),
		floorId: filters.floor,
		departmentId: filters.department_id,
	})
	/**
	 * This allows the user to search for different users and highlight their reservations.
	 */
	const desksWithReservationsHighlighted = useMemo(() => {
		return desksWithReservations.map((desk) => {
			if ((users?.length ?? 0) < 1)
				return { ...desk, isDisabled: !desksBookable[desk.id] }

			const wasMadeBySearchedUser = desk.reservations.find((reservation) => {
				return users.find((user) => user === reservation.user.email)
			})

			if (wasMadeBySearchedUser) {
				return {
					...desk,
					highlight: true,
					isDisabled: !desksBookable[desk.id],
				}
			}

			return { ...desk, isDisabled: !desksBookable[desk.id] }
		})
	}, [desksWithReservations, users, desksBookable])

	const actions = useActions({
		fetchDesksSchedule: ({ building, floor, ...filter }: Filter, date: Dayjs) =>
			fetchDesksSchedule({
				...filter,
				building_id: building,
				floor_id: floor,
				start: date.startOf("day").toISOString(),
				end: date.endOf("day").toISOString(),
				limit: ENTRIES_PER_PAGE,
			}),
	})

	/**
	 * Handlers
	 */
	const handleFilterChange = useCallback(
		async (filter: Filter) => {
			reqParams.current = filter
			updateHistory(pathname, filter)

			fetchDesks({ ...filter, limit: ENTRIES_PER_PAGE })
			actions.fetchDesksSchedule(filter, currentDate)
			setFilters(filter)
		},
		[actions, currentDate, fetchDesks, pathname],
	)

	const handleMouseOver = (id: string) => {
		setHoveredDesk(id)
		setPanningDisabled(true)
	}

	const handleMouseOut = () => {
		clearHoveredDesk()
		setPanningDisabled(false)
	}

	const handleDeskClick = (desk: DeskWithReservations) => {
		setOpenedDesk(desk.id, true)

		if (!desk.isDisabled && !desk.reservations.length && canBookDesks) {
			createNewReservation(desk)
		}
	}

	const handleModalClose = () => {
		history.push(FLOOR_PLAN_SCHEDULE_PATHNAME)
	}

	/**
	 * useEffects
	 */

	/**
	 * Main routing logic for create and edit modal views.
	 **/
	useEffect(() => {
		if (add) {
			const queryFormData = parseQueryWithDefault(search, {
				start: "",
				end: "",
				date: currentDate.toString(),
				schedule: "Once",
				desk: "",
				deskName: "",
				scheduleDate: dayjs()
					.add(desk_reservation_window_length ?? 7, "day")
					.toString(),
				tz: timeZone,
			})

			openModal(DeskBookingModal, {
				formData: {
					...queryFormData,
					date: dayjs(queryFormData.date),
					schedule: createScheduleOption(
						queryFormData.schedule,
						queryFormData.date,
					),
					scheduleDate: dayjs(queryFormData.scheduleDate),
				},
				onClose: handleModalClose,
			})
		} else if (id) {
			openModal(DeskBookingModal, {
				reservationId: id,
				onClose: handleModalClose,
			})
		} else {
			updateHistory(pathname, reqParams.current)
			fetchDesks({ ...reqParams.current, limit: ENTRIES_PER_PAGE })
			actions.fetchDesksSchedule(reqParams.current, currentDate)
			closeAllModals()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [add, id, search])
	// saves the current params into the locale store
	useEffect(
		() => () => {
			if (pathname.endsWith(FLOOR_PLAN_SCHEDULE_PATHNAME)) {
				saveFilter(reqParams.current)
			}
		},
		[saveFilter, pathname],
	)

	useEffect(() => {
		clearOpenedDesk()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, currentDate])

	useEffect(() => {
		const date = currentDate.toISOString()

		if (currentDateRef.current !== date) {
			actions.fetchDesksSchedule(reqParams.current, currentDate)
			currentDateRef.current = date
		}
	}, [actions, currentDate])

	return (
		<View className="ManageFloorPlan">
			<Header />
			<FloorPlanFilters
				onChange={handleFilterChange}
				onUsersChange={setUsers}
				defaultValues={storedFilterValues}
			/>
			{floor && hasCapacityLimit && (
				<>
					<Space size={0.25} />
					<OccupancyInfo type="Floor" floor={floor} />
				</>
			)}
			<Space size={0.5} />
			{floor?.image ? (
				<div className={mainFloorPlanContentClasses}>
					<Card className="floor-plan">
						<Map
							map={floor}
							showZoomControls
							showPanControls
							isDisabled={isPanningDisabled}
							key={`building-${filters.building}-floor-{${filters.floor}}`}
						>
							{!isLoading &&
								desksWithReservationsHighlighted.map((desk, i: number) => {
									return (
										<Place
											key={`seat-${i}`}
											x={desk.coord_x}
											y={desk.coord_y}
											mapWidth={floor.width!}
											mapHeight={floor.height!}
											onClick={() => {
												handleDeskClick(desk)
											}}
											onMouseOver={() => handleMouseOver(desk.id)}
											onMouseOut={handleMouseOut}
											title={desk.name}
											amenities={desk.amenities}
											departments={desk.departments}
											reservations={desk.reservations}
											isAvailable={(desk.reservations?.length ?? 0) === 0}
											isOccupied={
												!desk.isDisabled && (desk.reservations?.length ?? 0) > 0
											}
											isDisabled={desk.isDisabled}
											isHovered={desk.id === hoveredDesk}
											isFocused={desk.id === openedDesk}
											isHighlighted={desk.highlight}
										/>
									)
								})}
						</Map>
					</Card>
					<Card className="user-list">
						{!isLoading && desksWithReservationsHighlighted.length > 0 && (
							<DeskList
								canBookDesks={canBookDesks}
								desksWithReservations={desksWithReservationsHighlighted}
							/>
						)}
						{/* we will reimplement this when we have a BE ready
						{floor && (
							<OccupancyWarning
								id={filters.floor}
								company={company}
								day={currentDate}
							/>
						)} */}
					</Card>
				</div>
			) : (
				<Card className="missing">
					<p className="error">
						This floor plan is not configured.
						<br />
						{canAddFloorPlans ? (
							<>
								Upload floor plan images under{" "}
								<Link
									to={{
										pathname: "/settings/floor-plans",
										search: queryBuilder({
											building: filters.building,
											floor: filters.floor,
										}),
									}}
								>
									Settings / Floor plans
								</Link>
								.
							</>
						) : (
							<>
								Contact your Office Manager or IT admin to configure desks and
								floor plans.
							</>
						)}
					</p>
				</Card>
			)}
		</View>
	)
}

export default ManageFloorPlan
