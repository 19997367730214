import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { IS_WHITE_LABEL } from "../../../constants"
import { useCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import Button from "../../basic/Button"
import Card from "../../basic/Card"
import {
	getDemoUrl,
	getPlanDescription,
	getSubscriptionIcon,
	isSubscription,
	toFriendlyName,
	toFriendlyTitle,
} from "./utils"

import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanType } from "../../../redux/api/billing/types"

import { ReactComponent as PlaySVG } from "../../../assets/images/icons/Play.svg"

import "./styles.sass"

export type SubscriptionCardProps = (
	| {
			subscription: Subscription
			isActive: true
	  }
	| {
			subscription: { plan_type: PlanType }
			isActive: false
	  }
) & {
	paymentMethod?: string
	hasTrialAvailable: boolean
	onButtonClick?: (params: {
		id?: number
		planType: PlanType
		hasTrialAvailable: boolean
	}) => void
	onLicenseClick?: (subscription: Subscription) => void
}

/**
 * SubscriptionCard
 */
const SubscriptionCard = ({
	subscription,
	isActive,
	hasTrialAvailable,
	onButtonClick,
	paymentMethod,
	onLicenseClick,
}: SubscriptionCardProps) => {
	const { plan_type } = subscription

	const { t } = useTranslation()

	const handleOnClick = () => {
		onButtonClick?.({
			planType: plan_type,
			hasTrialAvailable,
			...(isSubscription(subscription) && { id: subscription.subscription_id }),
		})
	}
	const getButtonText = () => {
		if (isActive) {
			return t("desktop.components.subscription_card.actions.modify_plan")
		}
		if (hasTrialAvailable) {
			return t("desktop.components.subscription_card.actions.start_trial")
		}
		return t(
			"desktop.components.subscription_card.actions.select_subscription_plan",
		)
	}

	const getActions = () => (
		<Button
			variant={isActive ? "secondary" : "primary"}
			onClick={handleOnClick}
		>
			{getButtonText()}
		</Button>
	)

	return (
		<Card
			className="SubscriptionCard"
			header={<CardHeader planType={plan_type} isActive={isActive} />}
			actions={getActions()}
		>
			<PlanInfo
				planType={plan_type}
				isActive={isActive}
				isTrial={isActive ? subscription.status === "in_trial" : false}
			/>
			{isActive ? (
				<SubscriptionDetails
					subscription={subscription}
					paymentMethod={paymentMethod}
					onLicenseClick={onLicenseClick}
				/>
			) : (
				<SubscriptionGroupDescription planType={plan_type} />
			)}
		</Card>
	)
}

type Props = { planType: PlanType; isActive: boolean }

const CardHeader = ({ planType, isActive }: Props) => {
	const { t } = useTranslation()
	return (
		<div className="subscription-header">
			{toFriendlyTitle(planType)}
			{!isActive && !IS_WHITE_LABEL && (
				<a
					className="demo"
					href={getDemoUrl(planType)}
					target="_blank"
					rel="noopener noreferrer"
				>
					<PlaySVG /> {t("desktop.components.subscription_card.demo")}
				</a>
			)}
		</div>
	)
}

const PlanInfo = ({
	planType,
	isActive,
	isTrial,
}: Props & { isTrial: boolean }) => {
	const cn = classNames("plan-icon", { active: isActive })
	const { t } = useTranslation()

	return (
		<div className="plan-info">
			<div>
				<div className="plan-info-title">
					{isActive
						? toFriendlyName(planType)
						: t("desktop.components.subscription_card.inactive")}
				</div>
				<div className="plan-info-desc">
					{isActive
						? isTrial
							? t("desktop.components.subscription_card.free_trial")
							: t("desktop.components.subscription_card.subscription")
						: t("desktop.components.subscription_card.no_plan_selected")}
				</div>
			</div>
			{getSubscriptionIcon(planType)({ className: cn })}
		</div>
	)
}

const SubscriptionGroupDescription = ({ planType }: { planType: PlanType }) => (
	<div className="subscription-group-description">
		{getPlanDescription(planType)}
	</div>
)

const SubscriptionDetails = ({
	subscription,
	paymentMethod,
	onLicenseClick,
}: {
	subscription: Subscription
	paymentMethod?: string
	onLicenseClick?: (subscription: Subscription) => void
}) => {
	const { unit, amount, plan_variation, next_billing_date, trial_end, status } =
		subscription
	const { t } = useTranslation()
	const currency = useCurrencyFormatter(plan_variation.currency)

	return (
		<div className="subscription-details">
			<div className="licenses">
				<Button variant="link" onClick={() => onLicenseClick?.(subscription)}>
					{t("desktop.components.subscription_card.licenses", {
						count: unit[0].quantity,
						unit: unit[0].type,
					})}
				</Button>
			</div>
			<div className="active">
				{t("desktop.components.subscription_card.active_units", {
					count: unit[0].quantity_used,
					unit: unit[0].type,
				})}
			</div>
			<div className="small-details">
				Cost:{" "}
				<strong>
					{currency.format(amount ?? 0)} / {plan_variation.period_unit}
				</strong>
			</div>
			{paymentMethod && (
				<div className="small-details">
					{t("desktop.components.subscription_card.payment_method")}:{" "}
					<strong>{paymentMethod}</strong>
				</div>
			)}
			{status === "in_trial" ? (
				<div className="small-details">
					{t("desktop.components.subscription_card.trial_ends")}:{" "}
					<strong>{dayjs(trial_end).format("LL")}</strong>
				</div>
			) : (
				status !== "non_renewing" && (
					<div className="small-details">
						{t("desktop.components.subscription_card.next_billing_date")}:{" "}
						<strong>{dayjs(next_billing_date).format("LL")}</strong>
					</div>
				)
			)}
		</div>
	)
}

export default SubscriptionCard
