import React, { useEffect, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useRefetch } from "../../hooks/mobile/useRefetch"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useAnalyticsScreenView } from "../../providers/Mobile/FirebaseAnalyticsProvider"
import { toInternalTime } from "../../utils"

import { useFetchDeskReservationsQuery } from "../../redux/api/deskReservations"
import { DeskReservation } from "../../redux/api/deskReservations/types"
import { fetchDepartments } from "../../redux/departments/departmentsSlice"
import { selectDepartments } from "../../redux/departments/selectors"
import { useAppSelector } from "../../redux/reducers"
import { selectDefaultUserBuilding } from "../../redux/user/selectors"
import { formatUser } from "../../redux/user/utils"
import { useActions } from "../../redux/utils"

import { FilterSpecialValues } from "../../components/Filter/types"
import { BottomNav } from "../../components/Mobile/BottomNav"
import SafeViewArea from "../../components/Mobile/SafeViewArea"
import { SearchBox } from "../../components/Mobile/SearchBox"
import { TopSelectors } from "../../components/Mobile/TopSelectors"
import Loader from "../../components/basic/Loader"

import { ReactComponent as PinSVG } from "../../assets/images/icons/Pin.svg"
import { ReactComponent as PresenceCrossSVG } from "../../assets/images/icons/Presence cross.svg"
import { ReactComponent as SearchSVG } from "../../assets/images/icons/Search.svg"

import "./Presence.sass"

type PresentProps = {
	present: DeskReservation
}

const Present: React.FC<PresentProps> = ({ present }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const cn = classNames("check-indicator", present.checked_in ? "in" : "out")

	const navigateToFloor = () => {
		history.push("/floor", {
			date: present.start,
			time: toInternalTime(present.start ?? ""),
			location_id: present.building.id,
			floor_id: present.floor.id,
			desk_id: present.desk.id,
		})
	}

	return (
		<div className="Present" onClick={navigateToFloor}>
			<div className="check">
				<div className={cn} />
			</div>
			<div className="person-info">
				<div className="name">{formatUser(present.user)}</div>
				<div className="time">
					<span>{t("mobile.presence.present_from")}</span>
					&nbsp;
					<span>
						<span className="start">{dayjs(present.start).format("LT")}</span>
						{dayjs(present.end).format("LT")}
					</span>
				</div>
			</div>
			<div className="location">
				<div className="location-icon">
					<PinSVG />
				</div>
			</div>
		</div>
	)
}

const Presence: React.FC = () => {
	useAnalyticsScreenView("Presence")

	const { t } = useTranslation()

	const actions = useActions({
		fetchDepartments: () => fetchDepartments({}),
	})

	// const { reservations, isLoaded, isLoading } = useAppSelector(selectPresence)
	const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

	const { entries: departments, isLoaded: areDepartmentsLoaded } =
		useAppSelector(selectDepartments)

	// Filters
	const [dayFilter, setDayFilter] = useState<string>(
		dayjs().startOf("day").toISOString(),
	)

	const endDate = dayjs(dayFilter).endOf("day").toISOString()

	const [searchText, setSearchText] = useState<string>(
		FilterSpecialValues.EMPTY,
	)

	const { value: buildingFilter, onChange: setBuildingFilter } =
		useLocalStorage(
			"mobile-presence-building_filter",
			defaultUserBuilding?.id ?? FilterSpecialValues.EMPTY,
		)

	const { value: floorFilter, onChange: setFloorFilter } = useLocalStorage(
		"mobile-presence-floor_filter",
		FilterSpecialValues.EMPTY,
	)

	const { value: departmentFilter, onChange: setDepartmentFilter } =
		useLocalStorage(
			"mobile-presence-department_filter",
			FilterSpecialValues.EMPTY,
		)

	const {
		data: { results: reservations = [] } = {},
		isFetching: isLoading,
		isSuccess,
		refetch: refetchDeskReservations,
	} = useFetchDeskReservationsQuery({
		start: dayFilter,
		end: endDate,
		...(buildingFilter !== FilterSpecialValues.ALL && {
			building_id: buildingFilter,
		}),
		...(floorFilter !== FilterSpecialValues.ALL && { floor_id: floorFilter }),
		...(departmentFilter !== FilterSpecialValues.ALL && {
			user_department_id: departmentFilter,
		}),
		limit: FETCH_WITH_NO_LIMIT,
	})

	useRefetch([refetchDeskReservations])

	const ids = new Set()

	const filteredReservations = reservations.filter((reservation) => {
		let selected = true

		if (ids.has(reservation.user.email)) {
			return false
		}

		if (searchText !== FilterSpecialValues.EMPTY) {
			const fullName = formatUser(reservation.user)

			selected =
				selected &&
				fullName.toLowerCase().includes(searchText.trim().toLowerCase())
		}

		ids.add(reservation.user.email)

		return selected
	})

	const anonymousCount =
		filteredReservations?.filter((r) => r.user.email === null).length ?? 0

	/**
	 * Effects:
	 */

	useEffect(() => {
		if (!areDepartmentsLoaded) {
			actions.fetchDepartments()
		}
	}, [actions, areDepartmentsLoaded])

	useEffect(() => {
		analyticsEvent(SupportedEvents.DESK_PRESENCE)
	}, [])

	return (
		<SafeViewArea className="Presence">
			<TopSelectors
				day={dayFilter}
				dayChange={setDayFilter}
				buildingFilter={buildingFilter}
				buildingFilterChange={setBuildingFilter}
				floorFilter={floorFilter}
				floorFilterChange={setFloorFilter}
				departmentFilter={departmentFilter}
				departmentFilterChange={setDepartmentFilter}
				showDepartments={departments.length > 0}
				showAll
			/>
			<SearchBox
				onChange={(text) => {
					setSearchText(text)
				}}
			/>
			<div className="body">
				{!isLoading && isSuccess && filteredReservations.length > 0 && (
					<>
						{searchText === "" && (
							<div className="search-info">
								<h3>
									<span className="present-number">
										{filteredReservations.length}
									</span>
									&nbsp;
									{t("mobile.presence.present")}
								</h3>
								{anonymousCount > 0 && (
									<div>
										<Trans
											i18nKey="mobile.presence.anonymous"
											count={anonymousCount}
										>
											From which {{ anonymousCount }} anonymous
										</Trans>
									</div>
								)}
							</div>
						)}
						{filteredReservations.length > 0 && (
							<div className="presents">
								<div className="scroller">
									{filteredReservations.map((present) => {
										return <Present present={present} key={present.id} />
									})}
								</div>
							</div>
						)}
						{filteredReservations.length === 0 && (
							<div className="no-presents">
								<div>
									<SearchSVG />
								</div>
								<div>{t("mobile.general.no_searches")}</div>
							</div>
						)}
					</>
				)}
				{!isLoading && isSuccess && filteredReservations.length === 0 && (
					<div className="no-presents">
						<div>
							<PresenceCrossSVG />
						</div>
						<div>{t("mobile.presence.no_presents")}</div>
					</div>
				)}
				{isLoading && (
					<div className="loading">
						<Loader variant="fullScreen" />
					</div>
				)}
			</div>
			<BottomNav />
		</SafeViewArea>
	)
}

export default Presence
