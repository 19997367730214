import React, { ReactNode } from "react"

import classNames from "classnames"
import { NavLink, useLocation } from "react-router-dom"

import { generateId } from "../hooks/useId"
import { Collapse } from "./basic/Collapse"

import { ReactComponent as Chevron } from "../assets/images/icons/Chevron.svg"

import "./NavMenu.sass"

type NavMenuProps = {
	icon: JSX.Element
	to: string
	text: string
	isDisabled?: boolean
	activePath?: string
	children?:
		| React.ReactElement<NavSubItemProps>
		| React.ReactElement<NavSubItemProps>[]
}
/*
	The NavMenu component is collapsible menu component the item will be in active state
	if to props is included it the location
	In future the implementation will change so that it will be active
	when location has one of the sub items paths in it

	Code example:
	<NavMenu
		icon={<ChairSVG />}
		text="link text"
		to="/settings/desks"
		>
			<NavSubItem to="/settings/general">Booking policies</NavSubItem>
			<NavSubItem to="/settings/time-slots">Time slots</NavSubItem>
	</NavMenu>
*/
export const NavMenu = ({
	icon,
	to,
	text,
	isDisabled = false,
	children,
	activePath,
}: NavMenuProps) => {
	const { pathname } = useLocation()

	const isActive = activePath ? pathname.startsWith(activePath) : false
	const navMenuClasses = classNames("NavMenu", {
		isDisabled: !!isDisabled,
		active: isActive,
	})

	const navLinkClasses = classNames("nav-link", {
		isDisabled: !!isDisabled,
		active: isActive,
	})

	const key = activePath ?? to ?? generateId()

	return (
		<div className={navMenuClasses}>
			<NavLink to={!isDisabled ? to : "#"} className={navLinkClasses}>
				{icon}
				<span>{text}</span>
				<Chevron className="chevron" />
			</NavLink>
			<Collapse itemKey={key} isExtended={isActive} className="sub-nav">
				{children}
			</Collapse>
		</div>
	)
}

type NavSubItemProps = {
	to: string
	isDisabled?: boolean
	activePath?: string
	children: ReactNode
}

export const NavSubItem = ({
	to,
	isDisabled = false,
	children,
	activePath,
}: NavSubItemProps) => {
	const { pathname } = useLocation()
	const subItemClasses = classNames("NavSubItem", {
		isDisabled: !!isDisabled,
		active: activePath ? pathname.startsWith(activePath) : false,
	})

	return (
		<NavLink to={!isDisabled ? to : "#"} className={subItemClasses}>
			{children}
		</NavLink>
	)
}
