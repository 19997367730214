import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import { useToast } from "../../../hooks/useToast"
import InviteCancelModal from "../../../modals/Visitors/InviteCancelModal"
import { InviteExportModal } from "../../../modals/Visitors/InviteExportModal"
import { generateString, updateHistory } from "../../../utils"
import {
	ENTRIES_PER_PAGE,
	INVITES_FILTERS_STORE_NAME,
	VISITORS_INVITES_PATHNAME,
} from "../constants"
import InvitesFilters, { Filter } from "./InvitesFilters"
import { useModals } from "@mattjennings/react-modal-stack"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import {
	checkinInvite,
	inviteReinvite,
	invitesReprintBadge,
} from "../../../redux/invite/inviteSlice"
import {
	fetchInvites,
	getInvitesTotal,
} from "../../../redux/invites/invitesSlice"
import { selectInvites } from "../../../redux/invites/selectors"
import {
	EntryStatusType,
	Host,
	InviteListResponse,
	FetchOptions as InvitesFetchOptions,
} from "../../../redux/invites/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { formatUser, isNormalUser } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { NewVisitsCreatedByOption } from "../../../redux/visitor_settings/types"
import { fetchVisitorSettings } from "../../../redux/visitor_settings/visitorSettingsSlice"
import { selectVisitors } from "../../../redux/visitors/selectors"
import { FetchOptions as VisitorsFetchOptions } from "../../../redux/visitors/types"
import { getVisitorsTotal } from "../../../redux/visitors/visitorsSlice"

import Datestring from "../../../components/Datestring"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Heading from "../../../components/Heading"
import { DayNav } from "../../../components/Manage/CalNav"
import WeekendToggle from "../../../components/Manage/WeekendToggle"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import { Tooltip } from "../../../components/Tooltip"
import View from "../../../components/View"
import Table from "../../../components/advanced/Table"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"
import MultiToggle from "../../../components/basic/MultiToggle"
import {
	Action,
	Column,
	DEFAULT_EMPTY_TABLE_CELL,
} from "../../../components/basic/Table"

import { ReactComponent as CheckSVG } from "../../../assets/images/icons/Check.svg"
import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

// import { ReactComponent as ChairSVG } from "../../../assets/Icon/Chair.svg"
// import { ReactComponent as ClockSVG } from "../../../assets/Icon/Clock.svg"
// import { ReactComponent as DocumentCheckSVG } from "../../../assets/Icon/DocumentCheck.svg"
// import { ReactComponent as PersonSVG } from "../../../assets/Icon/Person.svg"
// import { ReactComponent as SupervisorAccountSVG } from "../../../assets/Icon/SupervisorAccount.svg"
// import { ReactComponent as TasksSVG } from "../../../assets/Icon/Tasks.svg"
import "./Invites.sass"

const { stringify } = queryString

enum TabType {
	VISITS = "visits",
	INVITES = "invites",
}

type StatusCellProps = {
	confirmed: boolean
	status: string
	tooltipContent?: string
}

const StatusCell = ({
	confirmed,
	status,
	tooltipContent = undefined,
}: StatusCellProps) => {
	const { t } = useTranslation()

	return (
		<>
			{confirmed && tooltipContent ? (
				<Tooltip uniqueId={generateString(4)} content={`${tooltipContent}`}>
					<div className="StatusCell">
						<CheckSVG />
						{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
					</div>
				</Tooltip>
			) : confirmed && !tooltipContent ? (
				<div className="StatusCell">
					<CheckSVG />
					{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
				</div>
			) : (
				<div className="StatusCell">
					<CrossSVG />
					{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
				</div>
			)}
		</>
	)
}

type NameCellProps = {
	name: string
	host: Host
}

const NameCell = ({ name, host }: NameCellProps) => {
	const { t } = useTranslation()

	return (
		<div className="NameCell">
			<div className="invite">
				<div className="name">{name}</div>
				<div className="host">
					{t("desktop.manage.visitors.table.cell.host")}:{" "}
					<b>{formatUser(host)}</b>
				</div>
			</div>
		</div>
	)
}

/**
 * ManageInvites component
 */

const ManageInvites = () => {
	const history = useHistory()
	const { search, pathname } = history.location
	const { t } = useTranslation()
	const { openModal } = useModals()
	const { infoToast, errorToast } = useToast()

	const { currentDate, showWeekends } = useAppSelector(selectAppDates)
	const { entry: user } = useAppSelector(selectUser)
	const {
		entries: tableData,
		count: invitesCount,
		offset,
		isLoaded,
		total: invitesTotal,
	} = useAppSelector(selectInvites)
	const { total: totalCount } = useAppSelector(selectVisitors)
	const { entries: visitorSettings, isLoaded: settingsLoaded } = useAppSelector(
		selectVisitorSettings,
	)

	const start = currentDate.startOf("day").toISOString()
	const end = currentDate.endOf("day").toISOString()

	const defaultFilter: Filter = {
		building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
		show: "",
		search: "",
		page: 1,
	}

	const [storedFilterValues, saveFilter] = useStoredFilter({
		filterName: INVITES_FILTERS_STORE_NAME,
		defaultFilterValues: defaultFilter,
	})

	const reqParams = useRef<Filter>(storedFilterValues)

	const [building, setBuilding] = useState(storedFilterValues.building_id)

	const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
		useBackendPagination({
			offset,
			totalNumberOfItems: invitesCount,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 7,
			maxTrailingLinks: 2,
		})

	const actions = useActions({
		fetchInvites: (params: InvitesFetchOptions) =>
			fetchInvites({ ...params, limit: ENTRIES_PER_PAGE }),
		getVisitorsTotal: (params: VisitorsFetchOptions) =>
			getVisitorsTotal(params),
		checkinInvite: (id: string) => checkinInvite(id),
		inviteReinvite: (id: string) => inviteReinvite(id),
		inviteReprint: (id: string) => invitesReprintBadge(id),
		fetchVisitorSettings: () => fetchVisitorSettings(),
		toggleWeekends: () => toggleWeekends(),
		getInvitesTotal: (params: InvitesFetchOptions) => getInvitesTotal(params),
	})

	const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
		reqParams: reqParams,
		path: VISITORS_INVITES_PATHNAME,
		fetchCall: actions.fetchInvites,
		fetchOptions: { start, end },
		offset,
	})

	const editInvite = useCallback(
		(i: InviteListResponse) =>
			history.push(`/manage/visitors/invites/edit/${i.id}`),
		[history],
	)
	const getInvitesCount = useCallback(
		({ building_id }: Filter) => {
			actions.getInvitesTotal({ building_id, start, end })
		},
		[actions, start, end],
	)

	const tableColumns = useMemo<Column<InviteListResponse>[]>(() => {
		return [
			{
				field: "full_name",
				label: "",
				renderCell: (i) => {
					return <NameCell name={i.full_name} host={i.host} />
				},
			},
			{
				field: "entry_status",
				label: t("desktop.manage.visitors.table.entry_status"),
				renderCell: (i) => {
					if (i.entry_status) {
						return (
							<StatusCell
								confirmed={i.entry_status === EntryStatusType.APPROVED}
								status={i.entry_status.toLocaleLowerCase()}
							/>
						)
					}
					return DEFAULT_EMPTY_TABLE_CELL
				},
			},
			{
				field: "created_by",
				label: t("desktop.manage.visitors.table.invited_by"),
				renderCell: (i) => {
					return formatUser(i.created_by)
				},
			},
			{
				field: "document_signed",
				label: t("desktop.manage.visitors.table.documents"),
				renderCell: (i) => {
					if (i.document_signed !== undefined && i.document_signed !== null) {
						return (
							<StatusCell
								confirmed={i.document_signed || false}
								status={i.document_signed ? "signed" : "not_signed"}
								tooltipContent={
									i.document_name ?? t("mobile.visitor.document_not_found")
								}
							/>
						)
					}
					return DEFAULT_EMPTY_TABLE_CELL
				},
			},
			{
				field: "start",
				label: t("desktop.manage.visitors.table.due_at"),
				renderCell: (i) => dayjs(i.start).format("LT"),
			},
			{
				field: "desk",
				label: t("desktop.manage.visitors.table.assigned_desk"),
				renderCell: (i) => {
					return i.desk?.name || DEFAULT_EMPTY_TABLE_CELL
				},
			},
		]
	}, [t])

	const rowActions = useMemo<Action<InviteListResponse>[]>(() => {
		return [
			{
				label: t("desktop.manage.visitors.table.cell.action.edit_invite"),
				onClick: editInvite,
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.preprint_badge"),
				onClick: async (i) => {
					const response = await actions.inviteReprint(i.id)

					if (invitesReprintBadge.fulfilled.match(response)) {
						infoToast(t("desktop.manage.visitors.preprint_success"))
					} else {
						errorToast(response?.error?.message)
					}
				},
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.invite_again"),
				onClick: async (i) => {
					const response = await actions.inviteReinvite(i.id)

					if (inviteReinvite.fulfilled.match(response)) {
						infoToast(t("desktop.manage.visitors.form.email_sent_toast"))
					} else {
						errorToast(response.error.message)
					}
				},
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.check_in"),
				onClick: async (i) => {
					const response = await actions.checkinInvite(i.id)

					if (checkinInvite.fulfilled.match(response)) {
						infoToast(t("desktop.manage.visitors.invite_checked_in"))
						actions.fetchInvites({
							building_id: reqParams.current.building_id,
							show: reqParams.current.show,
							start,
							end,
							search: reqParams.current.search,
						})
						actions.getVisitorsTotal({
							building_id: reqParams.current.building_id,
							start,
							end,
						})
					} else {
						errorToast(response.error.message)
					}
				},
			},

			{
				label: t("desktop.manage.visitors.table.cell.action.cancel_invite"),
				onClick: (invite) =>
					openModal(InviteCancelModal, {
						invite,
						fetchParams: {
							building_id: reqParams.current.building_id,
							show: reqParams.current.show,
							start,
							end,
							search: reqParams.current.search,
						},
					}),
			},
		]
	}, [t, editInvite, actions, infoToast, errorToast, start, end, openModal])

	// const tableFooterCells = [
	// 	<div className="FooterCell">
	// 		<PersonSVG />
	// 		<b>1</b> <span>{t("desktop.manage.visitors.table.summary.invites")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<TasksSVG />
	// 		<b>1/2</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.approved")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		{" "}
	// 		<DocumentCheckSVG />
	// 		<b>1/2</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.signed")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<ClockSVG />
	// 		<b>1</b> <span>{t("desktop.manage.visitors.table.summary.overdue")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<SupervisorAccountSVG />
	// 		<b>1</b> <span>{t("desktop.manage.visitors.table.summary.hosts")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<ChairSVG />
	// 		<b>1</b> <span>{t("desktop.manage.visitors.table.summary.desks")}</span>
	// 	</div>,
	// ]

	const onTabChangeHandler = (tab: TabType) => {
		history.push({
			pathname: `/manage/visitors/${tab}`,
			search: stringify({ building_id: reqParams.current.building_id }),
		})
	}

	const handleNewClick = () => {
		history.push("/manage/visitors/invites/add")
	}

	const handleExportClick = () => {
		openModal(InviteExportModal, {
			range: { from: currentDate, to: currentDate },
			buildingId: reqParams.current.building_id,
			show: reqParams.current.show,
			search: reqParams.current.search,
		})
	}

	const handleFilterChange = useCallback(
		async (filter: Filter) => {
			if (
				reqParams.current.building_id !== filter.building_id &&
				(filter.search !== "" || filter.show !== "")
			) {
				getInvitesCount(filter)
			}
			reqParams.current = { ...filter, page: 1 }
			updateHistory(pathname, reqParams.current)
			setBuilding(filter.building_id)
			fetchDataPagination()
		},
		[fetchDataPagination, getInvitesCount, pathname],
	)

	const isAdminManager = !isNormalUser(user)

	useEffect(() => {
		updateHistory(pathname, reqParams.current)
		fetchDataPagination()
		if (reqParams.current.search !== "" || reqParams.current.show !== "") {
			getInvitesCount(reqParams.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, start])

	// it will navigate to the first page when there is no results on higher pages
	useEffect(() => {
		if (isLoaded && invitesCount > 0 && tableData.length === 0 && page > 1) {
			setPage(1)
		}
	}, [invitesCount, isLoaded, page, tableData.length, setPage])

	useEffect(() => {
		actions.getVisitorsTotal({
			building_id: building,
			limit: 0,
			start,
			end,
		})
	}, [actions, building, end, start])

	useEffect(() => {
		if (!settingsLoaded) {
			actions.fetchVisitorSettings()
		}
	}, [actions, settingsLoaded])

	// saves the current params into the locale store
	useEffect(
		() => () => {
			saveFilter(reqParams.current)
		},
		[saveFilter],
	)

	return (
		<View className="ManageInvites">
			{!settingsLoaded ? (
				<div className="loading">
					<Loader />
				</div>
			) : (
				<>
					<Heading>
						<DayNav noMaxDateSet />

						<span className="date-range">
							<Datestring date={currentDate} isLong showYear isInline />
						</span>

						<WeekendToggle
							showWeekends={showWeekends}
							onToggle={actions.toggleWeekends}
						/>

						<div className="space"></div>

						{isAdminManager && (
							<Button
								onClick={handleExportClick}
								variant="secondary-white"
								isSmall
							>
								{t("desktop.manage.visitors.buttons.export_csv")}
							</Button>
						)}
						{(isAdminManager ||
							(visitorSettings?.length &&
								visitorSettings
									.filter((setting) => setting.enabled)
									.some(
										(setting) =>
											setting.create_visits_by !==
											NewVisitsCreatedByOption.OFFICE_MANAGER_AND_ADMIN,
									))) && (
							<Button isSmall onClick={handleNewClick}>
								{t("desktop.manage.visitors.buttons.new_invite")}
							</Button>
						)}
					</Heading>

					<Space size={0.5} />

					<MultiToggle
						options={[
							{
								label: (
									<span>
										{t("desktop.manage.visitors.tabs.visitors")}
										{" · "}
										{totalCount}
									</span>
								),
								value: TabType.VISITS,
							},
							{
								label: (
									<span>
										{t("desktop.manage.visitors.tabs.invites")}
										{" · "}
										{invitesTotal}
									</span>
								),
								value: TabType.INVITES,
							},
						]}
						onChange={onTabChangeHandler}
						value={TabType.INVITES}
					/>

					<Space size={0.5} />

					<InvitesFilters
						onChange={handleFilterChange}
						defaultValues={storedFilterValues}
					/>

					<Space size={0.5} />

					<Table
						showRowNumber
						calcRowNumber={calcRowNumber}
						loading={false}
						rows={tableData}
						columns={tableColumns}
						rowActions={rowActions}
						onRowClick={editInvite}
						pagination={
							paginationLinks.length > 1 ? (
								<Pagination
									links={paginationLinks}
									setPage={setPage}
									onPrevious={() => setPage(page - 1)}
									onNext={() => setPage(page + 1)}
									hasNext={hasNext}
									hasPrevious={hasPrevious}
									from={from}
									to={to}
									total={invitesCount}
									items={t("desktop.manage.visitors.invite.table.invites")}
								/>
							) : undefined
						}
						// footerCells={tableFooterCells}
					/>
				</>
			)}
		</View>
	)
}

export default ManageInvites
