import { api } from ".."
import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { floorURL, floorsURL } from "../../../api"
import { ResponseError } from "../../../api/apiUtils"
import { countFloorsInBuildings } from "../../../utils"
import { fetchBuildingsOnly } from "../../buildings/buildingsSlice"
import { PaginatedOptions } from "../../reduxUtils"
import {
	FetchFloorRequestProps,
	FetchFloorsRequestProps,
	FloorRequest,
	FloorResponse,
	FloorsResponse,
	UpdateFloorRequest,
} from "./types"

export const floors = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchFloors: builder.query<
			FloorsResponse & { offset: number },
			PaginatedOptions & FetchFloorsRequestProps
		>({
			query: ({ building = null, stats = true }) => {
				const query = building
					? {
							conditions: { building_id: building },
					  }
					: {
							fields: ["*", "settings"],
					  }
				return {
					url: floorsURL({ query, stats }),
				}
			},
			providesTags: (result, _error) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "Floors" as const,
								id,
							})),
							{ type: "Floors", id: "LIST" },
					  ]
					: [{ type: "Floors", id: "LIST" }],
			transformResponse: (response: FloorsResponse, _meta, arg) => {
				return { ...response, offset: arg?.offset ?? 0 }
			},
		}),
		fetchFloor: builder.query<FloorResponse, FetchFloorRequestProps>({
			query: ({ id, stats = false, desks = false, settings = true }) => ({
				url: floorURL(id, {
					stats,
					desks,
					...(settings && {
						query: {
							fields: ["*", "settings"],
						},
					}),
				}),
			}),
			providesTags: (_result, _error, { id }) => [{ type: "Floors", id }],
		}),
		createFloor: builder.mutation<FloorResponse, FloorRequest>({
			query: (body) => ({
				url: floorsURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Floors", id: "LIST" }],
			onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
				try {
					const { data, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(fetchBuildingsOnly({ stats: true }))
							.then(({ payload }: any) => {
								analyticsEvent(SupportedEvents.FLOOR_ADD, {
									id: data.id,
									name: data.name,
									total: countFloorsInBuildings(payload),
								})
							})
							.catch((err: ResponseError) => console.log(err))
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		updateFloor: builder.mutation<FloorResponse, UpdateFloorRequest>({
			query: ({ id, ...body }) => ({
				url: floorURL(id, {
					query: {
						fields: ["*", "settings"],
					},
				}),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "Floors", id: "LIST" },
				{ type: "Floors", id },
			],
			onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
				try {
					const { data, meta } = await queryFulfilled

					if (meta?.response?.ok) {
						analyticsEvent(SupportedEvents.FLOOR_UPDATE, {
							id: data.id,
							name: data.name,
						})
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
		destroyFloor: builder.mutation<void, string>({
			query: (id) => ({
				url: floorURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "Floors", id: "LIST" },
				{ type: "Floors", id },
			],
			onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
				try {
					const { meta } = await queryFulfilled

					if (meta?.response?.ok) {
						dispatch(fetchBuildingsOnly({ stats: true }))
							.then(({ payload }: any) => {
								analyticsEvent(SupportedEvents.FLOOR_DELETE, {
									id,
									total: countFloorsInBuildings(payload),
								})
							})
							.catch((err: ResponseError) => console.log(err))
					}
				} catch (err) {
					console.error(err)
				}
			},
		}),
	}),
})

export const {
	useFetchFloorsQuery,
	useLazyFetchFloorsQuery,
	useFetchFloorQuery,
	useLazyFetchFloorQuery,
	useCreateFloorMutation,
	useUpdateFloorMutation,
	useDestroyFloorMutation,
} = floors
