import { ParseKeys, t } from "i18next"

import { PLAN_GROUPS, PLAN_TYPES } from "../../../redux/api/billing/constants"
import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanGroup, PlanType } from "../../../redux/api/billing/types"

import { ReactComponent as BadgeSVG } from "../../../assets/images/icons/Badge.svg"
import { ReactComponent as ChairSVG } from "../../../assets/images/icons/Chair.svg"
import { ReactComponent as MeetingRoomSVG } from "../../../assets/images/icons/Meeting room.svg"
import { ReactComponent as SignSVG } from "../../../assets/images/icons/Sign.svg"

export const PLAN_TYPES_MAP: Record<
	string,
	{
		title: string
		name: string
		demoUrl: string
		icon: (props: { className: string }) => JSX.Element
	}
> = {
	[PLAN_TYPES.JOAN_ON_DISPLAY]: {
		title: "desktop.components.subscription_card.joan_on_display",
		name: "desktop.components.subscription_card.joan_on_display",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <SignSVG {...props} />,
	},
	[PLAN_TYPES.JOAN_ON_TABLET]: {
		title: "desktop.components.subscription_card.joan_on_tablets",
		name: "desktop.components.subscription_card.joan_on_tablets",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs",
		icon: (props: { className: string }) => <SignSVG {...props} />,
	},
	[PLAN_TYPES.SIGN]: {
		title: "desktop.components.subscription_card.joan_sign",
		name: "desktop.components.subscription_card.joan_sign",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <SignSVG {...props} />,
	},
	[PLAN_TYPES.SIGN_ESSENTIALS]: {
		title: "desktop.components.subscription_card.joan_sign",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <SignSVG {...props} />,
	},
	[PLAN_TYPES.SIGN_PROFESSIONAL]: {
		title: "desktop.components.subscription_card.joan_sign",
		name: "desktop.components.subscription_card.professional",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <SignSVG {...props} />,
	},
	[PLAN_TYPES.DESK_ESSENTIALS]: {
		title: "desktop.components.subscription_card.joan_desk_asset",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <ChairSVG {...props} />,
	},
	[PLAN_TYPES.DESK_AND_ASSET_ESSENTIALS]: {
		title: "desktop.components.subscription_card.joan_desk_asset",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <ChairSVG {...props} />,
	},
	[PLAN_TYPES.DESK_BOOKING]: {
		title: "desktop.components.subscription_card.joan_desk_asset",
		name: "desktop.components.subscription_card.desk_booking",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <ChairSVG {...props} />,
	},
	[PLAN_TYPES.DESK_BOOKING_EAP]: {
		title: "desktop.components.subscription_card.joan_desk_asset",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <ChairSVG {...props} />,
	},
	[PLAN_TYPES.HOME_ESSENTIALS]: {
		title: "desktop.components.subscription_card.joan_home",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <MeetingRoomSVG {...props} />,
	},
	[PLAN_TYPES.ROOM_ESSENTIALS]: {
		title: "desktop.components.subscription_card.joan_room",
		name: "desktop.components.subscription_card.essentials",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <MeetingRoomSVG {...props} />,
	},
	[PLAN_TYPES.ROOM_PROFESSIONAL]: {
		title: "desktop.components.subscription_card.joan_room",
		name: "desktop.components.subscription_card.professional",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <MeetingRoomSVG {...props} />,
	},
	[PLAN_TYPES.ROOM_ENTERPRISE]: {
		title: "desktop.components.subscription_card.joan_room",
		name: "desktop.components.subscription_card.enterprise",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <MeetingRoomSVG {...props} />,
	},
	[PLAN_TYPES.VISITOR_PROFESSIONAL]: {
		title: "desktop.components.subscription_card.joan_visitor",
		name: "desktop.components.subscription_card.professional",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <BadgeSVG {...props} />,
	},
	[PLAN_TYPES.VISITOR_PROFESSIONAL_EAP]: {
		title: "desktop.components.subscription_card.joan_visitor",
		name: "desktop.components.subscription_card.early_bird",
		demoUrl: "https://www.youtube.com/watch?v=E5WHwdveVvs", //TODO: change to correct URL
		icon: (props: { className: string }) => <BadgeSVG {...props} />,
	},
} as const

export const PLAN_GROUP_DESCRIPTION: Record<PlanGroup, string> = {
	[PLAN_GROUPS.DESK_BOOKING]:
		"desktop.components.subscription_card.desk_booking_description",
	[PLAN_GROUPS.ROOM]:
		"desktop.components.subscription_card.room_booking_description",
	[PLAN_GROUPS.SIGN]:
		"desktop.components.subscription_card.joan_sign_description",
	[PLAN_GROUPS.VISITOR]:
		"desktop.components.subscription_card.visitor_management_description",
	[PLAN_GROUPS.HOME]: "",
	[PLAN_GROUPS.JOAN_ON_DISPLAY]: "",
	[PLAN_GROUPS.JOAN_ON_TABLET]: "",
} as const

export const toFriendlyName = (planType: PlanType) =>
	PLAN_TYPES_MAP[planType]?.name
		? t(PLAN_TYPES_MAP[planType]?.name as ParseKeys)
		: `<${t("desktop.components.subscription_card.unknown")}>`

export const toFriendlyTitle = (planType: PlanType) =>
	PLAN_TYPES_MAP[planType]?.title
		? t(PLAN_TYPES_MAP[planType]?.title as ParseKeys)
		: `<${t("desktop.components.subscription_card.unknown")}>`

export const getDemoUrl = (planType: PlanType) =>
	PLAN_TYPES_MAP[planType]?.demoUrl ??
	"https://www.youtube.com/@meetJOAN/videos"

export const getSubscriptionIcon = (planType: PlanType) =>
	PLAN_TYPES_MAP[planType]?.icon ??
	((props: { className: string }) => <BadgeSVG {...props} />)

export const getPlanGroup = (planType: PlanType) => {
	switch (planType) {
		case PLAN_TYPES.DESK_BOOKING:
		case PLAN_TYPES.DESK_BOOKING_EAP:
		case PLAN_TYPES.DESK_ESSENTIALS:
		case PLAN_TYPES.DESK_AND_ASSET_ESSENTIALS:
			return PLAN_GROUPS.DESK_BOOKING

		case PLAN_TYPES.HOME:
		case PLAN_TYPES.HOME_ESSENTIALS:
			return PLAN_GROUPS.HOME

		case PLAN_TYPES.JOAN_ON_DISPLAY:
			return PLAN_GROUPS.JOAN_ON_DISPLAY

		case PLAN_TYPES.JOAN_ON_TABLET:
			return PLAN_GROUPS.JOAN_ON_TABLET

		case PLAN_TYPES.SIGN:
		case PLAN_TYPES.SIGN_ESSENTIALS:
		case PLAN_TYPES.SIGN_PROFESSIONAL:
			return PLAN_GROUPS.SIGN

		case PLAN_TYPES.VISITOR_PROFESSIONAL:
		case PLAN_TYPES.VISITOR_PROFESSIONAL_EAP:
			return PLAN_GROUPS.VISITOR

		default:
			return PLAN_GROUPS.ROOM
	}
}

export const getPlanDescription = (planType: PlanType) => {
	const planDescription = PLAN_GROUP_DESCRIPTION[getPlanGroup(planType)]
	return planDescription ? t(planDescription as ParseKeys) : ""
}

export const isSubscription = (
	value: Subscription | { plan_type: PlanType },
): value is Subscription => "subscription_id" in value
