import { useMemo } from "react"

import dayjs from "dayjs"

import { FETCH_WITH_NO_LIMIT } from "../constants"
import { toInternalTime } from "../utils"

import {
	useFetchDeskReservationsQuery,
	useFetchMyDeskReservationsQuery,
} from "../redux/api/deskReservations"
import { DeskReservation } from "../redux/api/deskReservations/types"
import { useFetchDesksQuery } from "../redux/api/desks"
import { DepartmentResponse } from "../redux/departments/types"
import { useAppSelector } from "../redux/reducers"
import { selectSettingsEffective } from "../redux/settings/selectors"
import { TimeslotResponse } from "../redux/timeslots/types"
import { areSlotsOverlapping } from "../redux/timeslots/utils"
import { selectUser } from "../redux/user/selectors"

import { FilterSpecialValues } from "../components/Filter/types"

type Props = {
	date?: string
	timeslot?: Partial<TimeslotResponse>
	floorId?: string
	buildingId?: string
	departmentId?: string
	excludeResId?: string
}

export const useCalculateBookable = ({
	date,
	timeslot,
	floorId,
	buildingId,
	departmentId,
	excludeResId,
}: Props) => {
	const start = dayjs(date).startOf("day").toISOString()
	const end = dayjs(date).endOf("day").toISOString()
	const skip = !floorId && !buildingId && !departmentId
	const {
		data: { results: reservations } = {},
		isLoading: areDeskReservationsLoading,
	} = useFetchDeskReservationsQuery(
		{
			start,
			end,
			building_id: buildingId,
			floor_id: floorId === FilterSpecialValues.ALL ? undefined : floorId,
			department_id: departmentId,
			limit: FETCH_WITH_NO_LIMIT,
		},
		{
			skip,
		},
	)

	const {
		data: { results: myReservations } = {},
		isLoading: areMyDeskReservationsLoading,
	} = useFetchMyDeskReservationsQuery(
		{
			start,
			end,
			building_id: buildingId,
			limit: FETCH_WITH_NO_LIMIT,
		},
		{ skip },
	)

	const { data: { results: desks } = {}, isLoading: areDesksLoading } =
		useFetchDesksQuery(
			{
				floor: floorId,
				building: buildingId,
				department_id: departmentId,
				limit: FETCH_WITH_NO_LIMIT,
			},
			{ skip },
		)

	const { entry: user, isLoading: isUserLoading } = useAppSelector(selectUser)
	const { entry: settings, isLoading: areSettingsLoading } = useAppSelector(
		selectSettingsEffective,
	)

	const isLoading =
		areDeskReservationsLoading ||
		areMyDeskReservationsLoading ||
		areDesksLoading ||
		isUserLoading ||
		areSettingsLoading

	const desksBookable: Record<string, boolean> = useMemo(() => {
		if (
			isLoading ||
			desks === undefined ||
			settings === null ||
			reservations === undefined ||
			myReservations === undefined
		) {
			return {}
		}

		const isAdminOrManager = user.permissions.includes(
			"desk.view_reservation_override_department_rules",
		)

		const filteredReservations = getFilteredReservations(
			reservations,
			timeslot,
			excludeResId,
		)

		const bookableDesks: Record<string, boolean> = {}

		desks.forEach((desk) => {
			let isBookable = true

			// Desk not active
			if (!desk.active) {
				bookableDesks[desk.id] = false
				return
			}

			// More than one concurrent reservation per user
			if (settings.desk_one_reservation_per_user && timeslot !== undefined) {
				const userReservation = getFilteredReservations(
					myReservations,
					timeslot,
					excludeResId,
				)

				if (userReservation.length > 0) {
					bookableDesks[desk.id] = false
					return
				}
			}

			// User is not admin or office manager
			if (!isAdminOrManager) {
				// User not in the same departments
				if (settings.desk_department_rules_enabled) {
					const userHasDep =
						user.departments !== undefined && user.departments.length > 0
					const deskHasDep =
						desk.departments !== undefined && desk.departments.length > 0

					if (!userHasDep && deskHasDep) {
						bookableDesks[desk.id] = false

						return
					} else if (userHasDep && deskHasDep) {
						let inDepartment = false

						for (let dd of desk.departments!) {
							const userDepartment = user.departments.find(
								(ud: DepartmentResponse) => ud.id === dd.id,
							)

							if (userDepartment !== undefined) {
								inDepartment = true
								break
							}
						}

						if (!inDepartment) {
							bookableDesks[desk.id] = false
							return
						}
					}
				}

				// User is not assigned to the desk
				if (desk.users !== undefined && desk.users.length > 0) {
					const deskUser = desk.users.find((du) => du.email === user.email)

					if (deskUser === undefined) {
						bookableDesks[desk.id] = false
						return
					}
				}

				// Desk already reserved
				if (timeslot !== undefined) {
					const deskReservation = filteredReservations.find(
						(r) => r.desk.id === desk.id,
					)

					if (deskReservation !== undefined) {
						bookableDesks[desk.id] = false
						return
					}
				}

				// TODO Occupancy limit reached
			}

			bookableDesks[desk.id] = isBookable
		})

		return bookableDesks
	}, [
		timeslot,
		desks,
		user,
		reservations,
		settings,
		excludeResId,
		myReservations,
		isLoading,
	])

	return desksBookable
}

const getFilteredReservations = (
	reservations: DeskReservation[],
	timeslot?: Partial<TimeslotResponse>,
	excludeResId?: string,
) => {
	if (timeslot !== undefined) {
		return reservations?.filter((res) => {
			return (
				areSlotsOverlapping(
					{ from: timeslot.from, to: timeslot.to },
					{
						from: toInternalTime(res.start ?? ""),
						to: toInternalTime(res.end ?? ""),
					},
				) &&
				(!excludeResId || res.id !== excludeResId)
			)
		})
	}
	return []
}
