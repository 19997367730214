import { MouseEvent, useCallback, useRef } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { amenitiesURL, departmentsURL, usersURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import { equalArraysById } from "../../utils"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import Button from "../basic/Button"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import { AmenityResponse } from "../../redux/amenities/types"
import {
	useCreateDeskMutation,
	useDestroyDeskMutation,
	useUpdateDeskMutation,
} from "../../redux/api/desks"
import { DeskRequestWithId, DeskResponse } from "../../redux/api/desks/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { setRepositionDesk } from "../../redux/app/appSlice"
import { DepartmentResponse } from "../../redux/departments/types"
import { FloorResponse } from "../../redux/floors/types"
import { formatUser } from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"
import { useActions } from "../../redux/utils"

import "./DeskForm.sass"

type Props = {
	floor: FloorResponse | null
	desks: DeskResponse[]
}

type FormValues = {
	name: string
	active: boolean
	departments: DepartmentResponse[]
	users: UserResponse[]
	amenities: AmenityResponse[]
}

const DeskForm = ({ floor, desks }: Props) => {
	const { closeModal } = useModals()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const [createDesk] = useCreateDeskMutation()
	const [updateDesk] = useUpdateDeskMutation()
	const [destroyDesk] = useDestroyDeskMutation()

	const actions = useActions({
		repositionDesk: (desk: DeskRequestWithId) => setRepositionDesk(desk),
	})

	const multiple = desks.length > 1

	let multipleDepartments = false
	let multipleUsers = false
	let multipleAmenities = false
	let multipleEnabled = false

	if (multiple) {
		desks.forEach((desk) => {
			if (!multipleDepartments) {
				multipleDepartments = !equalArraysById(
					desk.departments!,
					desks[0].departments!,
				)
			}
			if (!multipleUsers) {
				multipleUsers = !equalArraysById(desk.users!, desks[0].users!)
			}
			if (!multipleAmenities) {
				multipleAmenities = !equalArraysById(
					desk.amenities!,
					desks[0].amenities!,
				)
			}
			if (desk.active !== desks[0].active) {
				multipleEnabled = true
			}
		})
	}

	const desk = desks[0]
	const { id, name, users, departments, amenities, active = true } = desk ?? {}

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? "",
			active: multipleEnabled ? false : active,
			departments: multiple ? [] : departments ?? [],
			users: multiple ? [] : users ?? [],
			amenities: multiple ? [] : amenities ?? [],
		},
	})

	const {
		setError,
		control,
		formState: { isSubmitting },
	} = methods

	const deskEnabled = useRef<boolean | undefined>(
		multipleEnabled ? undefined : active,
	)

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			const requests = []

			for (const desk of desks) {
				requests.push(destroyDesk(desk.id))
			}

			Promise.all(requests)
				.then((responses) => {
					for (const response of responses) {
						if (!isRejected(response)) {
							infoToast(
								t("desktop.settings.desks.desk_form.desk_deleted_toast"),
							)
						} else {
							errorToast(response.error.message)
						}
					}
				})
				.finally(() => {
					closeModal()
				})
		},
		[desks, destroyDesk, infoToast, t, errorToast, closeModal],
	)

	const onCreateClick = useCallback(
		async ({ name, departments, users, amenities, active }: FormValues) => {
			if (!floor) {
				return
			}
			const response = await createDesk({
				floor_id: floor.id,
				name,
				coord_x: desk.coord_x,
				coord_y: desk.coord_y,
				active,
				departments: departments?.map((d) => d.id) ?? [],
				users: users?.map((u) => u.email) ?? [],
				amenities: amenities?.map((a) => a.id) ?? [],
			})

			if (!isRejected(response)) {
				infoToast(t("desktop.settings.desks.desk_form.desk_created_toast"))
				closeModal()
			} else {
				if (isApiResponseError(response.error)) {
					setErrors(response.error.formError, setError, errorToast)
				}
			}
		},
		[
			floor,
			createDesk,
			desk.coord_x,
			desk.coord_y,
			infoToast,
			t,
			closeModal,
			setError,
			errorToast,
		],
	)

	const onUpdateClick = useCallback(
		async ({ name, active, departments, users, amenities }: FormValues) => {
			if (!floor) {
				return
			}

			let response

			if (multiple) {
				const requests = []

				for (let d of desks) {
					const r = updateDesk({
						id: d.id,
						floor_id: d.floor_id,
						name: d.name,
						coord_x: d.coord_x,
						coord_y: d.coord_y,
						active: deskEnabled.current === undefined ? d.active : active,
						departments:
							departments.length === 0
								? d.departments?.map((d) => d.id)
								: departments.map((d) => d.id),
						users:
							users.length === 0
								? d.users?.map((u) => u.email)
								: users.map((u) => u.email),
						amenities:
							amenities.length === 0
								? d.amenities?.map((a) => a.id)
								: amenities.map((a) => a.id),
					})

					requests.push(r)
				}

				Promise.all(requests)
					.then((responses) => {
						for (const response of responses) {
							if (!isRejected(response)) {
								infoToast(
									t("desktop.settings.desks.desk_form.desk_updated_toast"),
								)
							} else {
								if (isApiResponseError(response.error)) {
									setErrors(response.error.formError, setError, errorToast)
								}
							}
						}
					})
					.finally(() => {
						closeModal()
					})
			} else {
				response = await updateDesk({
					id: desk.id,
					floor_id: floor.id,
					name,
					coord_x: desk.coord_x,
					coord_y: desk.coord_y,
					active,
					departments: departments.map((d) => d.id),
					users: users.map((u) => u.email),
					amenities: amenities.map((a) => a.id),
				})

				if (!isRejected(response)) {
					infoToast(t("desktop.settings.desks.desk_form.desk_updated_toast"))
					closeModal()
				} else {
					if (isApiResponseError(response.error)) {
						setErrors(response.error.formError, setError, errorToast)
					}
				}
			}
		},
		[
			floor,
			multiple,
			desks,
			updateDesk,
			infoToast,
			t,
			setError,
			errorToast,
			closeModal,
			desk.id,
			desk.coord_x,
			desk.coord_y,
		],
	)

	const onRepositionClick = useCallback(
		(e: MouseEvent) => {
			e.preventDefault()

			infoToast(
				t("desktop.settings.desks.desk_form.desk_reposition_toast_click", {
					deskName: desk.name,
				}),
			)

			actions.repositionDesk({
				...desk,
				departments: desk.departments?.map((d) => d.id),
				users: desk.users?.map((u) => u.email),
				amenities: desk.amenities?.map((a) => a.id),
			})
			closeModal()
		},
		[infoToast, t, desk, actions, closeModal],
	)

	const canReposition =
		!multiple && !process.env.REACT_APP_FEATURE_DISABLE_DESK_REPOSITIONING

	const updateMode = Boolean(id)

	const title = updateMode
		? multiple
			? t("desktop.settings.desks.desk_form.edit_multi_desk")
			: t("desktop.settings.desks.desk_form.edit_desk")
		: t("desktop.settings.desks.desk_form.new_desk")

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="DeskForm"
				updateMode={updateMode}
				title={title}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
				additionalButton={
					updateMode && canReposition ? (
						<Button
							variant="secondary"
							className="move"
							onClick={onRepositionClick}
							isDisabled={isSubmitting}
						>
							{t("desktop.settings.desks.desk_form.reposition_button")}
						</Button>
					) : undefined
				}
			>
				{!multiple && (
					<Field
						control={control}
						name={"name"}
						label={t("desktop.settings.desks.desk_form.desk_name_or_number")}
						helpText={t("desktop.settings.desks.desk_form.desk_name_example")}
					>
						{(props) => (
							<Input
								autoFocus
								maxLength={30}
								disabled={isSubmitting}
								{...props}
							/>
						)}
					</Field>
				)}

				<Field
					control={control}
					name="departments"
					label={t("desktop.settings.desks.desk_form.departments")}
					subText={multipleDepartments && t("general.multiple_values")}
				>
					{(props) => (
						<AsyncSelect
							isPaginated
							isMulti
							urlGenerator={(fetchOptions) => {
								return departmentsURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.desks.desk_form.no_departments_found",
							)}
							getOptionLabel={(department) => department?.name ?? ""}
							getOptionValue={(department) => department?.id ?? ""}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="users"
					label={t("desktop.settings.desks.desk_form.assign_to")}
					subText={multipleUsers && t("general.multiple_values")}
				>
					{(props) => (
						<AsyncSelect
							isMulti
							urlGenerator={(fetchOptions) => {
								return usersURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.desks.desk_form.no_user_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>

				<Field
					control={control}
					name={"amenities"}
					label={t("desktop.settings.desks.desk_form.amenities")}
					subText={multipleAmenities && t("general.multiple_values")}
				>
					{(props) => (
						<AsyncSelect
							isMulti
							urlGenerator={(fetchOptions) => {
								return amenitiesURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.desks.desk_form.no_amenities_found",
							)}
							getOptionLabel={(amenity) => amenity?.name ?? ""}
							getOptionValue={(amenity) => amenity?.id ?? ""}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field control={control} name="active" className="asset-enabled-field">
					{({ onChange, ...props }) => (
						<Switch
							{...props}
							onChange={(v) => {
								deskEnabled.current = v
								onChange(v)
							}}
							label={t("desktop.settings.desks.desk_form.enable_desk")}
							subLabel={multipleEnabled ? "multiple values" : undefined}
							disabled={isSubmitting}
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default DeskForm
