import posthog from "posthog-js"

import * as sentry from "@sentry/capacitor"
import { init as sentryReactInit } from "@sentry/react"

import { MeResponse } from "./redux/user/types"

/**
 * This file initializes all analytics/statistics we currently support.
 *
 * It also exposes functions to handle user identification
 * and events.
 */

declare global {
	interface Window {
		additionalSentryConfig: any
		additionalPostHogConfig: any
		sentry: any
		posthog: any
	}
}

const sentryDNS = process.env.REACT_APP_SENTRY_DSN
const posthogId = process.env.REACT_APP_POSTHOG_ID
const posthogUrl = process.env.REACT_APP_POSTHOG_URL

const isSentryActive = !!sentryDNS
const isPosthogActive = !!posthogId && !!posthogUrl

/**
 * https://sentry.io/
 *
 * Is used for error tracking only.
 */
if (isSentryActive) {
	console.log("Sentry is being initialized.")
	/**
	 * https://docs.sentry.io/platforms/javascript/guides/capacitor/#configure
	 * https://github.com/getsentry/sentry-capacitor/issues/40
	 * Sibling React init unofficially supported by @sentry/capacitor, but confirmed by contributors.
	 */
	sentry.init(
		{
			dsn: sentryDNS,
			environment: process.env.REACT_APP_SENTRY_ENV || "development",
			debug: process.env.NODE_ENV === "development",
			release: process.env.REACT_APP_VERSION,
		},
		sentryReactInit,
	)
}

/**
 * https://app.posthog.com/
 *
 * Event and user tracking.
 */
if (isPosthogActive) {
	console.log("Posthog is being initialized.")

	posthog.init(posthogId, {
		api_host: posthogUrl,
		disable_session_recording: true,
	})
}

/**
 * Currently supported events, described and updated here:
 *
 * https://docs.google.com/spreadsheets/d/1LlRcY9IH6IFN_nsV9CTp1stSBTX4XC2VnZrxkiVIKmw/edit#gid=507533559
 */
export const analyticsIdentifyUser = (user: MeResponse) => {
	if (!user) {
		const problem = Error(
			"No user found when calling analyticsIdentifyUser. analyticsIdentifyUser() failed.",
		)

		if (isSentryActive) {
			sentry.captureException(problem)
		}

		console.error(problem.message)

		return
	}

	const company = user.companies ? user.companies[0] : undefined

	if (!company) {
		const problem = Error(
			`No company found on the user ${user.email}. analyticsIdentifyUser() failed.`,
		)

		if (isSentryActive) {
			sentry.captureException(problem)
		}

		console.error(problem.message)

		return
	}

	const { email, id: userId, groups } = user
	const { analytics_domain, country, name, id: companyId } = company

	const portal_user_role = groups.includes("portal_admin")
		? "portal_admin"
		: groups.includes("portal_officer_manager")
		? "portal_office_manager"
		: "portal_user"

	if (isSentryActive) {
		sentry.configureScope((scope: any) => {
			scope.setUser({ email })
		})
	}

	if (isPosthogActive) {
		posthog.identify(userId, {
			email,
			team_name: name,
			team_id: companyId,
			team_domain: analytics_domain,
			team_country: country,
			portal_user_role,
		})

		posthog.setPersonProperties({
			email,
			team_name: name,
			team_id: companyId,
			team_domain: analytics_domain,
			team_country: country,
			portal_user_role,
		})
	}
}

export const enablePosthogRecording = (groups?: string[]) => {
	if (!isPosthogActive || !groups) {
		return
	}

	if (
		groups.includes("portal_admin") ||
		groups.includes("portal_officer_manager")
	) {
		posthog.startSessionRecording()
	}
}

export const resetPosthog = () => {
	if (!isPosthogActive) {
		return
	}

	posthog.reset()
}

/**
 * Currently supported events, described and updated here:
 *
 * https://docs.google.com/spreadsheets/d/1LlRcY9IH6IFN_nsV9CTp1stSBTX4XC2VnZrxkiVIKmw/edit#gid=773844549
 */
export enum SupportedEvents {
	AMENITY_ADD = "JAP/admin/amenity_add",
	AMENITY_DELETE = "JAP/admin/amenity_delete",
	ASSET_ADD = "JAP/admin/asset_add",
	ASSET_BOOKED = "JAP/user/asset_booked",
	ASSET_BOOKED_FOR_OTHERS = "JAP/admin/asset_booked_for_others",
	ASSET_DELETE = "JAP/admin/asset_delete",
	ASSET_TYPE_ADD = "JAP/admin/asset_type_add",
	ASSET_TYPE_DELETE = "JAP/admin/asset_type_delete",
	ASSET_TYPE_UPDATE = "JAP/admin/asset_type_update",
	ASSET_UPDATE = "JAP/admin/asset_update",
	DEPARTMENT_ADD = "JAP/admin/department_add",
	DEPARTMENT_DELETE = "JAP/admin/department_delete",
	DEPARTMENT_UPDATE = "JAP/admin/department_update",
	DESK_ADD = "JAP/admin/desk_add",
	DESK_DELETE = "JAP/admin/desk_delete",
	DESK_UPDATE = "JAP/admin/desk_update",
	FILTER_BY_AMENITY = "JAP/user/filter_by_amenity",
	FLOOR_ADD = "JAP/admin/floor_add",
	FLOOR_DELETE = "JAP/admin/floor_delete",
	FLOOR_UPDATE = "JAP/admin/floor_update",
	FLOORPLAN_ADD = "JAP/admin/floorplan_add",
	FLOORPLAN_EDIT = "JAP/admin/floorplan_edit",
	FLOORPLAN_SHARING_DISABLED = "JAP/admin/floorplan_sharing_disabled",
	FLOORPLAN_SHARING_ENABLED = "JAP/admin/floorplan_sharing_enabled",
	INITIAL_LOCATION_ADD = "JAP/admin/initial_location_add",
	LOCATION_ADD = "JAP/admin/location_add",
	LOCATION_DELETE = "JAP/admin/location_delete",
	LOCATION_UPDATE = "JAP/admin/location_update",
	PEOPLE_ADD = "JAP/admin/people_add",
	PEOPLE_REMOVE = "JAP/admin/people_remove",
	RESERVATION_ADD = "JAP/user/reservation_add",
	RESERVATION_DELETE = "JAP/user/reservation_delete",
	RESERVATION_UPDATE = "JAP/user/reservation_update",
	USER_LOGGED_IN = "JAP/login/user_logged_in",
	ROOM_BOOKED = "JMA/user/room_booked",
	ROOM_BOOKING_CANCEL = "JMA/user/room_booking_cancel",
	ROOM_BOOKING_CHECKIN = "JMA/user/room_booking_checkin",
	ROOM_BOOKING_END = "JMA/user/room_booking_end",
	ROOM_BOOKING_EXTEND = "JMA/user/room_booking_extend",
	DESK_BOOKED = "JAP/user/desk_booked",
	DESK_BOOKED_FOR_OTHERS = "JAP/admin/desk_booked_for_others",
	DESK_RESERVATION_CHECKIN = "JAP/user/desk_reservation_checkin",
	DESK_RESERVATION_CHECKOUT = "JAP/user/desk_reservation_checkout",
	DESK_PRESENCE = "JMA/user/desk_presence",
	DESK_MAP = "JMA/user/desk_map",
	DESK_MAP_TIMESLIDER = "JMA/user/desk_map_timeslider",
	DESK_RESERVATION_RECOMMENDED = "JMA/user/desk_reservation_recommended",
	VISITOR_ADMIN_MANAGEMENT_ENABLED = "JAP/admin/visitor_solution_enabled",
	VISITOR_ADMIN_MANAGEMENT_DISABLED = "JAP/admin/visitor_solution_disabled",
	VISITOR_ADMIN_DOCUMENT_ADD = "JAP/admin/visitor_document_add",
	VISITOR_ADMIN_DOCUMENT_DELETE = "JAP/admin/visitor_document_delete",
	VISITOR_ADMIN_TABLET_DELETE = "JAP/admin/visitor_tablet_delete",
	VISITOR_ADMIN_PRINTER_DELETE = "JAP/admin/visitor_printer_delete",
	VISITOR_ADMIN_TABLET_ADD = "JAP/admin/visitor_tablet_add",
	VISITOR_ADMIN_PRINTER_ADD = "JAP/admin/visitor_printer_add",
	VISITOR_ADMIN_SIGN_IN_ENABLED = "JAP/admin/visitor_tablet_signin_enabled",
	VISITOR_ADMIN_SIGN_IN_DISABLED = "JAP/admin/visitor_tablet_signin_disabled",
	VISITOR_ADMIN_PRINTER_ENABLED = "JAP/admin/visitor_printer_enabled",
	VISITOR_ADMIN_PRINTER_DISABLED = "JAP/admin/visitor_printer_disabled",
	VISITOR_ADMIN_INVITE_ADD = "JAP/admin/visitor_invite_add",
	VISITOR_ADMIN_INVITE_UPDATE = "JAP/admin/visitor_invite_update",
	VISITOR_ADMIN_VISIT_ADD = "JAP/admin/visitor_visit_add",
	VISITOR_ADMIN_VISIT_UPDATE = "JAP/admin/visitor_visit_update",
	VISITOR_USER_INVITE_ADD = "JAP/user/visitor_invite_add",
	VISITOR_USER_INVITE_UPDATE = "JAP/user/visitor_invite_update",
	VISITOR_USER_VISIT_ADD = "JAP/user/visitor_visit_add",
	VISITOR_USER_VISIT_UPDATE = "JAP/user/visitor_visit_update",
	VISITOR_CHECK_IN = "JMA/visitor/visitor_visitor_check_in",
	VISITOR_DOCUMENT_SIGNED = "JMA/visitor/visitor_visitor_document_signed",
	VISITOR_USER_OPEN_VISIT_CARD = "JMA/user/visitor_open_visit_card",
	VISITOR_USER_OPEN_INVITE_CARD = "JMA/user/visitor_open_invite_card",
	VISITOR_USER_BADGE_PRINTED = "JMA/user/visitor_badge_printed",
}

export const analyticsEvent = (eventName: SupportedEvents, metadata?: any) => {
	if (isPosthogActive) {
		posthog.capture(eventName, metadata)
	}
}
