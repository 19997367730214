import { FETCH_WITH_NO_LIMIT } from "../constants"
import { UrlParameters, queryBuilder } from "./queryBuilder"

import { DeleteType } from "../redux/reservations/types"
import { SsoProviderType } from "../redux/ssoProviders/types"
import { VisitorEmailType } from "../redux/visitor_email_settings/types"

export const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
export const API_ROOT = process.env.REACT_APP_PORTAL_URI
export const APP_URI = process.env.REACT_APP_URI
export const WEB_SOCKET_URI = process.env.REACT_APP_WEB_SOCKET_URI

export const OLD_API = "api/v1.0"
export const DESK_API = "api/2.0/desk"
export const PORTAL_API = "api/2.0/portal"
export const EXPERIMENTS_API = "api/2.0/experiments/json_data"
export const DEVICE_API = "api/2.0/device"
export const REST_API = "rest"

export const OLD_API_ROOT = `${API_ROOT}/${OLD_API}`
export const DESK_API_ROOT = `${API_ROOT}/${DESK_API}`
export const PORTAL_API_ROOT = `${API_ROOT}/${PORTAL_API}`
export const DEVICE_API_ROOT = `${API_ROOT}/${DEVICE_API}`
export const EXPERIMENTS_API_ROOT = `${API_ROOT}/${EXPERIMENTS_API}`
export const REST_API_ROOT = `${API_ROOT}/${REST_API}`

export const VISITORS_API_ROOT = `${PORTAL_API_ROOT}/visitors`

export const SUBSCRIPTIONS_API_ROOT = `${PORTAL_API_ROOT}/subscriptions`

export const ROOMS_API_ROOT = `${PORTAL_API_ROOT}/resources`
export const ROOMS_DEVICES_API_ROOT = `${PORTAL_API_ROOT}/devices`

export const IMAGE_API_ROOT = `${API_ROOT}/api/2.0/desk`

export const webSocketURL = () => `${WEB_SOCKET_URI}/ws/v2`

export const webSocketTabletURL = (id: string, token: string) =>
	`${webSocketURL()}/tablet/${id}/?token=${token}`

export const oAuthCallbackURL = () => `${APP_URI}/auth/callback`

export const AUTH_PARAMS = {
	response_type: "code",
	redirect_uri: oAuthCallbackURL(),
	client_id: CLIENT_ID,
	app_type: "web_desk_booking_app",
	state: "",
}

export const oAuthAuthorizeURL = (redirectUri?: string) => {
	const params = { ...AUTH_PARAMS }
	const { pathname, search, hash } = window.location
	params.state = redirectUri || pathname + search + hash

	return `${API_ROOT}/api/authorize/${queryBuilder(params)}`
}

export const oAuthLogoutURL = () =>
	`${API_ROOT}/account/logout?next=${encodeURIComponent(
		oAuthAuthorizeURL("/"),
	)}`

export const invalidateAuthToken = () => `${DESK_API_ROOT}/logout`

export const tokenURL = () => `${API_ROOT}/api/token/`

export const requestLoginURL = () => `${API_ROOT}/api/2.0/desk/request-login`

export const loginURL = () => `${API_ROOT}/api/2.0/desk/login`

export const meURL = () => `${DESK_API_ROOT}/me/`

export const mePortalURL = () => `${PORTAL_API_ROOT}/me/`

export const featureFlagsURL = () => `${API_ROOT}/rest/features/`

export const deviceURL = () => `${DESK_API_ROOT}/device`

export const companyURL = (cid: string) => `${DESK_API_ROOT}/company/${cid}`
export const updateCompanyURL = () => `${PORTAL_API_ROOT}/company/`

export const usersURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/users/${queryBuilder(params)}`

export const userURL = (email: string) => `${PORTAL_API_ROOT}/users/${email}/`

export const companyConsentURL = (consent_type: string | undefined | null) =>
	`${PORTAL_API_ROOT}/company/consent/${consent_type ? `${consent_type}/` : ""}`

export const companySettingsURL = () =>
	`${PORTAL_API_ROOT}/company/${queryBuilder({
		query: {
			fields: ["settings", "settings_effective"],
		},
	})}`

export const companyPortalURL = (fields: string[] = []) =>
	`${PORTAL_API_ROOT}/company/${queryBuilder({
		query: {
			fields: [
				"name",
				"address",
				"employees",
				"industry",
				"country",
				"meeting_room_count",
				"tools",
				"daily_visitors",
				"latitude",
				"longitude",
				...fields,
			],
		},
	})}`

export const reservationURL = (id: string, params: UrlParameters) =>
	`${DESK_API_ROOT}/reservation/${id}${queryBuilder(params)}`

export const suggestionsURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/desk/reservations/suggestions/${queryBuilder(params)}`

export const checkInsURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/reservations/checkins/${queryBuilder(params)}`

export const recurringReservationsURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/desk/recurring-reservation${queryBuilder(params)}`

export const reservationsURL = (cid: string, params: UrlParameters) =>
	`${companyURL(cid)}/reservation${queryBuilder(params)}`

export const reservationsCSVURL = (params: UrlParameters) =>
	`${DESK_API_ROOT}/reservation/export${queryBuilder(params)}`

export const floorReservationsURL = (cid: string, params: UrlParameters) =>
	`${companyURL(cid)}/reservation${queryBuilder(params)}`

export const resourcesURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/resources/${queryBuilder({
		...params,
		limit: params?.limit ?? FETCH_WITH_NO_LIMIT,
	})}`

export const desksURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/desks/${queryBuilder({
		...params,
		limit: params?.limit ?? FETCH_WITH_NO_LIMIT,
	})}`

export const deskURL = (id: string) => `${PORTAL_API_ROOT}/desks/${id}/`

export const departmentsURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/departments/${queryBuilder({
		...params,
		limit: params?.limit ?? FETCH_WITH_NO_LIMIT,
		ordering: params?.ordering ?? "name",
	})}`

export const departmentURL = (id: string) =>
	`${PORTAL_API_ROOT}/departments/${id}/`

export const floorsURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/buildings/floors/${queryBuilder({
		...params,
		ordering: params?.ordering ?? "name",
	})}`

export const floorURL = (id: string, params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/buildings/floors/${id}/${queryBuilder(params)}`

export const buildingsURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/buildings/${queryBuilder({
		...params,
		ordering: params?.ordering ?? "name",
	})}`

export const buildingsWithVMURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/buildings/${queryBuilder({
		...params,
		ordering: params?.ordering ?? "name",
		has_visitor_management: true,
	})}`

export const buildingURL = (id: string, params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/buildings/${id}/${queryBuilder(params)}`

export const eventsURL = () => `${OLD_API_ROOT}/events/`

export const eventCancelURL = () => `${OLD_API_ROOT}/events/cancel/`

export const eventCheckinURL = () => `${OLD_API_ROOT}/events/checkin/`

export const eventExtendURL = () => `${OLD_API_ROOT}/events/extend/`

export const eventBookURL = () => `${OLD_API_ROOT}/events/book/`

export const slotsURL = () => `${DESK_API_ROOT}/slots/`

export const slotURL = (id: string) => `${DESK_API_ROOT}/slots/${id}/`

export const amenitiesURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/amenities/${queryBuilder(params)}`

export const amenityURL = (id: string) => `${PORTAL_API_ROOT}/amenities/${id}/`

export const ssoProvidersURL = () => `${PORTAL_API_ROOT}/sso/`

export const ssoProviderURL = (provider: SsoProviderType) =>
	`${PORTAL_API_ROOT}/sso/${provider}/`

export const deskFilesURL = () => `${DESK_API_ROOT}/file/`

export const deskFileURL = (id: string) => `${DESK_API_ROOT}/files/${id}/`

export const deskFileImageURL = (src: string, resizeOptions?: UrlParameters) =>
	`${IMAGE_API_ROOT}/${src}${queryBuilder(resizeOptions)}`

export const checkInURL = (loc: string | number) =>
	`${DESK_API_ROOT}/location/${loc}/checkin`

export const checkOutURL = () => `${DESK_API_ROOT}/checkout`

export const experimentsCompanyURL = (id?: string) =>
	`${EXPERIMENTS_API_ROOT}/company/${id ? `${id}/` : ""}`

export const experimentsUserURL = () => `${EXPERIMENTS_API_ROOT}/user/`

export const scimSettingsURL = () => `${API_ROOT}/api/scim/v2/settings/`

export const appointmentsURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/desk/appointments/${queryBuilder(params)}`

export const appointmentURL = (id: string) =>
	`${PORTAL_API_ROOT}/desk/appointments/${id}/`

export const appointmentPinURL = (cid: string, pin: string | number) =>
	`${PORTAL_API_ROOT}/desk/appointments/${cid}/pin/${pin}/`

export const appointmentCheckInURL = (cid: string, id?: string) =>
	`${PORTAL_API_ROOT}/desk/appointments/public/${cid}/${id ? `${id}/` : ""}`

export const employeeScreeningURL = (buildingId: string) =>
	`${PORTAL_API_ROOT}/screenings/employees/${buildingId}/`

export const employeeScreeningURLOLD = () =>
	`${PORTAL_API_ROOT}/desk/screenings/employee/`

export const visitorScreeningURL = (cid: string) =>
	`${PORTAL_API_ROOT}/desk/screenings/visitor/public/${cid}/`

export const configScreeningsURL = () =>
	`${PORTAL_API_ROOT}/desk/screenings/config/`

export const configScreeningURL = (cid: string) =>
	`${PORTAL_API_ROOT}/desk/screenings/config/${cid}/`

export const configScreeningVisitorURL = (cid: string) =>
	`${PORTAL_API_ROOT}/desk/screenings/config/visitor/public/${cid}/`

export const configScreeningEmployeeURL = () =>
	`${PORTAL_API_ROOT}/desk/screenings/config/employee/`

export const exportScreeningURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/desk/screenings/export${queryBuilder(params)}`

export const configScreeningsListURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/screenings/configs/${queryBuilder(params)}`

export const configScreeningsURLNew = (id?: string) =>
	`${PORTAL_API_ROOT}/screenings/configs/${id ? `${id}/` : ""}`

export const configScreeningsDelete = (id: string) =>
	`${PORTAL_API_ROOT}/screenings/configs/${id}/`

export const configScreeningsBulk = () =>
	`${PORTAL_API_ROOT}/screenings/configs/bulk/`

export const configScreeningExportCSV = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/screenings/export/${queryBuilder(params)}`

export const deskReservationsGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/desks/reservations/${queryBuilder(params)}`

export const deskReservationURL = (id?: string, type?: DeleteType) =>
	`${PORTAL_API_ROOT}/desks/reservations/${
		id ? `${id}/${queryBuilder({ type })}` : ""
	}`

export const deskReservationCheckin = (id: string) =>
	`${PORTAL_API_ROOT}/desks/reservations/${id}/checkin/`

export const deskReservationCheckout = (id: string) =>
	`${PORTAL_API_ROOT}/desks/reservations/${id}/checkout/`

export const desksScheduleGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/desks/schedule/${queryBuilder(params)}`

export const shareableAccessLogURL = (sharableId: string) =>
	`${PORTAL_API_ROOT}/shareable/${sharableId}/access_log/`

export const createShareableURL = (type: string) =>
	`${PORTAL_API_ROOT}/shareable/${type}/`
export const shareableDeviceURL = (id: string) =>
	`${DEVICE_API_ROOT}/shareable/${id}/`
export const shareableURL = (id: string) =>
	`${PORTAL_API_ROOT}/shareable/${id}/`

export const assetTypesURL = (id?: string) =>
	`${PORTAL_API_ROOT}/asset-types/${id ? `${id}/` : ""}`

export const assetTypesGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/asset-types/${queryBuilder(params)}`

export const assetsGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/assets/${queryBuilder({
		...params,
		limit: params?.limit ?? FETCH_WITH_NO_LIMIT,
	})}`

export const assetsURL = (id?: string) =>
	`${PORTAL_API_ROOT}/assets/${id ? `${id}/` : ""}`

export const assetsScheduleGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/assets/schedule/${queryBuilder(params)}`

export const assetReservationURL = (param: {
	id?: string
	type?: DeleteType
}) =>
	`${PORTAL_API_ROOT}/assets/reservations/${
		param.id ? `${param.id}/${queryBuilder({ type: param.type })}` : ""
	}`

export const assetReservationCheckin = (id: string) =>
	`${PORTAL_API_ROOT}/assets/reservations/${id}/checkin/`

export const assetReservationCheckout = (id: string) =>
	`${PORTAL_API_ROOT}/assets/reservations/${id}/checkout/`

export const assetReservationsExportURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/assets/reservations/export/${queryBuilder(params)}`

export const assetReservationsGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/assets/reservations/${queryBuilder(params)}`

export const subscriptionsURL = () => `${SUBSCRIPTIONS_API_ROOT}/`

export const subscriptionsGetURL = (params: UrlParameters) =>
	`${SUBSCRIPTIONS_API_ROOT}/${queryBuilder(params)}`

export const subscriptionURL = (id: number) =>
	`${SUBSCRIPTIONS_API_ROOT}/${encodeURIComponent(id)}/`

export const subscriptionsCustomerURL = () =>
	`${SUBSCRIPTIONS_API_ROOT}/customer/`

export const subscriptionsPlansURL = () =>
	`${SUBSCRIPTIONS_API_ROOT}/plans-settings/`

export const subscriptionsEstimatesURL = () =>
	`${SUBSCRIPTIONS_API_ROOT}/estimate/`

export const subscriptionActivationURL = (params: UrlParameters) =>
	`${SUBSCRIPTIONS_API_ROOT}/service-activation/${queryBuilder(params)}`

export const paymentsURL = () => `${PORTAL_API_ROOT}/payments/`

export const paymentsResellerURL = () => `${paymentsURL()}reseller/`

export const paymentsCreditCardURL = () => `${paymentsURL()}credit-card/`

export const paymentsInvoiceURL = () => `${paymentsURL()}invoice/`

export const visitorsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/visitors/${queryBuilder(params)}`

export const visitsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/visits/${queryBuilder(params)}`

export const visitURL = (id: string) => `${VISITORS_API_ROOT}/visits/${id}/`

export const visitsExportURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/visits/export/${queryBuilder(params)}`

export const visitDocumentURL = (id: string) => `${visitURL(id)}document/`

export const visitCheckoutURL = (id: string) => `${visitURL(id)}checkout/`

export const invitesURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/invites/${queryBuilder(params)}`

export const invitesExportURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/invites/export/${queryBuilder(params)}`

export const invitesImportURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/invites/import/${queryBuilder(params)}`

export const inviteURL = (id: string) => `${VISITORS_API_ROOT}/invites/${id}/`

export const inviteCheckinURL = (id: string) => `${inviteURL(id)}checkin/`

export const inviteReinviteURL = (id: string) => `${inviteURL(id)}reinvite/`

export const reprintURL = (id: string) => `${visitURL(id)}print/`

export const visitorSettingsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/settings/${queryBuilder(params)}`

export const visitorSettingURL = (buildingId: string) =>
	`${VISITORS_API_ROOT}/settings/${buildingId}/`

export const visitorSettingUserURL = () => `${VISITORS_API_ROOT}/settings/me/`

export const visitorEmailSettingURL = (
	buildingId: string,
	type: VisitorEmailType,
) => `${VISITORS_API_ROOT}/settings/${buildingId}/emails/${type}/`

export const visitorEmailPreviewURL = (
	building_id: string,
	type: VisitorEmailType,
) => `${visitorEmailSettingURL(building_id, type)}preview/`

export const documentsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/documents/${queryBuilder(params)}`

export const documentsBulkURL = () => `${VISITORS_API_ROOT}/documents/bulk/`

export const documentURL = (id: string) =>
	`${VISITORS_API_ROOT}/documents/${id}/`

export const devicesURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/devices/${queryBuilder(params)}`

export const tabletURL = (id: string) =>
	`${VISITORS_API_ROOT}/devices/tablets/${id}/`

export const tabletAuthenticateURL = () =>
	`${VISITORS_API_ROOT}/devices/tablets/authenticate/`

export const tabletPairURL = () => `${VISITORS_API_ROOT}/devices/tablets/pair/`

export const tabletRepairURL = (id: string) =>
	`${VISITORS_API_ROOT}/devices/tablets/${id}/repair/`

export const tabletUnpairURL = (id: string) =>
	`${VISITORS_API_ROOT}/devices/tablets/${id}/unpair/`

export const printersURL = () => `${VISITORS_API_ROOT}/devices/printers/`

export const printerURL = (id: string) =>
	`${VISITORS_API_ROOT}/devices/printers/${id}/`

export const tabletSettingsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/settings/tablets/${queryBuilder(params)}`

export const tabletSettingURL = (buildingId: string) =>
	`${VISITORS_API_ROOT}/settings/tablets/${buildingId}/`

export const printerSettingsURL = (params?: UrlParameters) =>
	`${VISITORS_API_ROOT}/settings/printers/${queryBuilder(params)}`

export const printerSettingURL = (buildingId: string) =>
	`${VISITORS_API_ROOT}/settings/printers/${buildingId}/`

export const visitorRegistrationURL = (
	buildingId: string,
	tabletId: string,
	params?: UrlParameters,
) =>
	`${VISITORS_API_ROOT}/registration/${buildingId}/${tabletId}/${queryBuilder(
		params,
	)}`

export const visitorRegistrationBrandingURL = (
	buildingId: string,
	tabletId: string,
) => `${visitorRegistrationURL(buildingId, tabletId)}branding/`

export const visitorRegistrationQrURL = (
	buildingId: string,
	tabletId: string,
) => `${visitorRegistrationURL(buildingId, tabletId)}qr/`

export const visitorRegistrationHostsURL = (
	buildingId: string,
	tabletId: string,
	params?: UrlParameters,
) =>
	`${visitorRegistrationURL(buildingId, tabletId)}hosts/${queryBuilder(params)}`

export const visitorRegistrationVisitorsURL = (
	buildingId: string,
	tabletId: string,
	params?: UrlParameters,
) =>
	`${visitorRegistrationURL(buildingId, tabletId)}visitors/${queryBuilder(
		params,
	)}`

export const visitorRegistrationSignatureURL = (registrationId: string) =>
	`${VISITORS_API_ROOT}/registration/${registrationId}/signature/`

export const visitorRegistrationFinishURL = (registrationId: string) =>
	`${VISITORS_API_ROOT}/registration/${registrationId}/finish/`

export const visitorRegistrationSaveURL = (
	buildingId: string,
	tabletId: string,
	registrationId: string,
) => `${visitorRegistrationURL(buildingId, tabletId)}${registrationId}/`

export const visitorRegistrationCheckinURL = (
	buildingId: string,
	tabletId: string,
	registrationId: string,
	params: UrlParameters,
) =>
	`${visitorRegistrationSaveURL(
		buildingId,
		tabletId,
		registrationId,
	)}checkin/${queryBuilder(params)}`

export const visitorRegistrationCheckoutURL = (
	buildingId: string,
	tabletId: string,
) => `${visitorRegistrationURL(buildingId, tabletId)}checkout/`

export const uploadFileCompatURL = () => `${PORTAL_API_ROOT}/file-compat/`

export const peopleScheduleGetURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/people/schedule/${queryBuilder(params)}`

export const invoicesURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/invoices/${queryBuilder(params)}`

export const prepaidCodesURL = () =>
	`${PORTAL_API_ROOT}/subscriptions/prepaid-codes/`

// Rooms
export const roomsURL = (params?: UrlParameters) =>
	`${ROOMS_API_ROOT}/${queryBuilder(params)}`

export const scanRoomsURL = () => `${ROOMS_API_ROOT}/scan/`

export const roomURL = (key: string) => `${ROOMS_API_ROOT}/${key}/`

// Rooms devices
export const roomsDevicesURL = (params?: UrlParameters) =>
	`${ROOMS_DEVICES_API_ROOT}/${queryBuilder(params)}`

export const roomDeviceURL = (id: string) => `${ROOMS_DEVICES_API_ROOT}/${id}/`

export const pairRoomDeviceURL = () => `${ROOMS_DEVICES_API_ROOT}/pair/`

export const roomDeviceRepairURL = () => `${ROOMS_DEVICES_API_ROOT}/re-pair/`
export const servicesURL = () => `${PORTAL_API_ROOT}/subscriptions/services/`

export const serviceActivationURL = (params: UrlParameters) =>
	`${PORTAL_API_ROOT}/subscriptions/service-activation/${queryBuilder(params)}`

export const demoURL = (params?: UrlParameters) =>
	`${PORTAL_API_ROOT}/demo/${queryBuilder(params)}`

export const creditCardURL = () => `${API_ROOT}/rest/subscription/card/`
