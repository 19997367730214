import React, { useEffect, useState } from "react"

import DepartmentModal from "../../modals/DepartmentModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { fetchDepartments } from "../../redux/departments/departmentsSlice"
import { selectDepartments } from "../../redux/departments/selectors"
import { DepartmentResponse, FetchOptions } from "../../redux/departments/types"
import { useAppSelector } from "../../redux/reducers"
import { selectUserPermissions } from "../../redux/user/selectors"
import { useActions } from "../../redux/utils"

import Card from "../../components/Card"
import FilterSpace from "../../components/Filter/FilterSpace"
import Filters from "../../components/Filter/Filters"
import SearchFilter from "../../components/Filter/SearchFilter"
import Heading from "../../components/Heading"
import Intro from "../../components/Intro"
import OccupancyInfo from "../../components/OccupancyInfo"
import Space from "../../components/Space"
import View from "../../components/View"
import Button from "../../components/basic/Button"
import Loader from "../../components/basic/Loader"

import "./Departments.sass"

type DepartmentProps = {
	department: DepartmentResponse
}

function Department({ department }: DepartmentProps) {
	const { openModal } = useModals()

	const permissions = useAppSelector(selectUserPermissions)
	const canEditDepartment = permissions.includes(
		"portal.locations.change_department",
	)

	function handleEditClick() {
		openModal(DepartmentModal, { department })
	}

	return (
		<Card>
			<div className="department">
				<div className="main">
					<div className="data">
						<div className="primary">{department.name}</div>
						<div className="secondary">{department.users?.length} users</div>
					</div>

					{canEditDepartment && (
						<Button onClick={() => handleEditClick()} variant="secondary">
							Edit
						</Button>
					)}
				</div>
				{department && department.stats && department.stats.length > 0 && (
					<div className="statistics">
						<div className="applied">Applied at</div>
						{department.stats.map((stat) => (
							<div key={stat.building_id}>
								{stat.building_name}: {stat.floor_count} floors,{" "}
								{stat.desk_count} desks
							</div>
						))}
					</div>
				)}
				{department &&
					(department.capacity_limit || department.capacity_limit === 0) && (
						<OccupancyInfo type="Department" department={department} />
					)}
			</div>
		</Card>
	)
}

function SettingsDepartments() {
	const { openModal } = useModals()

	const actions = useActions({
		fetchDepartments: (options: FetchOptions) => fetchDepartments(options),
	})

	const [search, setSearch] = useState<string>("")

	const {
		entries: departments,
		isLoaded,
		isLoading,
	} = useAppSelector(selectDepartments)

	const permissions = useAppSelector(selectUserPermissions)
	const canAddDepartment = permissions.includes(
		"portal.locations.add_department",
	)

	function handleNewClick() {
		openModal(DepartmentModal, { department: null })
	}

	// Filter
	useEffect(() => {
		actions.fetchDepartments({
			stats: true,
			search,
		})
	}, [actions, search])

	// Clear all filters
	useEffect(() => {
		return function cleanup() {
			actions.fetchDepartments({ stats: true })
		}
	}, [actions])

	return (
		<View className="SettingsDepartments SettingsPage">
			<Heading>Departments</Heading>

			<Intro isConstrained>
				Define and edit who can sit in each department.
			</Intro>

			<Space size={0.75} />

			<Filters>
				<SearchFilter
					value={search}
					onChange={setSearch}
					placeholder="Filter departments"
				/>
				{isLoading && <Loader size="small" />}

				<FilterSpace />

				{canAddDepartment && (
					<Button onClick={handleNewClick} isSmall>
						Add department
					</Button>
				)}
			</Filters>

			<Space size={0.75} />

			{isLoaded && departments.length > 0 ? (
				departments.map((department: DepartmentResponse) => (
					<Department key={department.id} department={department} />
				))
			) : search.length === 0 ? (
				<Card className="small-font">No departments added yet.</Card>
			) : (
				<Card className="small-font">No departments found.</Card>
			)}
		</View>
	)
}

export default SettingsDepartments
