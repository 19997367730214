import { useCallback } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { useToast } from "./useToast"
import { isRejected } from "@reduxjs/toolkit"

import { useCheckinDeskReservationMutation } from "../redux/api/deskReservations"
import { DeskReservation } from "../redux/api/deskReservations/types"
import {
	isEmployeeScreeningNotApplicable,
	useLazyFetchEmployeeScreeningQuery,
} from "../redux/api/screenings"

type deskCheckinParams = {
	reservation: DeskReservation
	onCheckinCallback?: () => void
	onRedirectionCallback?: () => void
}

export const useDeskCheckIn = () => {
	const [checkinDeskReservation] = useCheckinDeskReservationMutation()
	const [fetchScreening] = useLazyFetchEmployeeScreeningQuery()
	const history = useHistory()
	const { errorToast } = useToast()

	return useCallback(
		async ({
			reservation,
			onCheckinCallback,
			onRedirectionCallback,
		}: deskCheckinParams) => {
			const screening = await fetchScreening(reservation.building.id)

			if (
				isEmployeeScreeningNotApplicable(screening) ||
				screening?.data?.passed === true
			) {
				const response = await checkinDeskReservation(reservation.id)

				if (isRejected(response)) {
					errorToast(response.error.message)
				}

				onCheckinCallback?.()

				return
			}

			if (!isRejected(screening)) {
				history.push(
					`/screening/employee/${
						reservation.building.id
					}?${queryString.stringify({
						id: reservation.id,
					})}`,
				)
				onRedirectionCallback?.()
			}
		},
		[checkinDeskReservation, errorToast, fetchScreening, history],
	)
}
