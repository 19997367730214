import dayjs from "dayjs"

import { RootState } from "./reducers"
import { createSelector } from "@reduxjs/toolkit"

export const getAuth = (state: RootState) => state.auth

export const selectIsAuth = createSelector(
	getAuth,
	({ access_token, expires_on }) => {
		return !!access_token && dayjs(expires_on).isAfter(dayjs())
	},
)

export const selectIsLogin = createSelector(getAuth, (authState) => {
	return authState.isLogin
})
