import { PathToStrings } from "./types/sharedTypes"

import { AppParamsType } from "./redux/appParams/appParamsSlice"

import logoIcon from "./assets/images/logo-icon.svg"
import logoIconWhitelabel from "./assets/images/logo-icon_whitelabel.svg"
import logoLandscape from "./assets/images/logo-landscape.svg"
import logoLandscapeWhitelabel from "./assets/images/logo-landscape_whitelabel.svg"
import logoPortraitWhitelabel from "./assets/images/logo-landscape_whitelabel.svg"
import logoMobileWhitelabel from "./assets/images/logo-landscape_whitelabel.svg"
import logoMobile from "./assets/images/logo-mobile.svg"
import logoPortrait from "./assets/images/logo-portrait.svg"

export const TABLET_LOW_BATTERY_THRESHOLD = 20
export const TABLET_STATUS_REFRESH_TIME = 60 // seconds

export const DEFAULT_NS = "translation"
export const WHITELABEL_NS = "translationWhitelabel"

export const DEFAULT_APP_PARAMS: AppParamsType = {
	isAppWhitelabel: false,
	appName: "Joan",
	appLogo: {
		icon: logoIcon,
		portrait: logoPortrait,
		landscape: logoLandscape,
		mobile: logoMobile,
	},
	appStore: {
		appleStoreURL:
			"https://apps.apple.com/az/app/joan-desk-booking/id1519226753",
		googlePlayURL:
			"https://play.google.com/store/apps/details?id=com.visionect.joan.desk",
	},
	appConfiguratorURL: "https://configurator.getjoan.com/releases/latest/joan",
	appManifest: "/manifest.json",
	appKnowledgeCenter: {
		helpCenter: "https://support.getjoan.com/knowledge",
		howToAddDevices:
			"https://support.getjoan.com/knowledge/how-to-add-your-devices",
		howToConfigureDevices:
			"https://support.getjoan.com/knowledge/how-to-configure-your-devices",
		howToInviteVisitorsFromCalendar:
			"https://support.getjoan.com/knowledge/how-to-invite-visitors-from-your-calendar",
		appPerms: "https://support.getjoan.com/knowledge/how-to-add-devices",
		howToConfigureDesksAndAssets:
			"https://support.getjoan.com/knowledge/how-to-configure-the-desk-booking-app",
	},
}

export const WHITELABEL_APP_PARAMS: AppParamsType = {
	isAppWhitelabel: true,
	appName: "Room Booking",
	appLogo: {
		icon: logoIconWhitelabel,
		portrait: logoPortraitWhitelabel,
		landscape: logoLandscapeWhitelabel,
		mobile: logoMobileWhitelabel,
	},
	// TODO: will need to find out/get links for whitelabel mobile apps
	appStore: {
		appleStoreURL: "https://www.apple.com/app-store/",
		googlePlayURL: "https://play.google.com/store/",
	},
	appConfiguratorURL:
		"https://configurator.getjoan.com/releases/latest/room-booking",
	appManifest: "/manifest-whitelabel.json",
	appKnowledgeCenter: {
		helpCenter: "https://support.roombookingsys.com/hc/en-us",
		howToAddDevices:
			"https://support.roombookingsys.com/hc/en-us/articles/11528419207580-How-to-add-your-devices",
		howToConfigureDevices:
			"https://support.roombookingsys.com/hc/en-us/articles/11528347842076-How-to-configure-your-devices-for-easy-check-in-",
		howToInviteVisitorsFromCalendar:
			"https://support.roombookingsys.com/hc/en-us/articles/11528684283164-How-to-invite-Visitors-from-your-Calendar-",
		appPerms:
			"https://support.roombookingsys.com/hc/en-us/articles/11528755497372-Room-booking-Office-permissions-in-the-application",
		howToConfigureDesksAndAssets:
			"https://support.roombookingsys.com/hc/en-us/articles/11529264949660-How-to-configure-the-Room-booking-Office-system",
	},
}

export const IS_WHITE_LABEL = process.env.REACT_APP_MOBILE_MODE === "WHITELABEL"

export const FETCH_WITH_NO_LIMIT = 10000

export const COUNTRIES: Record<string, string> = {
	AF: "Afghanistan",
	AX: "Aland Islands",
	AL: "Albania",
	DZ: "Algeria",
	AS: "American Samoa",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarctica",
	AG: "Antigua and Barbuda",
	AR: "Argentina",
	AM: "Armenia",
	AW: "Aruba",
	AU: "Australia",
	AT: "Austria",
	AZ: "Azerbaijan",
	BS: "Bahamas",
	BH: "Bahrain",
	BD: "Bangladesh",
	BB: "Barbados",
	BY: "Belarus",
	BE: "Belgium",
	BZ: "Belize",
	BJ: "Benin",
	BM: "Bermuda",
	BT: "Bhutan",
	BO: "Bolivia",
	BA: "Bosnia and Herzegovina",
	BW: "Botswana",
	BV: "Bouvet Island",
	BR: "Brazil",
	IO: "British Indian Ocean Territory",
	BN: "Brunei Darussalam",
	BG: "Bulgaria",
	BF: "Burkina Faso",
	BI: "Burundi",
	KH: "Cambodia",
	CM: "Cameroon",
	CA: "Canada",
	CV: "Cape Verde",
	KY: "Cayman Islands",
	CF: "Central African Republic",
	TD: "Chad",
	CL: "Chile",
	CN: "China",
	CX: "Christmas Island",
	CC: "Cocos (Keeling) Islands",
	CO: "Colombia",
	KM: "Comoros",
	CG: "Congo",
	CD: "Congo, The Democratic Republic of the",
	CK: "Cook Islands",
	CR: "Costa Rica",
	CI: "Cote d'Ivoire",
	HR: "Croatia",
	CU: "Cuba",
	CY: "Cyprus",
	CZ: "Czech Republic",
	DK: "Denmark",
	DJ: "Djibouti",
	DM: "Dominica",
	DO: "Dominican Republic",
	EC: "Ecuador",
	EG: "Egypt",
	SV: "El Salvador",
	GQ: "Equatorial Guinea",
	ER: "Eritrea",
	EE: "Estonia",
	ET: "Ethiopia",
	FK: "Falkland Islands (Malvinas)",
	FO: "Faroe Islands",
	FJ: "Fiji",
	FI: "Finland",
	FR: "France",
	GF: "French Guiana",
	PF: "French Polynesia",
	TF: "French Southern Territories",
	GA: "Gabon",
	GM: "Gambia",
	GE: "Georgia",
	DE: "Germany",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Greece",
	GL: "Greenland",
	GD: "Grenada",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernsey",
	GN: "Guinea",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HT: "Haiti",
	HM: "Heard Island and McDonald Islands",
	VA: "Holy See (Vatican City State)",
	HN: "Honduras",
	HK: "Hong Kong",
	HU: "Hungary",
	IS: "Iceland",
	IN: "India",
	ID: "Indonesia",
	IR: "Iran, Islamic Republic of",
	IQ: "Iraq",
	IE: "Ireland",
	IM: "Isle of Man",
	IL: "Israel",
	IT: "Italy",
	JM: "Jamaica",
	JP: "Japan",
	JE: "Jersey",
	JO: "Jordan",
	KZ: "Kazakhstan",
	KE: "Kenya",
	KI: "Kiribati",
	KP: "Korea, Democratic People's Republic of",
	KR: "Korea, Republic of",
	KW: "Kuwait",
	KG: "Kyrgyzstan",
	LA: "Lao People's Democratic Republic",
	LV: "Latvia",
	LB: "Lebanon",
	LS: "Lesotho",
	LR: "Liberia",
	LY: "Libyan Arab Jamahiriya",
	LI: "Liechtenstein",
	LT: "Lithuania",
	LU: "Luxembourg",
	MO: "Macao",
	MK: "Macedonia, The Former Yugoslav Republic of",
	MG: "Madagascar",
	MW: "Malawi",
	MY: "Malaysia",
	MV: "Maldives",
	ML: "Mali",
	MT: "Malta",
	MH: "Marshall Islands",
	MQ: "Martinique",
	MR: "Mauritania",
	MU: "Mauritius",
	YT: "Mayotte",
	MX: "Mexico",
	FM: "Micronesia, Federated States of",
	MD: "Moldova",
	MC: "Monaco",
	MN: "Mongolia",
	ME: "Montenegro",
	MS: "Montserrat",
	MA: "Morocco",
	MZ: "Mozambique",
	MM: "Myanmar",
	NA: "Namibia",
	NR: "Nauru",
	NP: "Nepal",
	NL: "Netherlands",
	AN: "Netherlands Antilles",
	NC: "New Caledonia",
	NZ: "New Zealand",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	NF: "Norfolk Island",
	MP: "Northern Mariana Islands",
	NO: "Norway",
	OM: "Oman",
	PK: "Pakistan",
	PW: "Palau",
	PS: "Palestinian Territory, Occupied",
	PA: "Panama",
	PG: "Papua New Guinea",
	PY: "Paraguay",
	PE: "Peru",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Poland",
	PT: "Portugal",
	PR: "Puerto Rico",
	QA: "Qatar",
	RE: "Reunion",
	RO: "Romania",
	RU: "Russian Federation",
	RW: "Rwanda",
	BL: "Saint Barthelemy",
	SH: "Saint Helena",
	KN: "Saint Kitts and Nevis",
	LC: "Saint Lucia",
	MF: "Saint Martin",
	PM: "Saint Pierre and Miquelon",
	VC: "Saint Vincent and the Grenadines",
	WS: "Samoa",
	SM: "San Marino",
	ST: "Sao Tome and Principe",
	SA: "Saudi Arabia",
	SN: "Senegal",
	RS: "Serbia",
	SC: "Seychelles",
	SL: "Sierra Leone",
	SG: "Singapore",
	SK: "Slovakia",
	SI: "Slovenia",
	SB: "Solomon Islands",
	SO: "Somalia",
	ZA: "South Africa",
	GS: "South Georgia and the South Sandwich Islands",
	ES: "Spain",
	LK: "Sri Lanka",
	SD: "Sudan",
	SR: "Suriname",
	SJ: "Svalbard and Jan Mayen",
	SZ: "Swaziland",
	SE: "Sweden",
	CH: "Switzerland",
	SY: "Syrian Arab Republic",
	TW: "Taiwan, Province of China",
	TJ: "Tajikistan",
	TZ: "Tanzania, United Republic of",
	TH: "Thailand",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinidad and Tobago",
	TN: "Tunisia",
	TR: "Turkey",
	TM: "Turkmenistan",
	TC: "Turks and Caicos Islands",
	TV: "Tuvalu",
	UG: "Uganda",
	UA: "Ukraine",
	AE: "United Arab Emirates",
	GB: "United Kingdom",
	US: "United States",
	UM: "United States Minor Outlying Islands",
	UY: "Uruguay",
	UZ: "Uzbekistan",
	VU: "Vanuatu",
	VE: "Venezuela",
	VN: "Viet Nam",
	VG: "Virgin Islands, British",
	VI: "Virgin Islands, U.S.",
	WF: "Wallis and Futuna",
	EH: "Western Sahara",
	YE: "Yemen",
	ZM: "Zambia",
	ZW: "Zimbabwe",
}

export const COUNTRY_OPTIONS = Object.entries(COUNTRIES).map(
	([key, value]) => ({
		value: key,
		label: value,
	}),
)

export const PRIMARY_COLOR = "#4205DD"

export const HOME_PATHS = {
	buildingSelect: "/building-selection",
	desktop: "/manage",
	mobile: "/home/reservations",
	visitor: "/visitors",
}

/**
 * Defines the constant labels used in the application
 * LABELS and WHITE_LABEL_LABELS are linked by Labels type this
 * means that they should have the same structure
 */
export const LABELS = {
	links: {
		termsOfUseURL: "https://getjoan.com/terms-of-use/",
		privacyPolicyURL: "https://getjoan.com/privacy-policy/",
		supportEmail: "support@getjoan.com",
		deskBookingInstructionsURL:
			"https://support.getjoan.com/knowledge/instructions-for-joan-desk-booking-app-users",
		supportFormURL:
			"https://share-eu1.hsforms.com/1W8drJxQeREyRfQ3GU3yQFQf39l1",
	},
	deviceTypes: {
		joan_6: "Joan 6",
		joan_6_pro: "Joan 6 Pro",
		joan_6_re: "Joan 6 RE",
		joan_9: "Joan 9",
		joan_13: "Joan 13",
		joan_32: "Joan 32",
		joan_on_display: "Joan on Display",
		joan_on_tablets: "Joan on Tablets",
	},
}

export const WHITE_LABEL_LABELS: Labels = {
	links: {
		termsOfUseURL: "https://getjoan.com/re/terms-of-use/",
		privacyPolicyURL: "https://getjoan.com/re/privacy-policy/",
		supportEmail: "pimsil.helpdesk@in.panasonic.com",
		// TODO: will need to find out/get links for whitelabel
		deskBookingInstructionsURL:
			"https://support.getjoan.com/knowledge/instructions-for-joan-desk-booking-app-users",
		supportFormURL:
			"https://share-eu1.hsforms.com/1W8drJxQeREyRfQ3GU3yQFQf39l1",
	},
	deviceTypes: {
		joan_6: "6 ″ Device",
		joan_6_pro: "6 ″ Pro Device",
		joan_6_re: "6 ″ RE Device",
		joan_9: "9 ″ Device",
		joan_13: "13 ″ Device",
		joan_32: "32 ″ Device",
		joan_on_display: "Room Booking on Display",
		joan_on_tablets: "Room Booking on Tablets",
	},
}

export type LabelPaths = PathToStrings<typeof LABELS>
export type Labels = typeof LABELS
