import { ObjectToUnion } from "../../types/sharedTypes"
import { DepartmentResponse } from "../departments/types"
import { PaginatedOptions } from "../reduxUtils"

export type UserResponse = {
	id: string
	email: string
	first_name?: string
	last_name?: string
	phone?: string
	building_id?: string
	last_login?: string
	groups?: string[]
	departments?: Pick<
		DepartmentResponse,
		"id" | "name" | "capacity_limit" | "active"
	>[]
	license?: License | null
}

export type License = {
	subscription_id: string
	assigned_at?: string
	expires_at?: string
}

export type UserRequest = {
	first_name: string
	last_name: string
	email?: string
	phone?: string
	building_id?: string
	groups?: string[]
	departments?: string[]
}

export type CSVEntry = {
	email?: string
	first_name?: string
	last_name?: string
}

export type UsersResponse = {
	offset: number
	count: number
	search: string
	results: UserResponse[]
}

export type UserConfigAction = {
	type: string
	payload: string | UsersResponse | UserResponse | UserResponse[]
}

export type FetchOptions = PaginatedOptions & {
	search?: string
	role_id?: string
	department_id?: string
	license?: LicenseAssignment
}

export const LICENSE_ASSIGNMENT = {
	ASSIGNED: "assigned",
	UNASSIGNED: "unassigned",
} as const

export type LicenseAssignment = ObjectToUnion<typeof LICENSE_ASSIGNMENT>

export const isLicenseFilter = (value: string): value is LicenseAssignment =>
	Object.values(LICENSE_ASSIGNMENT).includes(value as any)
