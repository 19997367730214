import React, { useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { NavLink, useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { ReservationCheckinUtils } from "../../../checkin_utils"
import { useDeskCheckIn } from "../../../hooks/useDeskCheckIn"
import { useToast } from "../../../hooks/useToast"
import { useAnalyticsScreenView } from "../../../providers/Mobile/FirebaseAnalyticsProvider"
import { shortUserTimeFormat, toInternalTime } from "../../../utils"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import {
	useCheckoutDeskReservationMutation,
	useDeleteDeskReservationMutation,
	useFetchDeskReservationQuery,
} from "../../../redux/api/deskReservations"
import { useFetchDeskQuery } from "../../../redux/api/desks"
import { useAppSelector } from "../../../redux/reducers"
import { DeleteType } from "../../../redux/reservations/types"
import { selectSettingsEffective } from "../../../redux/settings/selectors"

import { CorrectIconMapper } from "../../../components/CorrectIconMapper"
import { Drawer } from "../../../components/Mobile/Drawer"
import { ReservationDeleteMenu } from "../../../components/Mobile/ReservationDeleteMenu"
import { ReservationOptionMenu } from "../../../components/Mobile/ReservationOptionMenu"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import { ConfirmationModal } from "../../../components/advanced/ConfirmationModal"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as ClockSVG } from "../../../assets/images/icons/Clock.svg"
import { ReactComponent as MapsSVG } from "../../../assets/images/icons/Maps.svg"
import { ReactComponent as MoreHorizontalSVG } from "../../../assets/images/icons/More horizontal.svg"
import { ReactComponent as WorldSVG } from "../../../assets/images/icons/World2.svg"

import "./ReservationDetail.sass"

type ParamsType = {
	id: string
}

const ReservationDetail: React.FC = () => {
	useAnalyticsScreenView("Home/ReservationDetail")

	const { t } = useTranslation()
	const { id } = useParams<ParamsType>()
	const history = useHistory()
	const { openModal, closeModal } = useModals()

	const {
		data: reservation,
		isSuccess,
		isError,
	} = useFetchDeskReservationQuery(id ?? skipToken)
	const { data: desk } = useFetchDeskQuery(reservation?.desk.id ?? skipToken)
	const [checkoutDeskReservation] = useCheckoutDeskReservationMutation()
	const [deleteDeskReservation] = useDeleteDeskReservationMutation()
	const checkinDesk = useDeskCheckIn()

	const [openMenu, setOpenMenu] = useState(false)
	const [openDeleteMenu, setOpenDeleteMenu] = useState(false)

	const { errorToast } = useToast()

	const { entry: settings } = useAppSelector(selectSettingsEffective)

	const { desk_check_in_enabled } = settings ?? {}

	const validReservation =
		reservation && reservation.desk && reservation.floor && reservation.building

	const building = reservation && validReservation ? reservation.building : null
	const floor = reservation && validReservation ? reservation.floor : null

	const hasCheckin = reservation?.checked_in?.check_in_at
	const hasCheckout = reservation?.checked_in?.check_out_at

	const handleNoReservation = () => {
		toast(t("mobile.general.reservation_not_exist"))
		history.push("/home/reservations")
	}

	const handleEdit = () => {
		if (!reservation) {
			handleNoReservation()

			return
		}
		if (reservation.end && reservation.start) {
			history.push("/book/desk/summary", {
				id: reservation.id,
				date: dayjs(reservation.start).format("YYYY-MM-DD"),
				timeslot: {
					from: toInternalTime(reservation.start),
					to: toInternalTime(reservation.end),
				},
				building: reservation.building,
				floor: reservation.floor,
				desk: reservation.desk,
			})
		}
	}

	const handleDeleteOption = async () => {
		setOpenMenu(false)

		if (!reservation) {
			handleNoReservation()

			return
		}

		if (reservation.recurring) {
			setOpenDeleteMenu(true)
		} else {
			handleDeleteConfirmation(DeleteType.CURRENT)
		}
	}

	const handleDelete = async (type: DeleteType) => {
		const response = await deleteDeskReservation({ id, type })

		if (isRejected(response)) {
			errorToast(response.error.message)

			return
		}

		closeModal()
		history.push("/home/reservations")

		return
	}

	const handleDeleteConfirmation = (type: DeleteType) => {
		setOpenDeleteMenu(false)
		openModal(ConfirmationModal, {
			onConfirm: () => handleDelete(type),
			title: t("mobile.home.delete_desk_reservation_title"),
			hint: t("mobile.home.delete_desk_reservation_hint"),
		})
	}

	const canCheckIn = ReservationCheckinUtils.isEnabled(
		"checkin",
		settings,
		undefined,
		reservation,
	)
	const canCheckOut = ReservationCheckinUtils.isEnabled(
		"checkout",
		settings,
		undefined,
		reservation,
	)

	const actionClicked = (type: "checkin" | "checkout") => {
		let title = t("mobile.home.confirm_checkin")
		let func = handleCheckIn

		if (type === "checkout") {
			title = t("mobile.home.confirm_checkout")
			func = handleCheckOut
		}

		openModal(ConfirmationModal, {
			title,
			onConfirm: () => {
				func()
				closeModal()
			},
		})
	}

	const handleCheckOut = async () => {
		if (!reservation) return

		const response = await checkoutDeskReservation(reservation.id)

		if (isRejected(response)) {
			errorToast(response.error.message)
			return
		}
	}

	const handleCheckIn = () => reservation && checkinDesk({ reservation })

	return (
		<SafeViewArea className="ReservationDetail">
			<div className="head">
				<TopNav
					rightIcon={
						<MoreHorizontalSVG
							onClick={() => {
								setOpenMenu(true)
							}}
						/>
					}
				/>
				<div className="icon">
					<div>
						<CorrectIconMapper iconType="desk" needsWrap={false} />
					</div>
				</div>
			</div>
			<div className="body">
				{isSuccess && reservation && (
					<>
						<div className="main-data">
							<div className="info">
								<div className="type">{t("mobile.general.desk")}</div>
								<div className="status">{t("mobile.home.reserved")}</div>
							</div>
							<div className="name">{reservation.desk.name}</div>
							<div className="location">
								{building ? `${building.name}, ${floor?.name}` : ""}
							</div>
						</div>
						<div className="details">
							<div className="detail-row">
								<CalendarSVG />
								<div>{dayjs(reservation.start).format("dddd, MMM D")}</div>
							</div>
							<div className="detail-row">
								<ClockSVG />
								<div>
									{dayjs(reservation.start).format(shortUserTimeFormat())}
									{" - "}
									{dayjs(reservation.end).format(shortUserTimeFormat())}
								</div>
							</div>
							<div className="detail-row">
								<WorldSVG />
								<div>{reservation.tz}</div>
							</div>
							{desk?.amenities && desk.amenities.length > 0 && (
								<div className="detail-row">
									<CorrectIconMapper iconType="asset" needsWrap={false} />
									<div>
										{desk.amenities
											.reduce((text, a) => `${text}, ${a.name}`, "")
											.replace(", ", "")}
									</div>
								</div>
							)}
							<div className="detail-row">
								<NavLink to={"/home/reservation/" + reservation.id + "/map"}>
									<MapsSVG />
									<div>{t("mobile.home.show_on_map")}</div>
								</NavLink>
							</div>
							{desk_check_in_enabled && (
								<>
									{hasCheckin && (
										<div className="detail-row">
											{t("mobile.general.checked_in")}{" "}
											{dayjs(reservation.checked_in?.check_in_at).format(
												shortUserTimeFormat(),
											)}
											{hasCheckout && (
												<>
													<br />
													{t("mobile.general.checked_out")}{" "}
													{dayjs(reservation.checked_in?.check_out_at).format(
														shortUserTimeFormat(),
													)}
												</>
											)}
										</div>
									)}
									{(canCheckIn || canCheckOut) && (
										<div className="actions-row">
											{canCheckIn && (
												<Button
													variant="link"
													className="action"
													onClick={() => actionClicked("checkin")}
												>
													{t("mobile.general.check_in")}
												</Button>
											)}
											{canCheckOut && (
												<Button
													variant="link"
													className="action"
													onClick={() => actionClicked("checkout")}
												>
													{t("mobile.general.check_out")}
												</Button>
											)}
										</div>
									)}
								</>
							)}
						</div>
					</>
				)}
				{isError && (
					<div className="no-data">
						<div className="reason">
							{t("mobile.general.reservation_not_exist")}
						</div>
						<Button onClick={() => history.push("/home/reservations")}>
							{t("mobile.navigation.home")}
						</Button>
					</div>
				)}
				{!isSuccess && (
					<div className="loading">
						<Loader variant="fullScreen" />
					</div>
				)}
			</div>
			<Drawer open={openMenu}>
				<ReservationOptionMenu
					onEdit={handleEdit}
					onDelete={handleDeleteOption}
					onClose={() => {
						setOpenMenu(false)
					}}
				/>
			</Drawer>
			<Drawer open={openDeleteMenu}>
				<ReservationDeleteMenu
					onClick={handleDeleteConfirmation}
					onClose={() => {
						setOpenDeleteMenu(false)
					}}
				/>
			</Drawer>
		</SafeViewArea>
	)
}

export default ReservationDetail
