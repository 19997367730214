import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../hooks/useToast"
import FloorDeleteModal from "../../modals/FloorDeleteModal"
import { calculatePercentAmount } from "../../utils"
import Field from "../Field"
import { LimitOccupancyDecider } from "../advanced/LimitOccupancyDecider"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	useCreateFloorMutation,
	useUpdateFloorMutation,
} from "../../redux/api/floors"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { FloorResponse } from "../../redux/floors/types"

import "./FloorForm.sass"

type Props = {
	buildingId: string
	floor: FloorResponse | null
}

type FormValues = {
	name: string
	capacity_limit: number | null
	active: boolean
}

const FloorForm = ({ buildingId, floor }: Props) => {
	const { openModal, closeModal } = useModals()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { id, name, active, capacity_limit } = floor ?? {}

	const [createFloor] = useCreateFloorMutation()
	const [updateFloor] = useUpdateFloorMutation()

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? "",
			capacity_limit: capacity_limit ?? null,
			active: floor ? active : true,
		},
	})

	const {
		setError,
		control,
		getValues,
		formState: { isSubmitting },
	} = methods

	const onCreateClick = useCallback(
		async ({ name, capacity_limit, active }: FormValues) => {
			const response = await createFloor({
				building_id: buildingId,
				name,
				capacity_limit,
				image: null,
				height: null,
				width: null,
				active,
			})

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
				}
			} else {
				infoToast(
					t("desktop.settings.floor_plans.floor_form.floor_created_toast"),
				)
				closeModal()
			}
		},
		[createFloor, buildingId, setError, errorToast, infoToast, t, closeModal],
	)

	const onUpdateClick = useCallback(
		async ({ name, capacity_limit, active }: FormValues) => {
			if (id) {
				const { image, height, width } = floor ?? {}
				const response = await updateFloor({
					id,
					building_id: buildingId,
					name,
					capacity_limit,
					image: image,
					height: height,
					width: width,
					active,
				})

				if (isRejected(response)) {
					const { error } = response

					if (isApiResponseError(error)) {
						setErrors(error.formError, setError, errorToast)
					}

					return
				} else {
					infoToast(
						t("desktop.settings.floor_plans.floor_form.floor_updated_toast"),
					)
					closeModal()
				}
			}
		},
		[
			id,
			floor,
			updateFloor,
			buildingId,
			setError,
			errorToast,
			infoToast,
			t,
			closeModal,
		],
	)

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			if (floor !== null) {
				openModal(FloorDeleteModal, { floor })
			}
		},
		[openModal, floor],
	)

	const totalDeskNo = floor?.desks_count ?? 0
	const activeDeskAmount = calculatePercentAmount(
		totalDeskNo,
		getValues().capacity_limit ?? 100,
	)

	const updateMode = !!id

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="FloorForm"
				updateMode={updateMode}
				title={
					updateMode
						? t("desktop.settings.floor_plans.floor_form.edit_floor")
						: t("desktop.settings.floor_plans.floor_form.new_floor")
				}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
				hasConfirmationPrompt={false}
			>
				<Field
					control={control}
					name={"name"}
					label={t(
						"desktop.settings.floor_plans.floor_form.floor_name_or_number",
					)}
				>
					{(props) => (
						<Input
							autoFocus
							maxLength={100}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field control={control} name="capacity_limit">
					{(props) => (
						<LimitOccupancyDecider
							{...props}
							label={t(
								"desktop.settings.floor_plans.floor_form.floor_occupancy_limit",
							)}
							infoText={t(
								"desktop.settings.floor_plans.floor_form.no_of_bookable_desks",
								{ activeDeskAmount, totalDeskNo },
							)}
						/>
					)}
				</Field>
				<Field control={control} name="active">
					{(props) => (
						<Switch
							{...props}
							label={t("desktop.settings.floor_plans.floor_form.enable_floor")}
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default FloorForm
