import { ThunkApiConfig } from "RootType"

import { buildingsURL, get } from "../../api"
import { getErrorMessage } from "../reduxUtils"
import { BuildingsResponse, FetchOptions } from "./types"
import { createAsyncThunk } from "@reduxjs/toolkit"

const FETCH_DEFAULTS = {
	stats: false,
}

/**
 *  Thunks
 */

/*
	This call isn't affecting global state with the exception of logging the user out.
	It's only used for information gathering on component level.

	This call is used in floorsSlice
*/
export const fetchBuildingsOnly = createAsyncThunk<
	BuildingsResponse,
	FetchOptions,
	ThunkApiConfig
>("buildings/fetchOnly", async (options, { getState }) => {
	const { access_token } = getState().auth

	const response = await get(
		buildingsURL({
			...FETCH_DEFAULTS,
			...options,
			query: {
				fields: ["*", "settings"],
			},
		}),
		{},
		access_token,
	)

	if (response.ok) {
		const json = await response.json()

		return json
	}

	throw new Error(await getErrorMessage(response))
})
