import { PropsWithChildren, createContext, useState } from "react"

import { Error } from "../../screens/Visitors/Error"

import { selectError } from "../../redux/app/selectors"
import { useAppSelector } from "../../redux/reducers"

export type ErrorContextType = {
	setError: (error?: string) => void
	stateError?: string
}

export const ErrorContext = createContext<ErrorContextType>({
	setError: (error?: string) => {},
	stateError: undefined,
})

export const ErrorHandlerProvider = ({
	children,
}: PropsWithChildren<unknown>) => {
	const [stateError, setError] = useState<string | undefined>(undefined)
	const error = useAppSelector(selectError)

	return (
		<ErrorContext.Provider value={{ setError, stateError }}>
			{stateError ? (
				<Error error={stateError} />
			) : error ? (
				<Error error={error} />
			) : (
				<>{children}</>
			)}
		</ErrorContext.Provider>
	)
}
