import { useCallback, useEffect, useMemo, useRef } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"

import { timeZone } from "../../../dayjs"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import {
	DESKS_SCHEDULE_PATHNAME,
	DESK_FILTERS_STORE_NAME,
	ENTRIES_PER_PAGE,
	VISITORS_INVITES_PATHNAME,
	VISITORS_VISITS_PATHNAME,
} from "../constants"
import { DeskRowHeading } from "./DeskRowHeading"
import DeskScheduleCell from "./DeskScheduleCell"
import DeskFilters, { Filter } from "./Filters"
import Header from "./Header"
import { useModals } from "@mattjennings/react-modal-stack"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { fetchDesksSchedule } from "../../../redux/desk_schedule/deskScheduleSlice"
import { selectDeskSchedule } from "../../../redux/desk_schedule/selectors"
import {
	DeskScheduleEntry,
	DeskScheduleReservation,
} from "../../../redux/desk_schedule/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/Card"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import { ReservationSummary } from "../../../components/Manage/ReservationSummary"
import ReservationTable, {
	ReservationTableRow,
	TableDataEntryDate,
} from "../../../components/Manage/ReservationTable"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"
import Loader from "../../../components/basic/Loader"

import "./style.sass"

const { stringify } = queryString

export type ScheduleDeskDataRow = Pick<DeskScheduleEntry, "id" | "name">

type Params = {
	id?: string
}

type Props = {
	add?: boolean
}

const ManageDesks = ({ add }: Props) => {
	const { fromDate, toDate, showWeekends } = useAppSelector(selectAppDates)
	const startDate = useRef(fromDate.toISOString())
	const { entry: user } = useAppSelector(selectUser)
	const { offset, schedule, count, isLoading, isLoaded, error, aggregation } =
		useAppSelector(selectDeskSchedule)

	const desk_reservation_window_length = useCheckReservationWindowLength({
		globalSettings: true,
	})

	const defaultFilter: Filter = {
		department_id: FilterSpecialValues.ALL,
		building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
		floor_id: FilterSpecialValues.ALL,
		amenity_id: [FilterSpecialValues.ALL],
		search: "",
		page: 1,
	}

	const [storedFilterValues, saveFilter] = useStoredFilter({
		filterName: DESK_FILTERS_STORE_NAME,
		defaultFilterValues: defaultFilter,
	})

	const reqParams = useRef<Filter>(storedFilterValues)
	/**
	 * State
	 */

	const isAdminManager = isOfficeManager(user) || isPortalAdmin(user)
	const lastDay = dayjs()
		.startOf("date")
		.add(desk_reservation_window_length ?? 7, "day")
	const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")
	const manageDesksClassName = classNames("ManageDesks", {
		showWeekends: !!showWeekends,
	})

	/**
	 * Hooks
	 */
	const {
		params: { id },
	} = useRouteMatch<Params>()
	const { openModal, closeAllModals } = useModals()
	const { t } = useTranslation()

	const actions = useActions({
		toggleWeekends: () => toggleWeekends(),
		fetchDesksSchedule: (
			filter: Filter & { offset?: number; start: string; end: string },
		) => {
			const { page, ...params } = filter

			return fetchDesksSchedule({
				...params,
				limit: ENTRIES_PER_PAGE,
			})
		},
	})
	const history = useHistory()
	const { search, pathname } = history.location
	const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
		useBackendPagination({
			offset,
			totalNumberOfItems: count,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 7,
			maxTrailingLinks: 2,
		})

	const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
		reqParams: reqParams,
		path: DESKS_SCHEDULE_PATHNAME,
		fetchCall: actions.fetchDesksSchedule,
		fetchOptions: { start: fromDate.toISOString(), end: toDate.toISOString() },
		offset,
	})

	/**
	 * Function and memo
	 */

	const handleFilterChange = useCallback(
		async (filter: Filter) => {
			reqParams.current = { ...filter, page: 1 }
			updateHistory(pathname, reqParams.current)
			fetchDataPagination()
		},
		[fetchDataPagination, pathname],
	)

	const handleModalClose = () => {
		history.push(DESKS_SCHEDULE_PATHNAME)
	}

	const tableData: ReservationTableRow<
		ScheduleDeskDataRow,
		DeskScheduleReservation & TableDataEntryDate
	>[] = useMemo(
		() =>
			schedule?.flatMap((d) => ({
				header: {
					id: d.id,
					name: d.name,
				},
				data: d.schedule.flatMap((s) =>
					s.reservations.map((r) => ({
						...r,
						date: s.day,
					})),
				),
			})),

		[schedule],
	)

	const addReservation = (params: Record<string, any> = {}) => {
		history.push({
			pathname: `${DESKS_SCHEDULE_PATHNAME}/add`,
			search: stringify(params),
		})
	}

	const editReservation = ({
		id,
		visit_id,
		checked_in,
	}: DeskScheduleReservation) => {
		if (!visit_id) {
			history.push(`${DESKS_SCHEDULE_PATHNAME}/${id}`)
			return
		}
		history.push(
			`${
				checked_in ? VISITORS_VISITS_PATHNAME : VISITORS_INVITES_PATHNAME
			}/edit/${visit_id}`,
		)
	}

	/**
	 * Main routing logic for create and edit modal views.
	 **/
	useEffect(() => {
		if (add) {
			const queryFormData = parseQueryWithDefault(search, {
				start: "",
				end: "",
				date: dayjs().toString(),
				schedule: "Once",
				desk: "",
				deskName: "",
				scheduleDate: dayjs()
					.add(desk_reservation_window_length ?? 7, "day")
					.toString(),
				tz: timeZone,
			})

			openModal(DeskBookingModal, {
				formData: {
					...queryFormData,
					date: dayjs(queryFormData.date),
					schedule: createScheduleOption(
						queryFormData.schedule,
						queryFormData.date,
					),
					scheduleDate: dayjs(queryFormData.scheduleDate),
				},
				onClose: handleModalClose,
			})
		} else if (id) {
			openModal(DeskBookingModal, {
				reservationId: id,
				onClose: handleModalClose,
			})
		} else {
			updateHistory(pathname, reqParams.current)
			fetchDataPagination()
			closeAllModals()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [add, id, search])

	// saves the current params into the locale store
	useEffect(
		() => () => {
			if (pathname.endsWith(DESKS_SCHEDULE_PATHNAME)) {
				saveFilter(reqParams.current)
			}
		},
		[saveFilter, pathname],
	)

	// it will navigate to the first page when there is no results on higher pages
	useEffect(() => {
		if (isLoaded && count > 0 && schedule.length === 0 && page > 1) {
			setPage(1)
		}
	}, [count, isLoaded, page, schedule.length, setPage])

	useEffect(() => {
		const start = fromDate.toISOString()

		if (startDate.current !== start) {
			fetchDataPagination()
			startDate.current = start
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate])

	const isPaymentRequiredError = error && error.startsWith("Payment required")

	return (
		<View className={manageDesksClassName}>
			<Header
				fromDate={fromDate}
				toDate={toDate}
				weekEnd={weekEnd}
				showWeekends={showWeekends}
				showExportButton={isAdminManager}
				toggleWeekends={actions.toggleWeekends}
				onNewReservation={addReservation}
			/>

			<Space size={0.25} />

			<DeskFilters
				onChange={handleFilterChange}
				defaultValues={storedFilterValues}
			/>
			<Space size={0.5} />
			{error && !isPaymentRequiredError && (
				<>
					<Card className="small-font card-error">
						{t("desktop.manage.desk_booking.reservations_loading_error")}
					</Card>
					<Space size={0.5} />
				</>
			)}
			{!isLoaded ? (
				<Loader />
			) : (
				<ReservationTable
					showSummary
					isLoading={isLoading}
					weekStartDay={fromDate}
					entries={tableData}
					showWeekends={showWeekends}
					renderHead={({ row, index }) => (
						<DeskRowHeading
							deskRow={row}
							rowNumber={calcRowNumber(index)}
							key={`head-${index}`}
						/>
					)}
					renderCell={({ data, day, row, rowIndex }) => (
						<DeskScheduleCell
							day={day}
							header={row}
							schedule={data}
							onClick={editReservation}
							rowIndex={rowIndex}
							renderVacancy={({ hasAvailableSlots, day }) => {
								const isBefore = dayjs(day).subtract(1, "day").isBefore(lastDay)
								return (
									<>
										{hasAvailableSlots && (isBefore || isAdminManager) && (
											<button
												className="ReservationAvailable"
												onClick={() => {
													addReservation({
														date: day,
														desk: row.id,
														deskName: row.name,
													})
												}}
											>
												{t("desktop.manage.desk_booking.available")}
											</button>
										)}
									</>
								)
							}}
						/>
					)}
					renderSummary={({ day, index }) => (
						<ReservationSummary
							day={day}
							aggregation={aggregation}
							key={`tfoot-${index}`}
						/>
					)}
					pagination={
						paginationLinks.length > 1 ? (
							<Pagination
								links={paginationLinks}
								setPage={setPage}
								onPrevious={() => setPage(page - 1)}
								onNext={() => setPage(page + 1)}
								hasNext={hasNext}
								hasPrevious={hasPrevious}
								from={from}
								to={to}
								total={count}
								items={t("desktop.manage.desk_booking.desks")}
							/>
						) : undefined
					}
				/>
			)}
		</View>
	)
}

export default ManageDesks
