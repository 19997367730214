import React from "react"

import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../../hooks/useToast"
import BillingSection from "../BillingSection"
import { getCardSubscriptions, planTypeToTrialType } from "./utils"
import { isRejected } from "@reduxjs/toolkit"

import {
	useCreateTrialSubscriptionMutation,
	useFetchSubscriptionsQuery,
} from "../../../../redux/api/billing/subscriptions"
import { Subscription } from "../../../../redux/api/billing/subscriptions/types"
import { PlanType } from "../../../../redux/api/billing/types"

import SubscriptionCard from "../../../../components/advanced/SubscriptionCard"
import { isSubscription } from "../../../../components/advanced/SubscriptionCard/utils"

import "./styles.sass"

const { stringify } = queryString

const Plans = () => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const history = useHistory()

	const {
		data: { results: subscriptions = [], trials: availableTrials = [] } = {},
	} = useFetchSubscriptionsQuery({})
	const [createTrialSubscription] = useCreateTrialSubscriptionMutation()
	const cardsSubscriptions = getCardSubscriptions(subscriptions)

	const hasTrialAvailable = (planType: PlanType) =>
		availableTrials.includes(planTypeToTrialType(planType))

	const handleModifySubscription = async ({
		id,
		planType,
		hasTrialAvailable,
	}: {
		id?: number
		planType: PlanType
		hasTrialAvailable: boolean
	}) => {
		if (!id && hasTrialAvailable) {
			const response = await createTrialSubscription(
				planTypeToTrialType(planType),
			)

			if (isRejected(response)) {
				errorToast(response.error.message)
				return
			}
			infoToast(t("desktop.settings.billing.plans.toasts.trial_created"))
			return
		}
		history.push(
			`/settings/billing/${planTypeToTrialType(planType)}?${stringify({
				step: 1,
				id,
				planType,
			})}`,
		) // TODO: add correct path and params needed
	}

	const handleLicenseOnClick = ({
		plan_type,
		subscription_id,
		plan_variation,
	}: Subscription) => {
		history.push(
			`/settings/billing/${planTypeToTrialType(plan_type)}?${stringify({
				step: 3,
				id: subscription_id,
				plan_type,
				period: plan_variation.period_unit,
				currency: plan_variation.currency,
			})}`,
		) // TODO: add correct path and params needed
	}

	return (
		<BillingSection
			className="Plans"
			title={t("desktop.settings.billing.plans.title")}
			intro={t("desktop.settings.billing.plans.intro")}
		>
			<div className="plans-list">
				{cardsSubscriptions.map((s, i) => {
					if (isSubscription(s)) {
						return (
							<SubscriptionCard
								key={s.subscription_id}
								subscription={s}
								isActive
								hasTrialAvailable={hasTrialAvailable(s.plan_type)}
								onButtonClick={handleModifySubscription}
								onLicenseClick={handleLicenseOnClick}
							/>
						)
					}
					return (
						<SubscriptionCard
							key={`${s.plan_type}-${i}`}
							subscription={s}
							isActive={false}
							hasTrialAvailable={hasTrialAvailable(s.plan_type)}
							onButtonClick={handleModifySubscription}
						/>
					)
				})}
			</div>
		</BillingSection>
	)
}

export default Plans
